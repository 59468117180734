.form-control-datepicker {

    @include form-control-container($icon-calendar);

/*    padding: 0;
    border: 0;

    @include append-icon {

    }

    > .form-control {
        background: none;
        padding-right: 50px;
    }*/
}



//@import "bootstrap-datetimepicker-widget";
@import "daterangepicker";
