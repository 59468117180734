.form-control-icon-choice {
    display: flex;

    align-items: center;

    height: $input-padding-y + ($font-size-base * $input-line-height) + $input-padding-y;
    border: $input-border-width solid transparent;

    input { display: none; }

    .icon {
        @include icon-size(18px);
        @include size(30px);

        position: relative;
        opacity: 0.37;
        background: #9299a7;
        color: #fff;
        transition: opacity .1s;
        cursor: pointer;

        border-radius: 50%;

        &:before {
            @include center;
        }
    }

    input:checked + .icon {
        opacity: 1;
    }

    label {
        @include size(30px);
        margin: 0
    }

    label + label {
        margin-left: 1rem;
    }
}