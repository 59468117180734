.tagsinput-box {
    padding: 5px;
    position: relative;

    .form-control {
        border: 0;
    }


    
}