.pagination {
    display: flex;
    @include list-unstyled();
    @include border-radius();
}

.page-link {
    position: relative;
    display: flex;

    padding: $pagination-padding-y $pagination-padding-x;
    font-size: 1.4rem;
    line-height: 1;
    color: $body-color;
    font-size: 1.4rem;
    @include size(3rem);
    padding: 0;

    align-items: center;
    justify-content: center;

    border-radius: .4rem;


    &:hover {
        color: $body-color;
        text-decoration: none;
        background-color: $pagination-hover-bg;
        border-color: $pagination-hover-border-color;
    }

    &:focus {
        z-index: 2;
        outline: 0;
        box-shadow: $pagination-focus-box-shadow;
    }

    // Opinionated: add "hand" cursor to non-disabled .page-link elements
    &:not(:disabled):not(.disabled) {
        cursor: pointer;
    }
}

.page-item {



    &:first-child {
        .page-link {
            margin-left: 0;
            @include border-left-radius($border-radius);
        }
    }
    &:last-child {
        .page-link {
            @include border-right-radius($border-radius);
        }
    }

    &.active .page-link {
        z-index: 1;
        color: #fff;
        background-color: $body-color;
        border-color: $body-color;
    }

    &.disabled .page-link {
        color: $pagination-disabled-color;
        pointer-events: none;
        // Opinionated: remove the "hand" cursor set previously for .page-link
        cursor: auto;
        background-color: $pagination-disabled-bg;
        border-color: $pagination-disabled-border-color;
    }
}