.resource-link {
    display: flex;

    .media-body {
        display: flex;
        align-items: center;
        height: 100%;
    }

    a {
        @include anchor($body-color);
        font-size: 1.4rem;
    }

    &__name {
        a {
            @include anchor() {
                text-decoration: underline;
            }
        }
    }

    &__download {
        margin-left: 1rem;

        .icon {
            @include icon-size(2rem);
        }
    }

}