html {
    font-size: 10px;
}

body {
    position: relative;

    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

a, a:hover, a:focus, a:active {
    text-decoration: none;
}

h1, h2, h3, h4, h5, ul, ol, p {
    margin: 0;
}

.site-content {
    flex-grow: 1;
}