.campaign-info {
    &__head {
        font-size: 1.4rem;

        dt { font-weight: 400; }
        dd { font-weight: 500; }
    }


    @include tablet-up {
    &__head {
        display: flex;
        justify-content: right;

        dl, dt, dd {
            display: inline;
        }

        dl + dl { margin-left: 1.5rem; }
    }


        &__actions {
            margin-left: 1.5rem;
        }
    }
}