.site-login {
    /*min-height: 100vh;*/

    &__welcome {
        padding: 20px 0;

        &-media {
            margin-bottom: 20px;
        }

        &-title {
            font-size: 3.6rem;
            font-weight: bold;
            line-height: 1.78;
            color: $body-color;
        }

        &-body {
            font-family: font-shift;
            font-size: 2rem;
            line-height: 1.6;
            color: #9299a7;
        }
    }

    &__form {
        background: rgba(#282828, .8);
        padding: 20px 0;
    }


    @include desktop {

        &__bg {
            @include fixed(0, 0, 0, 50%);
            background: url('../../theme/img/login-bg.jpg') no-repeat left #ddd;
            background-size: cover;
            margin-left: 15px;
        }

        background-color: #f5f8fa;

        &__container {
            @include box;
            background-color: #fff;
            margin: 70px 0 0;
        }


        &__welcome {
            padding: 70px 70px 138px;


            &-media {
                margin-bottom: 80px;
            }

            &-title {
                font-size: 3.6rem;
                font-weight: bold;
                line-height: 1.78;
                color: $body-color;
            }

            &-body {
                font-family: font-shift;
                font-size: 2rem;
                line-height: 1.6;
                color: #9299a7;
            }
        }

        &__form {

            padding: 0;

            display: flex;
            flex-direction: column;
                width: 100%;

            form {
                padding: 20px 75px 20px 100px;
                flex-grow: 1;
                display: flex;
                justify-content: center;
                flex-direction: column;

                .btn {
                    width: 140px;
                }
            }

            .form-group {
                margin-bottom: 30px;
            }

            .form-control {
                padding: 0;
                border: 0;
                border-bottom: 1px solid #fff;
                background: none;
                color: #fff;

                &::placeholder {
                    color: #fff;
                }

                &:focus {
                    box-shadow: none;
                    border-color: $primary;
                }
            }

            &-footer {
                display: flex;
                justify-content: center;
                background: #fff;

                a {
                    padding: 1.8rem;
                    font-size: 1.4rem;
                }
            }

        }

    }


    & + .site-footer {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }
}