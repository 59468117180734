// Align

@mixin center-x($position: absolute) {
    position: $position;
    left: 50%;
    transform: translateX(-50%);
}
@mixin center-y($position: absolute) {
    position: $position;
    top: 50%;
    transform: translateY(-50%);
}

@mixin center($position: absolute) {
    position: $position;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

@mixin reset-center-x($position: relative) {
    position: $position;
    left: auto;
    transform: none;
}
@mixin reset-center-y($position: relative) {
    position: $position;
    top: auto;
    transform: none;
}

@mixin reset-center($position: relative) {
    position: $position;
    top: auto;
    left: auto;
    transform: none;
}