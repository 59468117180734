
.chart-book {
    display: flex;
    width: 280px;

    border-left: 3px solid $accent-default;

    max-width: 80vw;


    &__media {
        /*width: 97px;*/
        padding-left: 11px;

        img { width: 66px; }
    }

    &__title {
        line-height: 1.25;
    }

    &__body {
        margin-top: 10px;
        margin-bottom: 20px;
        font-family: font-shift;
        font-size: 1.4rem;
        line-height: 1.14;
        color: $ternary;
    }

    &__content {
        padding: 0 20px;

        > a {
            font-size: 1.4rem;
            @include anchor($body-color);
        }

    }


    @each $color, $value in $theme-chart-accents {
        &.chart-book--#{$color} {
            border-color: $value;
        }
    }

}

.chart-books {
    display: flex;
    overflow: auto;
}