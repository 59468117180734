.card {

    @include box;

    position: relative;
    max-width: 262px;
    margin: 0 auto;
    text-align: center;


    &__actions {
        @include list-unstyled;

        position: absolute;
        top: 5px;
        right: 8px;

        z-index: 5;
    }


    &__action {
        &--show {
            .icon:before { content: $icon-eye-open; }
            &:hover .icon:before { content: $icon-eye-open-fill; }
        }
        &--edit {
            .icon:before { content: $icon-pencil; }
            &:hover .icon:before { content: $icon-pencil-fill; }
        }
    }

    &__content {
        padding: 18px;
    }

    &__head {
        text-align: left;
    }

    .books {
        margin: 25px auto;
    }

    &__title {

        font-size: 1.6rem;
        font-weight: 500;
        line-height: 1.25;
        margin-bottom: 10px;

    }

    &__body {
        font-size: 1.4rem;
        font-weight: normal;
        line-height: 1.43;
        margin-bottom: 20px;
    }

    &__author {
        font-family: $font-shift;
        font-size: 1.4rem;
        line-height: 1.14;
        color: #ffc000;
    }


    &__cta {
        margin-top: 25px;
    }

    &__link {
        @include anchor {
            color: $body-color;
        }
    }
    &__footer {
        font-family: $font-shift;
        font-size: 2rem;
        padding: 10px;
    }

    &__front {
        /*background: #dfd;*/
    }
    &__rear {
        background: #d4dae3;
        padding: 30px;

        .card__title {
            padding-bottom: 10px;
            border-bottom: solid 1px #fff;
            margin-bottom: 20px;
        }

    }


    &__flip {
        display: flex;
        flex-wrap: nowrap;
        overflow: hidden;
        width: 200%;
        //transition: transform .2s;
    }
    &__front, &__rear {
        width: 50%;
    }

    &:hover & {
        &__flip {
            transform: translateX(-50%);
        }
    }

    &--site {

    }
    &--site & {
        &__actions {
            a {
                @include size(24px);
                @include anchor(#9299a7);

                position: relative;
                display: block;
                border-radius: 50%;
                background: #fff;

                .icon {
                    @include icon-size(12px);

                    &, &:before {
                        @include center;
                    }
                }

            }
        }



        &__link {
            /*@include absolute;*/
            /*display: flex;*/

            height: 0;
            padding-bottom: 100%;

        }

        &__media {
            height: 0;
            padding-bottom: 100%;

        }

        &__footer {
            @include absolute-bottom;
            background: #fff;
        }
    }

    &--number {
        background: #d4dae3;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: stretch;

        padding: 15px;

        @include desktop {
            padding: 28px 10px;
        }
    }

    &--number & {
        &__title {
            font-family: Shift;
            font-size: 24px;
            line-height: 1.5;
            flex-grow: 1;

            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__number {
            font-size: 4.8rem;
            font-weight: bold;
            line-height: 1.33;
            color: #fff;
        }
    }
}