.campaign-summary {
    & + & {
        margin-top: 10px;
    }

    .collapsible__body {
        padding-left: 0;
        padding-right: 0;
    }


    &__info {
        display: flex;
        justify-content: right;
        font-size: 1.4rem;

        dl, dt, dd {
            display: inline;
        }

        dl + dl { margin-left: 1.5rem; }
    }
}