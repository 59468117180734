$calendar-primary: #9299a7;
$calendar-secondary: #d4dae3;


.calendar {
    @include box;


    &__head {
        background: $calendar-primary;
        padding: 10px 15px;
        color: #fff;

        a {
            @include anchor(#fff);
        }

        .dropdown-menu a {
            @include anchor($body-color);
        }


        &-navigation {
            display: flex;
            height: 50px;
            align-items: center;

            > span {
                flex-grow: 1;
                text-align: center;
                padding-left: 1.5rem;
                padding-right: 1.5rem;

                font-size: 2rem;
                font-weight: bold;
            }
        }

        @include portable {
            .btn-link { display: block }
            .dropdown-menu { width: 100%; }
            .dropdown-item { text-align: center; }
        }

        @include desktop {
            display: flex;
            width: 100%;
            padding: 30px 40px 10px;
            align-items: center;

            > div {
                flex-grow: 2;
                width: 40%;
            }
            &-month {
                width: 20%;
                flex-grow: 1;
                display: flex;
                justify-content: center;
            }
        }

    }













    &__intro {
        background: $calendar-secondary;
        color: $calendar-primary;

        &-text {

        }

        &-day {
            padding: 10px 15px;
        }
    }


    // &__body {
    // 	padding: 36px 0;
    // }

    &-number {
        font-size: 3.6rem;
    }

    &-event {
        height: 40px;
        font-size: 1.2rem;

        background: #fdd;
        display: flex;
        align-items: flex-start;

        padding: 2px 10px 0;
        color: $body-color;

        overflow: hidden;
        text-overflow: ellipsis;

        position: relative;
        cursor: pointer;

        transition: background .1s, color .1s;


        @each $color, $value in $theme-accents--light {
            &.calendar-event--#{$color} {
                background: $value;
            }
        }

        @each $color, $value in $theme-accents {
            &.calendar-event--#{$color} {
                border-left: 4px solid $value;

                /*&:hover, &.hover {*/
                &.hover {
                    background: lighten($value, 15%);
                }
            }
        }

        &.calendar-event--half {
            border-color: transparent;
        }

    }

    &__cell {
        padding: 10px 0;

        .calendar__body & {
            padding: 0;
        }
    }


    // Table

    // table {
    // 	width: 100%;

    // 	.calendar__intro {
    // 		background: $calendar-secondary;
    // 		color: $calendar-primary;
    // 	}

    // 	.table-day {
    // 		color: $calendar-secondary;
    // 		padding: 10px;
    // 	}

    // 	tbody {
    // 		tr {
    // 			&:first-child() {
    // 				td {
    // 					padding-top: 36px;
    // 				}
    // 			}
    // 			&:last-child() {
    // 				td {
    // 					padding-bottom: 36px;
    // 				}
    // 			}
    // 		}
    // 	}

    // 	th {
    // 		width: 14.28571%;
    // 		text-align: right;

    // 		span {
    // 			font-weight: 400;
    // 		}

    // 		&.calendar__cell {
    // 			padding: 10px;
    // 		}

    // 		& + th {
    // 			border-left: 1px solid $calendar-primary;
    // 		}
    // 	}
    // }

    &--month {
        table {
            th {
                width: 14.28571%;
                text-align: right;

                &.calendar__cell {
                    padding: 35px 0 15px;
                    text-align: center;
                }

            }
        }
    }

    @include desktop {

        &__intro {

            &-day {
                padding: 20px 40px 5px;
            }
        }

        &__body {
            display: flex;
            flex-wrap: wrap;
            // max-height: 700px;
        }

        /*        &-event {
                    max-width: 166px;
                }*/

        &__cell {
            padding: 10px;

            .calendar-event {
                max-width: none;
                width: 100%;
            }
        }
    }
}






$table-border: #efefef;
$table-border: 1px solid #efefef;
$table-day: #8a888a;
$table-day-disabled: #d9d9d9;;

$table-day-width: 100% / 7;


$day-height: 200px;
$day-head-height: 36px;


.calendar-table {

    width: 100%;

    &__head, &__body {
        table {
            width: 100%;
        }

        td {
            width: $table-day-width;
        }
    }

    &__head {
        background: $calendar-secondary;
        color: $calendar-primary;

        td {
            width: $table-day-width;
            text-align: right;
            padding: 10px;
            text-align: center;
            padding-top: 32px;
        }
    }

    &__body {

    }

    &__row {
        position: relative;
    }

    &__days {
        position: absolute;
        top: 0;
        height: 100%;

        td {
            vertical-align: top;
            text-align: right;
            padding: 0;
            padding-top: 3px;
            padding-right: 14px;
            color: $table-day;
            font-size: 1.4rem;

            &.disabled {
                color: #fff;
                background: #d9d9d9;
            }
        }
    }

    &__events {
        position: relative;
        z-index: 1;
    }

    &__event {
        height: 48px;
        padding: 4px 0;
        padding-right: 1px;
        vertical-align: top;

        .calendar-event {
            max-width: none;
            width: 100%;
        }
    }

    &__actions {
        text-align: right;
        padding: 4px 8px;

        button {
            font-size: 3rem;
            color: #cbcbcb;
            line-height: .5;
            padding: 0 5px;
            background: none;
            border: 0;
            cursor: pointer;
        }

        span {
            display: inline-block;
            transform: translateY(-8px);
        }
    }

    &__actions-tooltip {
        padding: 20px;
        font-size: 1.4rem;

        &__info {
            text-align: left;
        }

        &__detail {
            margin-top: 30px;
            text-align: right;
            a {
                text-decoration: underline;
            }
        }
    }

    .calendar--month &, .calendar--week & {
        &__head td + td { border-left: 1px solid #c8c8c8; }
        &__row + &__row { border-top: $table-border; }
        &__days td + td { border-left: $table-border; }
    }

    .calendar--month & {
        &__row {
            padding-top: 36px;
        }
    }

}



.ct {

    width: 100%;
    height: 100%;

    & &, & & td {
        border: 0;
    }




    &-head {
        background: $calendar-secondary;
        color: $calendar-primary;

        th {
            width: $table-day-width;
            text-align: right;

            span {
                font-weight: 400;
            }

            &.calendar__cell {
                padding: 10px;
            }

            & + th {
                border-left: 1px solid $calendar-primary;
            }
        }
    }






    &-body {
        /*        > tr + tr {
                    border-top: $table-border;
                }
                > tr > td {
                    padding: 0;
                    border-top: 0;
                }

                .ct {
                    position: relative;

                    td {
                        width: $table-day-width;
                    }

                    .ct-day {
                        & + .ct-day:before {
                            content: '';
                            display: block;
                            border-left: $table-border;
                            position: absolute;
                            top: 0;
                            bottom: 0;
                        }
                    }
                }*/


        .ct {
            width: 100%;
            td {
                width: $table-day-width;
            }
        }

        .ct-container {
            position: relative;
        }

        > tr + tr {
            border-top: $table-border;
        }

        .ct-days {
            position: absolute;

            td + td {
                border-left: $table-border;
            }

            td {
                height: 36px;
                vertical-align: top;
                text-align: right;
                padding: 0;
                padding-top: 3px;
                padding-right: 14px;
                color: $table-day;
                font-size: 1.4rem;
            }
            /*display: none;*/
        }
        .ct-events {
            position: relative;
            z-index: 1;
            /*position: absolute;*/
            /*display: none;*/
        }
    }

    /*    &-content {
            @include absolute();
        }*/

    /*    &-bg {
            height: 100%;
        }*/

    &-row {
        position: relative;
        /*width: 100%;*/
        /*height: 200px;*/

        /*        &--week {
                    height: 682px;

                    thead {
                        height: 10px;
                    }

                    .ct-day-top {
                        height: 0;
                    }
                }*/
    }

    /*    &-day {
            height: 36px;
            vertical-align: middle;
            text-align: right;
            padding: 0;
            padding-right: 14px;
            color: $table-day;
            font-size: 1.4rem;

            &--disabled {
                background: $table-day-disabled;
                color: #fff;
                font-weight: 400;
                padding-top: 40px;
            }
        }*/

    &-event {
        padding: 4px 0;
        vertical-align: top;

        .calendar-event {
            max-width: none;
            width: 100%;
        }
    }

    .ct-actions {
        height: 20px;
        text-align: right;
        vertical-align: middle;
    }
}