// Pseudo

@mixin before() {
    &:before {
        content: '';
        display: block;

        @content;
    }
}

@mixin after() {
    &:after {
        content: '';
        display: block;

        @content;
    }
}

@mixin before-after() {
    &:before, &:after {
        content: '';
        display: block;

        @content;
    }
}