// Generated file

@mixin icon($content: $icon-empty) {
    display: inline-block;
    line-height: 1;

    &:before {
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        content: $content;
        display: inline-block;
        font-family: 'icons';
        font-style: normal;
        font-variant: normal;
        font-weight: normal;
        line-height: 1;
        speak: none;
        text-transform: none;

        @content;
    }
}

$icon-empty: "\AA00";
$icon-cog: "\AA01";
$icon-upload: "\AA02";
$icon-download: "\AA03";
$icon-eye-open: "\AA04";
$icon-eye-closed: "\AA05";
$icon-import: "\AA06";
$icon-export: "\AA07";
$icon-filter: "\AA08";
$icon-login: "\AA09";
$icon-logout: "\AA10";
$icon-ok: "\AA11";
$icon-remove: "\AA12";
$icon-plus: "\AA13";
$icon-minus: "\AA14";
$icon-search: "\AA15";
$icon-zoom-in: "\AA16";
$icon-zoom-out: "\AA17";
$icon-trash: "\AA18";
$icon-pencil: "\AA19";
$icon-burger: "\AA20";
$icon-times: "\AA21";
$icon-chevron-up: "\AA22";
$icon-chevron-down: "\AA23";
$icon-chevron-left: "\AA24";
$icon-chevron-right: "\AA25";
$icon-caret-up: "\AA26";
$icon-caret-down: "\AA27";
$icon-caret-left: "\AA28";
$icon-caret-right: "\AA29";
$icon-phone: "\AA30";
$icon-envelope: "\AA31";
$icon-pin: "\AA32";
$icon-calendar: "\AA33";
$icon-play: "\AA34";
$icon-eye-open-fill: "\AA37";
$icon-pencil-fill: "\AA38";
$icon-social-facebook: "\AB01";
$icon-social-instagram: "\AB02";
$icon-social-youtube: "\AB03";
$icon-social-linkedin: "\AB04";
$icon-social-googleplus: "\AB05";
$icon-social-twitter: "\AB06";
$icon-add-circle: "\C001";
$icon-add: "\C002";
$icon-profile: "\C003";
$icon-chart: "\C004";
$icon-arrow-right: "\C005";
$icon-arrow-up: "\C006";
$icon-arrow-left: "\C007";
$icon-arrow-down: "\C008";
$icon-like: "\C009";
