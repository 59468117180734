.add-widget {

    @include box;

    display: block;
    position: relative;
    height: 200px;
    border: 2px dashed #cbd0d8;



    .icon {
        &, &:before {
            @include center;
        }

        @include icon-size(29px);
        @include size(89px);
        background: #d4dae3;

        border-radius: 50%;
        color: #fff;
    }


    @include desktop {
        height: 256px;
    }

}