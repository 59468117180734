// Generated file

@font-face {
  font-family: "icons";
  src: url('../fonts/icons/icons.eot');
  src: url('../fonts/icons/icons.eot?#iefix') format('eot'),
    url('../fonts/icons/icons.woff2') format('woff2'),
    url('../fonts/icons/icons.woff') format('woff'),
    url('../fonts/icons/icons.ttf') format('truetype'),
    url('../fonts/icons/icons.svg#icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon {
    @include icon();
}

.icon-lg {
  font-size: 1.3333333333333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
  width: 1.2857142857142858em;
  text-align: center;
}

.-empty:before { content: $icon-empty }
.-cog:before { content: $icon-cog }
.-upload:before { content: $icon-upload }
.-download:before { content: $icon-download }
.-eye-open:before { content: $icon-eye-open }
.-eye-closed:before { content: $icon-eye-closed }
.-import:before { content: $icon-import }
.-export:before { content: $icon-export }
.-filter:before { content: $icon-filter }
.-login:before { content: $icon-login }
.-logout:before { content: $icon-logout }
.-ok:before { content: $icon-ok }
.-remove:before { content: $icon-remove }
.-plus:before { content: $icon-plus }
.-minus:before { content: $icon-minus }
.-search:before { content: $icon-search }
.-zoom-in:before { content: $icon-zoom-in }
.-zoom-out:before { content: $icon-zoom-out }
.-trash:before { content: $icon-trash }
.-pencil:before { content: $icon-pencil }
.-burger:before { content: $icon-burger }
.-times:before { content: $icon-times }
.-chevron-up:before { content: $icon-chevron-up }
.-chevron-down:before { content: $icon-chevron-down }
.-chevron-left:before { content: $icon-chevron-left }
.-chevron-right:before { content: $icon-chevron-right }
.-caret-up:before { content: $icon-caret-up }
.-caret-down:before { content: $icon-caret-down }
.-caret-left:before { content: $icon-caret-left }
.-caret-right:before { content: $icon-caret-right }
.-phone:before { content: $icon-phone }
.-envelope:before { content: $icon-envelope }
.-pin:before { content: $icon-pin }
.-calendar:before { content: $icon-calendar }
.-play:before { content: $icon-play }
.-eye-open-fill:before { content: $icon-eye-open-fill }
.-pencil-fill:before { content: $icon-pencil-fill }
.-social-facebook:before { content: $icon-social-facebook }
.-social-instagram:before { content: $icon-social-instagram }
.-social-youtube:before { content: $icon-social-youtube }
.-social-linkedin:before { content: $icon-social-linkedin }
.-social-googleplus:before { content: $icon-social-googleplus }
.-social-twitter:before { content: $icon-social-twitter }
.-add-circle:before { content: $icon-add-circle }
.-add:before { content: $icon-add }
.-profile:before { content: $icon-profile }
.-chart:before { content: $icon-chart }
.-arrow-right:before { content: $icon-arrow-right }
.-arrow-up:before { content: $icon-arrow-up }
.-arrow-left:before { content: $icon-arrow-left }
.-arrow-down:before { content: $icon-arrow-down }
.-like:before { content: $icon-like }
