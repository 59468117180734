//
// Basic layout styles
//




$site-header--stiky: true !default;
$site-header--stiky--portable: $site-header--stiky !default;
$site-header--stiky--desktop: $site-header--stiky !default;

$site-header--height: 150px !default;
$site-header--height--portable: 50px !default;
$site-header--height--desktop: 150px !default;



$site-footer--push: true;


@if $debug {
    .site-header { background: #f99; }
    .site-content { background: #9f9; }
    .site-footer { background: #99f; }
}


body {
    @if $site-footer--push {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
    }
}

.site-header {
    position: static;

    &, .header {
        z-index: $zindex-sticky;
        height: $site-header--height--portable;
    }
}

.site-content {
    @if $site-footer--push {
        flex-grow: 1;
    }
}

.site-footer {

}



////


//$site-header--fixed--portable: true;
//$site-header--fixed--desktop: true;
//$site-header--height--portable: 50px;
//$site-header--height--desktop: 150px;
//
//$site-footer--push: true;
//
//
//

//@include portable {
//    .site-header {
//        .header {
//            @if $site-header--fixed--portable {
//                position: fixed;
//                @include top();
//                z-index: $zindex-navbar-fixed
//            }
//        }
//    }
//
//    @if $site-header--fixed--portable {
//        body.site-menu-open {
//            overflow: hidden;
//
//            .site-header {
//                .header {
//                    height: 100%;
//                }
//            }
//        }
//    }
//}


//@include desktop {
//    .site-header {
//        &, .header {
//            height: $site-header--height--desktop;
//        }
//
//        @if $site-header--fixed--desktop {
//            .header {
//                @include fixed-top();
//                z-index: $zindex-navbar-fixed;
//            }
//        }
//    }
//}
