// stylelint-disable selector-no-qualifying-type

//
// Base styles
//

.btn {
    display: inline-block;
    font-weight: $btn-font-weight;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: $btn-border-width solid transparent;
    @include button-size($btn-padding-y, $btn-padding-x, $font-size-base, $btn-line-height, $btn-border-radius);
    @include transition($btn-transition);

    // Share hover and focus styles
    @include hover-focus {
        text-decoration: none;
    }

    &:focus,
        &.focus {
        outline: 0;
        box-shadow: $btn-focus-box-shadow;
    }

    // Disabled comes first so active can properly restyle
    &.disabled,
    &:disabled {
        opacity: $btn-disabled-opacity;
        @include box-shadow(none);
    }

    // Opinionated: add "hand" cursor to non-disabled .btn elements
    &:not(:disabled):not(.disabled) {
        cursor: pointer;
    }

    &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active {
        background-image: none;
        @include box-shadow($btn-active-box-shadow);

        &:focus {
            @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
        }
    }
}

// Future-proof disabling of clicks on `<a>` elements
a.btn.disabled,
fieldset:disabled a.btn {
    pointer-events: none;
}


//
// Alternate buttons
//

@each $color, $value in $theme-colors {
    .btn-#{$color} {
        @include button-variant($value, #fff, #000);
    }
}

@each $color, $value in $theme-colors {
    .btn-outline-#{$color} {
        @include button-outline-variant($value, #fff);
    }
}


//
// Link buttons
//

// Make a button look and behave like a link
.btn-link {
    font-weight: $font-weight-normal;
    color: $body-color;
    background-color: transparent;
    padding-left: 1rem;
    padding-right: 1rem;

    @include hover {
        color: $body-color;
        text-decoration: $link-hover-decoration;
        background-color: transparent;
        border-color: transparent;
    }

    &:focus,
        &.focus {
        text-decoration: $link-hover-decoration;
        border-color: transparent;
        box-shadow: none;
    }

    &:disabled,
        &.disabled {
        color: $btn-link-disabled-color;
    }

    // No need for an active state here
}


//
// Button Sizes
//

.btn-lg {
    @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $font-size-lg, $btn-line-height-lg, $btn-border-radius-lg);
}

.btn-sm {
    @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $font-size-sm, $btn-line-height-sm, $btn-border-radius-sm);
}


//
// Block button
//

.btn-block {
    display: block;
    width: 100%;

    // Vertically space out multiple block buttons
    + .btn-block {
        margin-top: $btn-block-spacing-y;
    }
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
    &.btn-block {
        width: 100%;
    }
}


.btn-icon-left, .btn-icon-right, .btn-icon-only {
    .icon {
        @include icon-size(18px);
        position: relative;
        top: .1rem;
    }
}


.btn-icon-left {
    .icon {
        left: -.5rem;
        margin-right: .5rem;
    }
}

.btn-icon-right {
    .icon {
        right: -.5rem;
        margin-left: .5rem;
	}
}

.btn-sm {
	&.btn-icon-left,
	&.btn-icon-right {
		.icon {
			@include icon-size(15px);
		}
	}
}

.btn-icon-only {
    $scale: 1.5;

    padding-left: $btn-padding-y * $scale;
    padding-right: $btn-padding-y * $scale;

    &.btn-lg {
        padding-left: $btn-padding-y-lg * $scale;
        padding-right: $btn-padding-y-lg * $scale;
    }

    &.btn-sm {
        padding-left: $btn-padding-y-sm * $scale;
        padding-right: $btn-padding-y-sm * $scale;
    }
}