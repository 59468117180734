@mixin style($style) {
    @if ($style == body) {
        font-family: $font-family-base;
        font-size: $font-size-base;
        line-height: $line-height-base;
    }

    @if ($style == heading) {
        font-family: $headings-font-family;
        font-weight: $headings-font-weight;
        line-height: $headings-line-height;
    }

    @if ($style == h1) {
        font-family: $font-fort;
        font-weight: 700;
        font-size: 4.8rem;
        line-height: $headings-line-height;
    }

    @if ($style == h2) {
        font-family: $font-fort;
        font-weight: 700;
        font-size: 3.2rem;
        line-height: $headings-line-height;
    }

    @if ($style == h3) {
        font-family: $font-shift;
        font-weight: 300;
        font-size: 2.4rem;
        line-height: $headings-line-height;
    }

    @if ($style == h4) {
        font-family: $font-shift;
        font-weight: 300;
        font-size: 2.0rem;
        line-height: $headings-line-height;
    }

    @if ($style == h5) {
        font-family: $font-fort;
        font-weight: 400;
        font-size: 1.6rem;
        line-height: $headings-line-height;
    }

    @if ($style == h6) {
        font-family: $font-shift;
        font-weight: 300;
        font-size: 1.4rem;
        line-height: $headings-line-height;
    }
}