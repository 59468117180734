.form-control-concept-search {
    .dropdown-menu {
        border-radius: 0;
        min-width: 100%;
        padding: 10px 0;

        label {
            margin: 0;
            cursor: pointer;
        }
    }
    .dropdown-item {
        &:active {
            background: none;
            color: $body-color;
        }
    }

    .input-group-prepend {
        position: relative;

        .btn {
            border-radius: 4px 0 0 4px;
            min-width: 130px;


            @include anchor {
                background: $secondary;
                color: $body-color;
            }

            @include append-icon($icon-chevron-down) {
                @include icon-size(12px);
                color: #fff;
                border: 0;
                margin-left: 6px;
            }
        }

        .dropdown-item {
            &.active {
                display: none !important;
            }

            input {
                display: none;
            }
        }
    }



    .form-control-typeahead {
        .dropdown-menu {
            padding: 10px 0;
            overflow: auto;
            overflow-x: hidden;

            .dropdown-item {
                padding: 0;
            }

            max-height: 3px + (36px * 10) + 3px;

            a.dropdown-item, label, .tt-suggestion {
                padding: .5rem 1.5rem;
            }

            label {
                padding-left: 4rem;
            }

            .custom-control-label {
                &:before, &:after {
                    top: .55rem;
                    left: 1.5rem;
                }
            }
        }


        .form-control {
            border-radius: 0;
        }

    }
}

.form-control-concept-search, .form-control-concept-search-xs {

    .input-group-append {
        position: relative;

        .btn {
            border-radius: 0 4px 4px 0;
            border: $input-border-width solid $input-border-color;
            background-color: $input-bg;
            border-left: 0;
            padding-left: 1rem;
            padding-right: 1rem;
            color: $primary;

        }
    }
}