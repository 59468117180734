.chart {
    // padding: 20px;
    display: flex;
    justify-content: center;

    > div {
        width: 100%;
	}
	
}

.amChartsDataSetSelector  {
	display: none;
}

.amcharts-left-div {
	display: none;
}

.amcharts-center-div {
	padding-left: 0 !important;
}