@mixin form-control-container($icon: false) {

    $icon-size: 1.5rem;

    position: relative;
    padding: 0;
    border: 0;

    @if $icon {
        @include prepend-icon($icon) {
            @include center-y;
            @include icon-size($icon-size);
            right: $input-padding-x;
        }
    }

    .form-control {
        background: none;
        position: relative;

        @if $icon {
            padding-right: 1rem + $icon-size + $input-padding-x;
        }
    }

}