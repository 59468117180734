@charset "UTF-8";
/*$custom-control-indicator-focus-box-shadow:     0 0 0 1px $body-bg, $input-btn-focus-box-shadow !default;*/
@font-face {
  font-family: "icons";
  src: url("../fonts/icons/icons.eot");
  src: url("../fonts/icons/icons.eot?#iefix") format("eot"), url("../fonts/icons/icons.woff2") format("woff2"), url("../fonts/icons/icons.woff") format("woff"), url("../fonts/icons/icons.ttf") format("truetype"), url("../fonts/icons/icons.svg#icons") format("svg");
  font-weight: normal;
  font-style: normal; }

.icon {
  display: inline-block;
  line-height: 1; }
  .icon:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    content: "ꨀ";
    display: inline-block;
    font-family: 'icons';
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    speak: none;
    text-transform: none;
    position: relative;
    top: 2px; }

.icon-lg {
  font-size: 1.3333333333333333em;
  line-height: 0.75em;
  vertical-align: -15%; }

.icon-2x {
  font-size: 2em; }

.icon-3x {
  font-size: 3em; }

.icon-4x {
  font-size: 4em; }

.icon-5x {
  font-size: 5em; }

.icon-fw {
  width: 1.2857142857142858em;
  text-align: center; }

.-empty:before {
  content: "ꨀ"; }

.-cog:before {
  content: "ꨁ"; }

.-upload:before {
  content: "ꨂ"; }

.-download:before {
  content: "ꨃ"; }

.-eye-open:before {
  content: "ꨄ"; }

.-eye-closed:before {
  content: "ꨅ"; }

.-import:before {
  content: "ꨆ"; }

.-export:before {
  content: "ꨇ"; }

.-filter:before {
  content: "ꨈ"; }

.-login:before {
  content: "ꨉ"; }

.-logout:before {
  content: "ꨐ"; }

.-ok:before {
  content: "ꨑ"; }

.-remove:before {
  content: "ꨒ"; }

.-plus:before {
  content: "ꨓ"; }

.-minus:before {
  content: "ꨔ"; }

.-search:before {
  content: "ꨕ"; }

.-zoom-in:before {
  content: "ꨖ"; }

.-zoom-out:before {
  content: "ꨗ"; }

.-trash:before {
  content: "ꨘ"; }

.-pencil:before {
  content: "ꨙ"; }

.-burger:before {
  content: "ꨠ"; }

.-times:before {
  content: "ꨡ"; }

.-chevron-up:before {
  content: "ꨢ"; }

.-chevron-down:before {
  content: "ꨣ"; }

.-chevron-left:before {
  content: "ꨤ"; }

.-chevron-right:before {
  content: "ꨥ"; }

.-caret-up:before {
  content: "ꨦ"; }

.-caret-down:before {
  content: "ꨧ"; }

.-caret-left:before {
  content: "ꨨ"; }

.-caret-right:before {
  content: "ꨩ"; }

.-phone:before {
  content: "ꨰ"; }

.-envelope:before {
  content: "ꨱ"; }

.-pin:before {
  content: "ꨲ"; }

.-calendar:before {
  content: "ꨳ"; }

.-play:before {
  content: "ꨴ"; }

.-eye-open-fill:before {
  content: "꨷"; }

.-pencil-fill:before {
  content: "꨸"; }

.-social-facebook:before {
  content: "ꬁ"; }

.-social-instagram:before {
  content: "ꬂ"; }

.-social-youtube:before {
  content: "ꬃ"; }

.-social-linkedin:before {
  content: "ꬄ"; }

.-social-googleplus:before {
  content: "ꬅ"; }

.-social-twitter:before {
  content: "ꬆ"; }

.-add-circle:before {
  content: "쀁"; }

.-add:before {
  content: "쀂"; }

.-profile:before {
  content: "쀃"; }

.-chart:before {
  content: "쀄"; }

.-arrow-right:before {
  content: "쀅"; }

.-arrow-up:before {
  content: "쀆"; }

.-arrow-left:before {
  content: "쀇"; }

.-arrow-down:before {
  content: "쀈"; }

.-like:before {
  content: "쀉"; }

/*!
 * Bootstrap v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #ff6f00;
  --secondary: #94a0b2;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --marketing: #85c4c1;
  --communication: #edae47;
  --sales: #ed6847;
  --marketing-light: #daedec;
  --communication-light: #fce8ce;
  --sales-light: #f9cabf;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent; }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Fort", sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212121;
  text-align: left;
  background-color: #f5f8fa; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 1rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #ff6f00;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #b34e00;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 1rem;
  font-family: "Shift", sans-serif;
  font-weight: 300;
  line-height: 1.2;
  color: inherit; }

h1, .h1 {
  font-size: 4rem; }

h2, .h2 {
  font-size: 3.2rem; }

h3, .h3 {
  font-size: 2.8rem; }

h4, .h4 {
  font-size: 2.4rem; }

h5, .h5 {
  font-size: 2rem; }

h6, .h6 {
  font-size: 1.6rem; }

.lead {
  font-size: 2rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  padding-right: 0;
  list-style: none;
  margin: 0; }

.list-inline {
  padding-left: 0;
  padding-right: 0;
  list-style: none;
  margin: 0; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 2rem;
  font-size: 2rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #f5f8fa;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 1rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code,
kbd,
pre,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 2rem;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }
  .table .table {
    background-color: #f5f8fa; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #ffd7b8; }

.table-hover .table-primary:hover {
  background-color: #ffc99f; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #ffc99f; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #e1e4e9; }

.table-hover .table-secondary:hover {
  background-color: #d2d7de; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #d2d7de; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-marketing,
.table-marketing > th,
.table-marketing > td {
  background-color: #ddeeee; }

.table-hover .table-marketing:hover {
  background-color: #cce6e6; }
  .table-hover .table-marketing:hover > td,
  .table-hover .table-marketing:hover > th {
    background-color: #cce6e6; }

.table-communication,
.table-communication > th,
.table-communication > td {
  background-color: #fae8cb; }

.table-hover .table-communication:hover {
  background-color: #f8deb4; }
  .table-hover .table-communication:hover > td,
  .table-hover .table-communication:hover > th {
    background-color: #f8deb4; }

.table-sales,
.table-sales > th,
.table-sales > td {
  background-color: #fad5cb; }

.table-hover .table-sales:hover {
  background-color: #f8c2b4; }
  .table-hover .table-sales:hover > td,
  .table-hover .table-sales:hover > th {
    background-color: #f8c2b4; }

.table-marketing-light,
.table-marketing-light > th,
.table-marketing-light > td {
  background-color: #f5fafa; }

.table-hover .table-marketing-light:hover {
  background-color: #e4f2f2; }
  .table-hover .table-marketing-light:hover > td,
  .table-hover .table-marketing-light:hover > th {
    background-color: #e4f2f2; }

.table-communication-light,
.table-communication-light > th,
.table-communication-light > td {
  background-color: #fef9f1; }

.table-hover .table-communication-light:hover {
  background-color: #fcefd9; }
  .table-hover .table-communication-light:hover > td,
  .table-hover .table-communication-light:hover > th {
    background-color: #fcefd9; }

.table-sales-light,
.table-sales-light > th,
.table-sales-light > td {
  background-color: #fdf0ed; }

.table-hover .table-sales-light:hover {
  background-color: #faddd6; }
  .table-hover .table-sales-light:hover > td,
  .table-hover .table-sales-light:hover > th {
    background-color: #faddd6; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #f5f8fa;
  background-color: #212529;
  border-color: #32383e; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #f5f8fa;
  background-color: #212529; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #32383e; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  padding: 0.95rem 2rem;
  font-size: 1.6rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #ffb780;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 111, 0, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:not([size]):not([multiple]) {
  height: calc(4.3rem + 2px); }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.95rem + 1px);
  padding-bottom: calc(0.95rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(1.05rem + 1px);
  padding-bottom: calc(1.05rem + 1px);
  font-size: 2rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.85rem + 1px);
  padding-bottom: calc(0.85rem + 1px);
  font-size: 1.4rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.95rem;
  padding-bottom: 0.95rem;
  margin-bottom: 0;
  line-height: 1.5;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-sm > .input-group-append > .form-control-plaintext.btn, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-lg > .input-group-append > .form-control-plaintext.btn {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.85rem 2rem;
  font-size: 1.4rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(3.8rem + 2px); }

.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 1.05rem 2rem;
  font-size: 2rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(5.1rem + 2px); }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 2rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.6rem;
  margin-left: -2rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 1.2rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.5rem;
    margin-top: 0.25rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #71dd8a; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #f5f8fa, 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }
  .was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #efa2a9; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #f5f8fa, 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.4rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.form-control-static {
  display: flex;
  align-items: center;
  height: 45px;
  border: 1px solid transparent; }

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.95rem 2rem;
  font-size: 1.6rem;
  line-height: 1.5;
  border-radius: 0.5rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .btn:hover, .btn:focus {
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 111, 0, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }
  .btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
    background-image: none; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #ff6f00;
  border-color: #ff6f00; }
  .btn-primary:hover {
    color: #fff;
    background-color: #000;
    border-color: #000; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 111, 0, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #ff6f00;
    border-color: #ff6f00; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #cc5900;
    border-color: #cc5900; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 111, 0, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #94a0b2;
  border-color: #94a0b2; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #000;
    border-color: #000; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(148, 160, 178, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #94a0b2;
    border-color: #94a0b2; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #76869d;
    border-color: #76869d; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(148, 160, 178, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #000;
    border-color: #000; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1e7e34; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #000;
    border-color: #000; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #117a8b; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-warning {
  color: #fff;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #fff;
    background-color: #000;
    border-color: #000; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #fff;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #d39e00;
    border-color: #d39e00; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #000;
    border-color: #000; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #bd2130; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-light {
  color: #fff;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #fff;
    background-color: #000;
    border-color: #000; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #fff;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #fff;
    background-color: #dae0e5;
    border-color: #dae0e5; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #000;
    border-color: #000; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #1d2124; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-marketing {
  color: #fff;
  background-color: #85c4c1;
  border-color: #85c4c1; }
  .btn-marketing:hover {
    color: #fff;
    background-color: #000;
    border-color: #000; }
  .btn-marketing:focus, .btn-marketing.focus {
    box-shadow: 0 0 0 0.2rem rgba(133, 196, 193, 0.5); }
  .btn-marketing.disabled, .btn-marketing:disabled {
    color: #fff;
    background-color: #85c4c1;
    border-color: #85c4c1; }
  .btn-marketing:not(:disabled):not(.disabled):active, .btn-marketing:not(:disabled):not(.disabled).active,
  .show > .btn-marketing.dropdown-toggle {
    color: #fff;
    background-color: #63b3b0;
    border-color: #63b3b0; }
    .btn-marketing:not(:disabled):not(.disabled):active:focus, .btn-marketing:not(:disabled):not(.disabled).active:focus,
    .show > .btn-marketing.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(133, 196, 193, 0.5); }

.btn-communication {
  color: #fff;
  background-color: #edae47;
  border-color: #edae47; }
  .btn-communication:hover {
    color: #fff;
    background-color: #000;
    border-color: #000; }
  .btn-communication:focus, .btn-communication.focus {
    box-shadow: 0 0 0 0.2rem rgba(237, 174, 71, 0.5); }
  .btn-communication.disabled, .btn-communication:disabled {
    color: #fff;
    background-color: #edae47;
    border-color: #edae47; }
  .btn-communication:not(:disabled):not(.disabled):active, .btn-communication:not(:disabled):not(.disabled).active,
  .show > .btn-communication.dropdown-toggle {
    color: #fff;
    background-color: #e89a19;
    border-color: #e89a19; }
    .btn-communication:not(:disabled):not(.disabled):active:focus, .btn-communication:not(:disabled):not(.disabled).active:focus,
    .show > .btn-communication.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(237, 174, 71, 0.5); }

.btn-sales {
  color: #fff;
  background-color: #ed6847;
  border-color: #ed6847; }
  .btn-sales:hover {
    color: #fff;
    background-color: #000;
    border-color: #000; }
  .btn-sales:focus, .btn-sales.focus {
    box-shadow: 0 0 0 0.2rem rgba(237, 104, 71, 0.5); }
  .btn-sales.disabled, .btn-sales:disabled {
    color: #fff;
    background-color: #ed6847;
    border-color: #ed6847; }
  .btn-sales:not(:disabled):not(.disabled):active, .btn-sales:not(:disabled):not(.disabled).active,
  .show > .btn-sales.dropdown-toggle {
    color: #fff;
    background-color: #e84219;
    border-color: #e84219; }
    .btn-sales:not(:disabled):not(.disabled):active:focus, .btn-sales:not(:disabled):not(.disabled).active:focus,
    .show > .btn-sales.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(237, 104, 71, 0.5); }

.btn-marketing-light {
  color: #fff;
  background-color: #daedec;
  border-color: #daedec; }
  .btn-marketing-light:hover {
    color: #fff;
    background-color: #000;
    border-color: #000; }
  .btn-marketing-light:focus, .btn-marketing-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(218, 237, 236, 0.5); }
  .btn-marketing-light.disabled, .btn-marketing-light:disabled {
    color: #fff;
    background-color: #daedec;
    border-color: #daedec; }
  .btn-marketing-light:not(:disabled):not(.disabled):active, .btn-marketing-light:not(:disabled):not(.disabled).active,
  .show > .btn-marketing-light.dropdown-toggle {
    color: #fff;
    background-color: #b8dcda;
    border-color: #b8dcda; }
    .btn-marketing-light:not(:disabled):not(.disabled):active:focus, .btn-marketing-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-marketing-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(218, 237, 236, 0.5); }

.btn-communication-light {
  color: #fff;
  background-color: #fce8ce;
  border-color: #fce8ce; }
  .btn-communication-light:hover {
    color: #fff;
    background-color: #000;
    border-color: #000; }
  .btn-communication-light:focus, .btn-communication-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(252, 232, 206, 0.5); }
  .btn-communication-light.disabled, .btn-communication-light:disabled {
    color: #fff;
    background-color: #fce8ce;
    border-color: #fce8ce; }
  .btn-communication-light:not(:disabled):not(.disabled):active, .btn-communication-light:not(:disabled):not(.disabled).active,
  .show > .btn-communication-light.dropdown-toggle {
    color: #fff;
    background-color: #f9d19e;
    border-color: #f9d19e; }
    .btn-communication-light:not(:disabled):not(.disabled):active:focus, .btn-communication-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-communication-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(252, 232, 206, 0.5); }

.btn-sales-light {
  color: #fff;
  background-color: #f9cabf;
  border-color: #f9cabf; }
  .btn-sales-light:hover {
    color: #fff;
    background-color: #000;
    border-color: #000; }
  .btn-sales-light:focus, .btn-sales-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(249, 202, 191, 0.5); }
  .btn-sales-light.disabled, .btn-sales-light:disabled {
    color: #fff;
    background-color: #f9cabf;
    border-color: #f9cabf; }
  .btn-sales-light:not(:disabled):not(.disabled):active, .btn-sales-light:not(:disabled):not(.disabled).active,
  .show > .btn-sales-light.dropdown-toggle {
    color: #fff;
    background-color: #f5a390;
    border-color: #f5a390; }
    .btn-sales-light:not(:disabled):not(.disabled):active:focus, .btn-sales-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-sales-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(249, 202, 191, 0.5); }

.btn-outline-primary {
  color: #ff6f00;
  background-color: transparent;
  background-image: none;
  border-color: #ff6f00; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #ff6f00;
    border-color: #ff6f00; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 111, 0, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #ff6f00;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #ff6f00;
    border-color: #ff6f00; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 111, 0, 0.5); }

.btn-outline-secondary {
  color: #94a0b2;
  background-color: transparent;
  background-image: none;
  border-color: #94a0b2; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #94a0b2;
    border-color: #94a0b2; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(148, 160, 178, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #94a0b2;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #212529;
    background-color: #94a0b2;
    border-color: #94a0b2; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(148, 160, 178, 0.5); }

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #fff;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #fff;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-outline-marketing {
  color: #85c4c1;
  background-color: transparent;
  background-image: none;
  border-color: #85c4c1; }
  .btn-outline-marketing:hover {
    color: #fff;
    background-color: #85c4c1;
    border-color: #85c4c1; }
  .btn-outline-marketing:focus, .btn-outline-marketing.focus {
    box-shadow: 0 0 0 0.2rem rgba(133, 196, 193, 0.5); }
  .btn-outline-marketing.disabled, .btn-outline-marketing:disabled {
    color: #85c4c1;
    background-color: transparent; }
  .btn-outline-marketing:not(:disabled):not(.disabled):active, .btn-outline-marketing:not(:disabled):not(.disabled).active,
  .show > .btn-outline-marketing.dropdown-toggle {
    color: #212529;
    background-color: #85c4c1;
    border-color: #85c4c1; }
    .btn-outline-marketing:not(:disabled):not(.disabled):active:focus, .btn-outline-marketing:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-marketing.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(133, 196, 193, 0.5); }

.btn-outline-communication {
  color: #edae47;
  background-color: transparent;
  background-image: none;
  border-color: #edae47; }
  .btn-outline-communication:hover {
    color: #fff;
    background-color: #edae47;
    border-color: #edae47; }
  .btn-outline-communication:focus, .btn-outline-communication.focus {
    box-shadow: 0 0 0 0.2rem rgba(237, 174, 71, 0.5); }
  .btn-outline-communication.disabled, .btn-outline-communication:disabled {
    color: #edae47;
    background-color: transparent; }
  .btn-outline-communication:not(:disabled):not(.disabled):active, .btn-outline-communication:not(:disabled):not(.disabled).active,
  .show > .btn-outline-communication.dropdown-toggle {
    color: #212529;
    background-color: #edae47;
    border-color: #edae47; }
    .btn-outline-communication:not(:disabled):not(.disabled):active:focus, .btn-outline-communication:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-communication.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(237, 174, 71, 0.5); }

.btn-outline-sales {
  color: #ed6847;
  background-color: transparent;
  background-image: none;
  border-color: #ed6847; }
  .btn-outline-sales:hover {
    color: #fff;
    background-color: #ed6847;
    border-color: #ed6847; }
  .btn-outline-sales:focus, .btn-outline-sales.focus {
    box-shadow: 0 0 0 0.2rem rgba(237, 104, 71, 0.5); }
  .btn-outline-sales.disabled, .btn-outline-sales:disabled {
    color: #ed6847;
    background-color: transparent; }
  .btn-outline-sales:not(:disabled):not(.disabled):active, .btn-outline-sales:not(:disabled):not(.disabled).active,
  .show > .btn-outline-sales.dropdown-toggle {
    color: #fff;
    background-color: #ed6847;
    border-color: #ed6847; }
    .btn-outline-sales:not(:disabled):not(.disabled):active:focus, .btn-outline-sales:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-sales.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(237, 104, 71, 0.5); }

.btn-outline-marketing-light {
  color: #daedec;
  background-color: transparent;
  background-image: none;
  border-color: #daedec; }
  .btn-outline-marketing-light:hover {
    color: #fff;
    background-color: #daedec;
    border-color: #daedec; }
  .btn-outline-marketing-light:focus, .btn-outline-marketing-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(218, 237, 236, 0.5); }
  .btn-outline-marketing-light.disabled, .btn-outline-marketing-light:disabled {
    color: #daedec;
    background-color: transparent; }
  .btn-outline-marketing-light:not(:disabled):not(.disabled):active, .btn-outline-marketing-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-marketing-light.dropdown-toggle {
    color: #212529;
    background-color: #daedec;
    border-color: #daedec; }
    .btn-outline-marketing-light:not(:disabled):not(.disabled):active:focus, .btn-outline-marketing-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-marketing-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(218, 237, 236, 0.5); }

.btn-outline-communication-light {
  color: #fce8ce;
  background-color: transparent;
  background-image: none;
  border-color: #fce8ce; }
  .btn-outline-communication-light:hover {
    color: #fff;
    background-color: #fce8ce;
    border-color: #fce8ce; }
  .btn-outline-communication-light:focus, .btn-outline-communication-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(252, 232, 206, 0.5); }
  .btn-outline-communication-light.disabled, .btn-outline-communication-light:disabled {
    color: #fce8ce;
    background-color: transparent; }
  .btn-outline-communication-light:not(:disabled):not(.disabled):active, .btn-outline-communication-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-communication-light.dropdown-toggle {
    color: #212529;
    background-color: #fce8ce;
    border-color: #fce8ce; }
    .btn-outline-communication-light:not(:disabled):not(.disabled):active:focus, .btn-outline-communication-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-communication-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(252, 232, 206, 0.5); }

.btn-outline-sales-light {
  color: #f9cabf;
  background-color: transparent;
  background-image: none;
  border-color: #f9cabf; }
  .btn-outline-sales-light:hover {
    color: #fff;
    background-color: #f9cabf;
    border-color: #f9cabf; }
  .btn-outline-sales-light:focus, .btn-outline-sales-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(249, 202, 191, 0.5); }
  .btn-outline-sales-light.disabled, .btn-outline-sales-light:disabled {
    color: #f9cabf;
    background-color: transparent; }
  .btn-outline-sales-light:not(:disabled):not(.disabled):active, .btn-outline-sales-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-sales-light.dropdown-toggle {
    color: #212529;
    background-color: #f9cabf;
    border-color: #f9cabf; }
    .btn-outline-sales-light:not(:disabled):not(.disabled):active:focus, .btn-outline-sales-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-sales-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(249, 202, 191, 0.5); }

.btn-link {
  font-weight: 400;
  color: #212121;
  background-color: transparent;
  padding-left: 1rem;
  padding-right: 1rem; }
  .btn-link:hover {
    color: #212121;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    border-color: transparent;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d; }

.btn-lg, .btn-group-lg > .btn {
  padding: 1.05rem 2rem;
  font-size: 2rem;
  line-height: 1.5;
  border-radius: 0.5rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.85rem 2rem;
  font-size: 1.4rem;
  line-height: 1.5;
  border-radius: 0.5rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.btn-icon-left .icon, .btn-icon-right .icon, .btn-icon-only .icon {
  width: 18px;
  height: 18px;
  font-size: 18px;
  line-height: 1;
  position: relative;
  top: .1rem; }

.btn-icon-left .icon {
  left: -.5rem;
  margin-right: .5rem; }

.btn-icon-right .icon {
  right: -.5rem;
  margin-left: .5rem; }

.btn-sm.btn-icon-left .icon, .btn-group-sm > .btn-icon-left.btn .icon, .btn-sm.btn-icon-right .icon, .btn-group-sm > .btn-icon-right.btn .icon {
  width: 15px;
  height: 15px;
  font-size: 15px;
  line-height: 1; }

.btn-icon-only {
  padding-left: 1.425rem;
  padding-right: 1.425rem; }
  .btn-icon-only.btn-lg, .btn-group-lg > .btn-icon-only.btn {
    padding-left: 1.575rem;
    padding-right: 1.575rem; }
  .btn-icon-only.btn-sm, .btn-group-sm > .btn-icon-only.btn {
    padding-left: 1.275rem;
    padding-right: 1.275rem; }

.fade {
  opacity: 0;
  transition: opacity 0.15s linear; }
  .fade.show {
    opacity: 1; }

.collapse {
  display: none; }
  .collapse.show {
    display: block; }

tr.collapse.show {
  display: table-row; }

tbody.collapse.show {
  display: table-row-group; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }

.dropup,
.dropdown {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-menu, span.twitter-typeahead .tt-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1.6rem;
  color: #212121;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropup .dropdown-menu, .dropup span.twitter-typeahead .tt-menu, span.twitter-typeahead .dropup .tt-menu {
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu, .dropright span.twitter-typeahead .tt-menu, span.twitter-typeahead .dropright .tt-menu {
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu, .dropleft span.twitter-typeahead .tt-menu, span.twitter-typeahead .dropleft .tt-menu {
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: 1rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item, span.twitter-typeahead .tt-suggestion {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, span.twitter-typeahead .tt-suggestion:hover, .dropdown-item:focus, span.twitter-typeahead .tt-suggestion:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, span.twitter-typeahead .active.tt-suggestion, span.twitter-typeahead .tt-suggestion.tt-cursor, .dropdown-item:active, span.twitter-typeahead .tt-suggestion:active {
    color: #fff;
    text-decoration: none;
    background-color: #ff6f00; }
  .dropdown-item.disabled, span.twitter-typeahead .disabled.tt-suggestion, .dropdown-item:disabled, span.twitter-typeahead .tt-suggestion:disabled {
    color: #6c757d;
    background-color: transparent; }

.dropdown-menu.show, span.twitter-typeahead .show.tt-menu {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 1.4rem;
  color: #6c757d;
  white-space: nowrap; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 0 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }
  .btn-group .btn + .btn,
  .btn-group .btn + .btn-group,
  .btn-group .btn-group + .btn,
  .btn-group .btn-group + .btn-group,
  .btn-group-vertical .btn + .btn,
  .btn-group-vertical .btn + .btn-group,
  .btn-group-vertical .btn-group + .btn,
  .btn-group-vertical .btn-group + .btn-group {
    margin-left: -1px; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:first-child {
  margin-left: 0; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 1.5rem;
  padding-left: 1.5rem; }
  .dropdown-toggle-split::after {
    margin-left: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 1.5rem;
  padding-left: 1.5rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.5rem;
  padding-left: 1.5rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical .btn,
  .btn-group-vertical .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn + .btn,
  .btn-group-vertical > .btn + .btn-group,
  .btn-group-vertical > .btn-group + .btn,
  .btn-group-vertical > .btn-group + .btn-group {
    margin-top: -1px;
    margin-left: 0; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control:focus,
    .input-group > .custom-select:focus,
    .input-group > .custom-file:focus {
      z-index: 3; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::before {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label,
    .input-group > .custom-file:not(:first-child) .custom-file-label::before {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.95rem 2rem;
  margin-bottom: 0;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 2.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #94a0b2;
    background-color: none; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #f5f8fa, 0 0 0 0.2rem rgba(255, 111, 0, 0.25); }
  .custom-control-input:active ~ .custom-control-label::before {
    color: #94a0b2;
    background-color: #fcfcfd; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #fcfcfd; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #fcfcfd; }

.custom-control-label {
  margin-bottom: 0; }
  .custom-control-label::before {
    position: absolute;
    top: 0.1rem;
    left: 0;
    display: block;
    width: 2rem;
    height: 2rem;
    pointer-events: none;
    content: "";
    user-select: none;
    border: 2px solid #94a0b2; }
  .custom-control-label::after {
    position: absolute;
    top: 0.1rem;
    left: 0;
    display: block;
    width: 2rem;
    height: 2rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 3px; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: none; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%2394a0b2' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #94a0b2; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%2394a0b2' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(255, 111, 0, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(255, 111, 0, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: none; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%2394a0b2'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(255, 111, 0, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(4.3rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #ffb780;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(255, 183, 128, 0.5); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    opacity: 0; }

.custom-select-sm {
  height: calc(3.8rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%; }

.custom-select-lg {
  height: calc(5.1rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(4.3rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(4.3rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-control {
    border-color: #ffb780;
    box-shadow: 0 0 0 0.2rem rgba(255, 111, 0, 0.25); }
    .custom-file-input:focus ~ .custom-file-control::before {
      border-color: #ffb780; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(4.3rem + 2px);
  padding: 0.95rem 2rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(calc(4.3rem + 2px) - 1px * 2);
    padding: 0.95rem 2rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: 1px solid #ced4da;
    border-radius: 0 0.25rem 0.25rem 0; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
  font-family: "Shift", sans-serif; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
    .nav-tabs .nav-item + .nav-item {
      margin-left: 20px; }
  .nav-tabs .nav-link {
    padding: 15px 20px;
    border-bottom: 3px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link, .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus, .nav-tabs .nav-link:active {
      color: #212121; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
    @media (min-width: 992px) {
      .nav-tabs .nav-link {
        min-width: 196px; } }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    /*background-color: $nav-tabs-link-active-bg;*/
    /*border-color: $nav-tabs-link-active-border-color;*/
    border-bottom-color: #9299a7; }
  .nav-tabs .dropdown-menu, .nav-tabs span.twitter-typeahead .tt-menu, span.twitter-typeahead .nav-tabs .tt-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #ff6f00; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  margin-right: 2rem;
  font-size: 2rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu, .navbar-nav span.twitter-typeahead .tt-menu, span.twitter-typeahead .navbar-nav .tt-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 2rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.5rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }
  .navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu, .navbar-expand-sm .navbar-nav span.twitter-typeahead .tt-menu, span.twitter-typeahead .navbar-expand-sm .navbar-nav .tt-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; }
    .navbar-expand-sm .dropup .dropdown-menu, .navbar-expand-sm .dropup span.twitter-typeahead .tt-menu, span.twitter-typeahead .navbar-expand-sm .dropup .tt-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu, .navbar-expand-md .navbar-nav span.twitter-typeahead .tt-menu, span.twitter-typeahead .navbar-expand-md .navbar-nav .tt-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; }
    .navbar-expand-md .dropup .dropdown-menu, .navbar-expand-md .dropup span.twitter-typeahead .tt-menu, span.twitter-typeahead .navbar-expand-md .dropup .tt-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu, .navbar-expand-lg .navbar-nav span.twitter-typeahead .tt-menu, span.twitter-typeahead .navbar-expand-lg .navbar-nav .tt-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; }
    .navbar-expand-lg .dropup .dropdown-menu, .navbar-expand-lg .dropup span.twitter-typeahead .tt-menu, span.twitter-typeahead .navbar-expand-lg .dropup .tt-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu, .navbar-expand-xl .navbar-nav span.twitter-typeahead .tt-menu, span.twitter-typeahead .navbar-expand-xl .navbar-nav .tt-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; }
    .navbar-expand-xl .dropup .dropdown-menu, .navbar-expand-xl .dropup span.twitter-typeahead .tt-menu, span.twitter-typeahead .navbar-expand-xl .dropup .tt-menu {
      top: auto;
      bottom: 100%; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu, .navbar-expand .navbar-nav span.twitter-typeahead .tt-menu, span.twitter-typeahead .navbar-expand .navbar-nav .tt-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .dropdown-menu-right {
      right: 0;
      left: auto; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }
  .navbar-expand .dropup .dropdown-menu, .navbar-expand .dropup span.twitter-typeahead .tt-menu, span.twitter-typeahead .navbar-expand .dropup .tt-menu {
    top: auto;
    bottom: 100%; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  color: #6c757d;
  content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  padding-right: 0;
  list-style: none;
  margin: 0;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: flex;
  padding: 0.5rem 0.75rem;
  font-size: 1.4rem;
  line-height: 1;
  color: #212121;
  font-size: 1.4rem;
  width: 3rem;
  height: 3rem;
  padding: 0;
  align-items: center;
  justify-content: center;
  border-radius: .4rem; }
  .page-link:hover {
    color: #212121;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 111, 0, 0.25); }
  .page-link:not(:disabled):not(.disabled) {
    cursor: pointer; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #212121;
  border-color: #212121; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.badge, .btn-badge {
  display: inline-block;
  padding: .6rem 1.5rem .4rem;
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  text-transform: uppercase;
  color: #fff;
  border-radius: 15px;
  transition: background 0.1s, border-color 0.1s, color 0.1s;
  border-width: 1px;
  border-style: solid;
  /*        .btn-group-toggle- &.btn-primary:not(.active) {
                    @include badge-variant($accent-default, #fff);
                }*/
  /*        .btn-group-toggle- &.btn-secondary:not(.active) {
                    @include badge-variant($accent-default, #fff);
                }*/
  /*        .btn-group-toggle- &.btn-success:not(.active) {
                    @include badge-variant($accent-default, #fff);
                }*/
  /*        .btn-group-toggle- &.btn-info:not(.active) {
                    @include badge-variant($accent-default, #fff);
                }*/
  /*        .btn-group-toggle- &.btn-warning:not(.active) {
                    @include badge-variant($accent-default, #fff);
                }*/
  /*        .btn-group-toggle- &.btn-danger:not(.active) {
                    @include badge-variant($accent-default, #fff);
                }*/
  /*        .btn-group-toggle- &.btn-light:not(.active) {
                    @include badge-variant($accent-default, #fff);
                }*/
  /*        .btn-group-toggle- &.btn-dark:not(.active) {
                    @include badge-variant($accent-default, #fff);
                }*/
  /*        .btn-group-toggle- &.btn-marketing:not(.active) {
                    @include badge-variant($accent-default, #fff);
                }*/
  /*        .btn-group-toggle- &.btn-communication:not(.active) {
                    @include badge-variant($accent-default, #fff);
                }*/
  /*        .btn-group-toggle- &.btn-sales:not(.active) {
                    @include badge-variant($accent-default, #fff);
                }*/
  /*        .btn-group-toggle- &.btn-marketing-light:not(.active) {
                    @include badge-variant($accent-default, #fff);
                }*/
  /*        .btn-group-toggle- &.btn-communication-light:not(.active) {
                    @include badge-variant($accent-default, #fff);
                }*/
  /*        .btn-group-toggle- &.btn-sales-light:not(.active) {
                    @include badge-variant($accent-default, #fff);
                }*/ }
  .badge:empty, .btn-badge:empty {
    display: none; }
  .badge.badge-default, .btn-badge.badge-default {
    background-color: #d8dfe8;
    color: #212121;
    border-color: #d8dfe8; }
    .badge.badge-default[href]:hover, .badge.badge-default[href]:focus, .btn-badge.badge-default[href]:hover, .btn-badge.badge-default[href]:focus {
      text-decoration: none;
      color: #212121;
      background-color: #b8c5d5;
      border-color: #b8c5d5; }
  .badge.badge-primary, .badge.btn-primary, .btn-badge.badge-primary, .btn-badge.btn-primary {
    background-color: #ff6f00;
    color: #fff;
    border-color: #ff6f00; }
    .badge.badge-primary[href]:hover, .badge.badge-primary[href]:focus, .badge.btn-primary[href]:hover, .badge.btn-primary[href]:focus, .btn-badge.badge-primary[href]:hover, .btn-badge.badge-primary[href]:focus, .btn-badge.btn-primary[href]:hover, .btn-badge.btn-primary[href]:focus {
      text-decoration: none;
      color: #fff;
      background-color: #cc5900;
      border-color: #cc5900; }
  .badge.badge-secondary, .badge.btn-secondary, .btn-badge.badge-secondary, .btn-badge.btn-secondary {
    background-color: #94a0b2;
    color: #fff;
    border-color: #94a0b2; }
    .badge.badge-secondary[href]:hover, .badge.badge-secondary[href]:focus, .badge.btn-secondary[href]:hover, .badge.btn-secondary[href]:focus, .btn-badge.badge-secondary[href]:hover, .btn-badge.badge-secondary[href]:focus, .btn-badge.btn-secondary[href]:hover, .btn-badge.btn-secondary[href]:focus {
      text-decoration: none;
      color: #fff;
      background-color: #76869d;
      border-color: #76869d; }
  .badge.badge-success, .badge.btn-success, .btn-badge.badge-success, .btn-badge.btn-success {
    background-color: #28a745;
    color: #fff;
    border-color: #28a745; }
    .badge.badge-success[href]:hover, .badge.badge-success[href]:focus, .badge.btn-success[href]:hover, .badge.btn-success[href]:focus, .btn-badge.badge-success[href]:hover, .btn-badge.badge-success[href]:focus, .btn-badge.btn-success[href]:hover, .btn-badge.btn-success[href]:focus {
      text-decoration: none;
      color: #fff;
      background-color: #1e7e34;
      border-color: #1e7e34; }
  .badge.badge-info, .badge.btn-info, .btn-badge.badge-info, .btn-badge.btn-info {
    background-color: #17a2b8;
    color: #fff;
    border-color: #17a2b8; }
    .badge.badge-info[href]:hover, .badge.badge-info[href]:focus, .badge.btn-info[href]:hover, .badge.btn-info[href]:focus, .btn-badge.badge-info[href]:hover, .btn-badge.badge-info[href]:focus, .btn-badge.btn-info[href]:hover, .btn-badge.btn-info[href]:focus {
      text-decoration: none;
      color: #fff;
      background-color: #117a8b;
      border-color: #117a8b; }
  .badge.badge-warning, .badge.btn-warning, .btn-badge.badge-warning, .btn-badge.btn-warning {
    background-color: #ffc107;
    color: #fff;
    border-color: #ffc107; }
    .badge.badge-warning[href]:hover, .badge.badge-warning[href]:focus, .badge.btn-warning[href]:hover, .badge.btn-warning[href]:focus, .btn-badge.badge-warning[href]:hover, .btn-badge.badge-warning[href]:focus, .btn-badge.btn-warning[href]:hover, .btn-badge.btn-warning[href]:focus {
      text-decoration: none;
      color: #fff;
      background-color: #d39e00;
      border-color: #d39e00; }
  .badge.badge-danger, .badge.btn-danger, .btn-badge.badge-danger, .btn-badge.btn-danger {
    background-color: #dc3545;
    color: #fff;
    border-color: #dc3545; }
    .badge.badge-danger[href]:hover, .badge.badge-danger[href]:focus, .badge.btn-danger[href]:hover, .badge.btn-danger[href]:focus, .btn-badge.badge-danger[href]:hover, .btn-badge.badge-danger[href]:focus, .btn-badge.btn-danger[href]:hover, .btn-badge.btn-danger[href]:focus {
      text-decoration: none;
      color: #fff;
      background-color: #bd2130;
      border-color: #bd2130; }
  .badge.badge-light, .badge.btn-light, .btn-badge.badge-light, .btn-badge.btn-light {
    background-color: #f8f9fa;
    color: #fff;
    border-color: #f8f9fa; }
    .badge.badge-light[href]:hover, .badge.badge-light[href]:focus, .badge.btn-light[href]:hover, .badge.btn-light[href]:focus, .btn-badge.badge-light[href]:hover, .btn-badge.badge-light[href]:focus, .btn-badge.btn-light[href]:hover, .btn-badge.btn-light[href]:focus {
      text-decoration: none;
      color: #fff;
      background-color: #dae0e5;
      border-color: #dae0e5; }
  .badge.badge-dark, .badge.btn-dark, .btn-badge.badge-dark, .btn-badge.btn-dark {
    background-color: #343a40;
    color: #fff;
    border-color: #343a40; }
    .badge.badge-dark[href]:hover, .badge.badge-dark[href]:focus, .badge.btn-dark[href]:hover, .badge.btn-dark[href]:focus, .btn-badge.badge-dark[href]:hover, .btn-badge.badge-dark[href]:focus, .btn-badge.btn-dark[href]:hover, .btn-badge.btn-dark[href]:focus {
      text-decoration: none;
      color: #fff;
      background-color: #1d2124;
      border-color: #1d2124; }
  .badge.badge-marketing, .badge.btn-marketing, .btn-badge.badge-marketing, .btn-badge.btn-marketing {
    background-color: #85c4c1;
    color: #fff;
    border-color: #85c4c1; }
    .badge.badge-marketing[href]:hover, .badge.badge-marketing[href]:focus, .badge.btn-marketing[href]:hover, .badge.btn-marketing[href]:focus, .btn-badge.badge-marketing[href]:hover, .btn-badge.badge-marketing[href]:focus, .btn-badge.btn-marketing[href]:hover, .btn-badge.btn-marketing[href]:focus {
      text-decoration: none;
      color: #fff;
      background-color: #63b3b0;
      border-color: #63b3b0; }
  .badge.badge-communication, .badge.btn-communication, .btn-badge.badge-communication, .btn-badge.btn-communication {
    background-color: #edae47;
    color: #fff;
    border-color: #edae47; }
    .badge.badge-communication[href]:hover, .badge.badge-communication[href]:focus, .badge.btn-communication[href]:hover, .badge.btn-communication[href]:focus, .btn-badge.badge-communication[href]:hover, .btn-badge.badge-communication[href]:focus, .btn-badge.btn-communication[href]:hover, .btn-badge.btn-communication[href]:focus {
      text-decoration: none;
      color: #fff;
      background-color: #e89a19;
      border-color: #e89a19; }
  .badge.badge-sales, .badge.btn-sales, .btn-badge.badge-sales, .btn-badge.btn-sales {
    background-color: #ed6847;
    color: #fff;
    border-color: #ed6847; }
    .badge.badge-sales[href]:hover, .badge.badge-sales[href]:focus, .badge.btn-sales[href]:hover, .badge.btn-sales[href]:focus, .btn-badge.badge-sales[href]:hover, .btn-badge.badge-sales[href]:focus, .btn-badge.btn-sales[href]:hover, .btn-badge.btn-sales[href]:focus {
      text-decoration: none;
      color: #fff;
      background-color: #e84219;
      border-color: #e84219; }
  .badge.badge-marketing-light, .badge.btn-marketing-light, .btn-badge.badge-marketing-light, .btn-badge.btn-marketing-light {
    background-color: #daedec;
    color: #fff;
    border-color: #daedec; }
    .badge.badge-marketing-light[href]:hover, .badge.badge-marketing-light[href]:focus, .badge.btn-marketing-light[href]:hover, .badge.btn-marketing-light[href]:focus, .btn-badge.badge-marketing-light[href]:hover, .btn-badge.badge-marketing-light[href]:focus, .btn-badge.btn-marketing-light[href]:hover, .btn-badge.btn-marketing-light[href]:focus {
      text-decoration: none;
      color: #fff;
      background-color: #b8dcda;
      border-color: #b8dcda; }
  .badge.badge-communication-light, .badge.btn-communication-light, .btn-badge.badge-communication-light, .btn-badge.btn-communication-light {
    background-color: #fce8ce;
    color: #fff;
    border-color: #fce8ce; }
    .badge.badge-communication-light[href]:hover, .badge.badge-communication-light[href]:focus, .badge.btn-communication-light[href]:hover, .badge.btn-communication-light[href]:focus, .btn-badge.badge-communication-light[href]:hover, .btn-badge.badge-communication-light[href]:focus, .btn-badge.btn-communication-light[href]:hover, .btn-badge.btn-communication-light[href]:focus {
      text-decoration: none;
      color: #fff;
      background-color: #f9d19e;
      border-color: #f9d19e; }
  .badge.badge-sales-light, .badge.btn-sales-light, .btn-badge.badge-sales-light, .btn-badge.btn-sales-light {
    background-color: #f9cabf;
    color: #fff;
    border-color: #f9cabf; }
    .badge.badge-sales-light[href]:hover, .badge.badge-sales-light[href]:focus, .badge.btn-sales-light[href]:hover, .badge.btn-sales-light[href]:focus, .btn-badge.badge-sales-light[href]:hover, .btn-badge.badge-sales-light[href]:focus, .btn-badge.btn-sales-light[href]:hover, .btn-badge.btn-sales-light[href]:focus {
      text-decoration: none;
      color: #fff;
      background-color: #f5a390;
      border-color: #f5a390; }
  .badge.badge-outline-primary, .badge.btn-outline-primary, .btn-group-toggle .badge.btn-outline-primary.active, .btn-badge.badge-outline-primary, .btn-badge.btn-outline-primary, .btn-group-toggle .btn-badge.btn-outline-primary.active {
    border-color: #ff6f00;
    color: #ff6f00; }
    .badge.badge-outline-primary[href]:hover, .badge.badge-outline-primary[href]:focus, .badge.btn-outline-primary[href]:hover, .badge.btn-outline-primary[href]:focus, .btn-group-toggle .badge.btn-outline-primary.active[href]:hover, .btn-group-toggle .badge.btn-outline-primary.active[href]:focus, .btn-badge.badge-outline-primary[href]:hover, .btn-badge.badge-outline-primary[href]:focus, .btn-badge.btn-outline-primary[href]:hover, .btn-badge.btn-outline-primary[href]:focus, .btn-group-toggle .btn-badge.btn-outline-primary.active[href]:hover, .btn-group-toggle .btn-badge.btn-outline-primary.active[href]:focus {
      text-decoration: none;
      border-color: #ff6f00;
      color: #fff;
      background-color: #ff6f00; }
  .badge.badge-outline-secondary, .badge.btn-outline-secondary, .btn-group-toggle .badge.btn-outline-secondary.active, .btn-badge.badge-outline-secondary, .btn-badge.btn-outline-secondary, .btn-group-toggle .btn-badge.btn-outline-secondary.active {
    border-color: #94a0b2;
    color: #94a0b2; }
    .badge.badge-outline-secondary[href]:hover, .badge.badge-outline-secondary[href]:focus, .badge.btn-outline-secondary[href]:hover, .badge.btn-outline-secondary[href]:focus, .btn-group-toggle .badge.btn-outline-secondary.active[href]:hover, .btn-group-toggle .badge.btn-outline-secondary.active[href]:focus, .btn-badge.badge-outline-secondary[href]:hover, .btn-badge.badge-outline-secondary[href]:focus, .btn-badge.btn-outline-secondary[href]:hover, .btn-badge.btn-outline-secondary[href]:focus, .btn-group-toggle .btn-badge.btn-outline-secondary.active[href]:hover, .btn-group-toggle .btn-badge.btn-outline-secondary.active[href]:focus {
      text-decoration: none;
      border-color: #94a0b2;
      color: #fff;
      background-color: #94a0b2; }
  .badge.badge-outline-success, .badge.btn-outline-success, .btn-group-toggle .badge.btn-outline-success.active, .btn-badge.badge-outline-success, .btn-badge.btn-outline-success, .btn-group-toggle .btn-badge.btn-outline-success.active {
    border-color: #28a745;
    color: #28a745; }
    .badge.badge-outline-success[href]:hover, .badge.badge-outline-success[href]:focus, .badge.btn-outline-success[href]:hover, .badge.btn-outline-success[href]:focus, .btn-group-toggle .badge.btn-outline-success.active[href]:hover, .btn-group-toggle .badge.btn-outline-success.active[href]:focus, .btn-badge.badge-outline-success[href]:hover, .btn-badge.badge-outline-success[href]:focus, .btn-badge.btn-outline-success[href]:hover, .btn-badge.btn-outline-success[href]:focus, .btn-group-toggle .btn-badge.btn-outline-success.active[href]:hover, .btn-group-toggle .btn-badge.btn-outline-success.active[href]:focus {
      text-decoration: none;
      border-color: #28a745;
      color: #fff;
      background-color: #28a745; }
  .badge.badge-outline-info, .badge.btn-outline-info, .btn-group-toggle .badge.btn-outline-info.active, .btn-badge.badge-outline-info, .btn-badge.btn-outline-info, .btn-group-toggle .btn-badge.btn-outline-info.active {
    border-color: #17a2b8;
    color: #17a2b8; }
    .badge.badge-outline-info[href]:hover, .badge.badge-outline-info[href]:focus, .badge.btn-outline-info[href]:hover, .badge.btn-outline-info[href]:focus, .btn-group-toggle .badge.btn-outline-info.active[href]:hover, .btn-group-toggle .badge.btn-outline-info.active[href]:focus, .btn-badge.badge-outline-info[href]:hover, .btn-badge.badge-outline-info[href]:focus, .btn-badge.btn-outline-info[href]:hover, .btn-badge.btn-outline-info[href]:focus, .btn-group-toggle .btn-badge.btn-outline-info.active[href]:hover, .btn-group-toggle .btn-badge.btn-outline-info.active[href]:focus {
      text-decoration: none;
      border-color: #17a2b8;
      color: #fff;
      background-color: #17a2b8; }
  .badge.badge-outline-warning, .badge.btn-outline-warning, .btn-group-toggle .badge.btn-outline-warning.active, .btn-badge.badge-outline-warning, .btn-badge.btn-outline-warning, .btn-group-toggle .btn-badge.btn-outline-warning.active {
    border-color: #ffc107;
    color: #ffc107; }
    .badge.badge-outline-warning[href]:hover, .badge.badge-outline-warning[href]:focus, .badge.btn-outline-warning[href]:hover, .badge.btn-outline-warning[href]:focus, .btn-group-toggle .badge.btn-outline-warning.active[href]:hover, .btn-group-toggle .badge.btn-outline-warning.active[href]:focus, .btn-badge.badge-outline-warning[href]:hover, .btn-badge.badge-outline-warning[href]:focus, .btn-badge.btn-outline-warning[href]:hover, .btn-badge.btn-outline-warning[href]:focus, .btn-group-toggle .btn-badge.btn-outline-warning.active[href]:hover, .btn-group-toggle .btn-badge.btn-outline-warning.active[href]:focus {
      text-decoration: none;
      border-color: #ffc107;
      color: #fff;
      background-color: #ffc107; }
  .badge.badge-outline-danger, .badge.btn-outline-danger, .btn-group-toggle .badge.btn-outline-danger.active, .btn-badge.badge-outline-danger, .btn-badge.btn-outline-danger, .btn-group-toggle .btn-badge.btn-outline-danger.active {
    border-color: #dc3545;
    color: #dc3545; }
    .badge.badge-outline-danger[href]:hover, .badge.badge-outline-danger[href]:focus, .badge.btn-outline-danger[href]:hover, .badge.btn-outline-danger[href]:focus, .btn-group-toggle .badge.btn-outline-danger.active[href]:hover, .btn-group-toggle .badge.btn-outline-danger.active[href]:focus, .btn-badge.badge-outline-danger[href]:hover, .btn-badge.badge-outline-danger[href]:focus, .btn-badge.btn-outline-danger[href]:hover, .btn-badge.btn-outline-danger[href]:focus, .btn-group-toggle .btn-badge.btn-outline-danger.active[href]:hover, .btn-group-toggle .btn-badge.btn-outline-danger.active[href]:focus {
      text-decoration: none;
      border-color: #dc3545;
      color: #fff;
      background-color: #dc3545; }
  .badge.badge-outline-light, .badge.btn-outline-light, .btn-group-toggle .badge.btn-outline-light.active, .btn-badge.badge-outline-light, .btn-badge.btn-outline-light, .btn-group-toggle .btn-badge.btn-outline-light.active {
    border-color: #f8f9fa;
    color: #f8f9fa; }
    .badge.badge-outline-light[href]:hover, .badge.badge-outline-light[href]:focus, .badge.btn-outline-light[href]:hover, .badge.btn-outline-light[href]:focus, .btn-group-toggle .badge.btn-outline-light.active[href]:hover, .btn-group-toggle .badge.btn-outline-light.active[href]:focus, .btn-badge.badge-outline-light[href]:hover, .btn-badge.badge-outline-light[href]:focus, .btn-badge.btn-outline-light[href]:hover, .btn-badge.btn-outline-light[href]:focus, .btn-group-toggle .btn-badge.btn-outline-light.active[href]:hover, .btn-group-toggle .btn-badge.btn-outline-light.active[href]:focus {
      text-decoration: none;
      border-color: #f8f9fa;
      color: #fff;
      background-color: #f8f9fa; }
  .badge.badge-outline-dark, .badge.btn-outline-dark, .btn-group-toggle .badge.btn-outline-dark.active, .btn-badge.badge-outline-dark, .btn-badge.btn-outline-dark, .btn-group-toggle .btn-badge.btn-outline-dark.active {
    border-color: #343a40;
    color: #343a40; }
    .badge.badge-outline-dark[href]:hover, .badge.badge-outline-dark[href]:focus, .badge.btn-outline-dark[href]:hover, .badge.btn-outline-dark[href]:focus, .btn-group-toggle .badge.btn-outline-dark.active[href]:hover, .btn-group-toggle .badge.btn-outline-dark.active[href]:focus, .btn-badge.badge-outline-dark[href]:hover, .btn-badge.badge-outline-dark[href]:focus, .btn-badge.btn-outline-dark[href]:hover, .btn-badge.btn-outline-dark[href]:focus, .btn-group-toggle .btn-badge.btn-outline-dark.active[href]:hover, .btn-group-toggle .btn-badge.btn-outline-dark.active[href]:focus {
      text-decoration: none;
      border-color: #343a40;
      color: #fff;
      background-color: #343a40; }
  .badge.badge-outline-marketing, .badge.btn-outline-marketing, .btn-group-toggle .badge.btn-outline-marketing.active, .btn-badge.badge-outline-marketing, .btn-badge.btn-outline-marketing, .btn-group-toggle .btn-badge.btn-outline-marketing.active {
    border-color: #85c4c1;
    color: #85c4c1; }
    .badge.badge-outline-marketing[href]:hover, .badge.badge-outline-marketing[href]:focus, .badge.btn-outline-marketing[href]:hover, .badge.btn-outline-marketing[href]:focus, .btn-group-toggle .badge.btn-outline-marketing.active[href]:hover, .btn-group-toggle .badge.btn-outline-marketing.active[href]:focus, .btn-badge.badge-outline-marketing[href]:hover, .btn-badge.badge-outline-marketing[href]:focus, .btn-badge.btn-outline-marketing[href]:hover, .btn-badge.btn-outline-marketing[href]:focus, .btn-group-toggle .btn-badge.btn-outline-marketing.active[href]:hover, .btn-group-toggle .btn-badge.btn-outline-marketing.active[href]:focus {
      text-decoration: none;
      border-color: #85c4c1;
      color: #fff;
      background-color: #85c4c1; }
  .badge.badge-outline-communication, .badge.btn-outline-communication, .btn-group-toggle .badge.btn-outline-communication.active, .btn-badge.badge-outline-communication, .btn-badge.btn-outline-communication, .btn-group-toggle .btn-badge.btn-outline-communication.active {
    border-color: #edae47;
    color: #edae47; }
    .badge.badge-outline-communication[href]:hover, .badge.badge-outline-communication[href]:focus, .badge.btn-outline-communication[href]:hover, .badge.btn-outline-communication[href]:focus, .btn-group-toggle .badge.btn-outline-communication.active[href]:hover, .btn-group-toggle .badge.btn-outline-communication.active[href]:focus, .btn-badge.badge-outline-communication[href]:hover, .btn-badge.badge-outline-communication[href]:focus, .btn-badge.btn-outline-communication[href]:hover, .btn-badge.btn-outline-communication[href]:focus, .btn-group-toggle .btn-badge.btn-outline-communication.active[href]:hover, .btn-group-toggle .btn-badge.btn-outline-communication.active[href]:focus {
      text-decoration: none;
      border-color: #edae47;
      color: #fff;
      background-color: #edae47; }
  .badge.badge-outline-sales, .badge.btn-outline-sales, .btn-group-toggle .badge.btn-outline-sales.active, .btn-badge.badge-outline-sales, .btn-badge.btn-outline-sales, .btn-group-toggle .btn-badge.btn-outline-sales.active {
    border-color: #ed6847;
    color: #ed6847; }
    .badge.badge-outline-sales[href]:hover, .badge.badge-outline-sales[href]:focus, .badge.btn-outline-sales[href]:hover, .badge.btn-outline-sales[href]:focus, .btn-group-toggle .badge.btn-outline-sales.active[href]:hover, .btn-group-toggle .badge.btn-outline-sales.active[href]:focus, .btn-badge.badge-outline-sales[href]:hover, .btn-badge.badge-outline-sales[href]:focus, .btn-badge.btn-outline-sales[href]:hover, .btn-badge.btn-outline-sales[href]:focus, .btn-group-toggle .btn-badge.btn-outline-sales.active[href]:hover, .btn-group-toggle .btn-badge.btn-outline-sales.active[href]:focus {
      text-decoration: none;
      border-color: #ed6847;
      color: #fff;
      background-color: #ed6847; }
  .badge.badge-outline-marketing-light, .badge.btn-outline-marketing-light, .btn-group-toggle .badge.btn-outline-marketing-light.active, .btn-badge.badge-outline-marketing-light, .btn-badge.btn-outline-marketing-light, .btn-group-toggle .btn-badge.btn-outline-marketing-light.active {
    border-color: #daedec;
    color: #daedec; }
    .badge.badge-outline-marketing-light[href]:hover, .badge.badge-outline-marketing-light[href]:focus, .badge.btn-outline-marketing-light[href]:hover, .badge.btn-outline-marketing-light[href]:focus, .btn-group-toggle .badge.btn-outline-marketing-light.active[href]:hover, .btn-group-toggle .badge.btn-outline-marketing-light.active[href]:focus, .btn-badge.badge-outline-marketing-light[href]:hover, .btn-badge.badge-outline-marketing-light[href]:focus, .btn-badge.btn-outline-marketing-light[href]:hover, .btn-badge.btn-outline-marketing-light[href]:focus, .btn-group-toggle .btn-badge.btn-outline-marketing-light.active[href]:hover, .btn-group-toggle .btn-badge.btn-outline-marketing-light.active[href]:focus {
      text-decoration: none;
      border-color: #daedec;
      color: #fff;
      background-color: #daedec; }
  .badge.badge-outline-communication-light, .badge.btn-outline-communication-light, .btn-group-toggle .badge.btn-outline-communication-light.active, .btn-badge.badge-outline-communication-light, .btn-badge.btn-outline-communication-light, .btn-group-toggle .btn-badge.btn-outline-communication-light.active {
    border-color: #fce8ce;
    color: #fce8ce; }
    .badge.badge-outline-communication-light[href]:hover, .badge.badge-outline-communication-light[href]:focus, .badge.btn-outline-communication-light[href]:hover, .badge.btn-outline-communication-light[href]:focus, .btn-group-toggle .badge.btn-outline-communication-light.active[href]:hover, .btn-group-toggle .badge.btn-outline-communication-light.active[href]:focus, .btn-badge.badge-outline-communication-light[href]:hover, .btn-badge.badge-outline-communication-light[href]:focus, .btn-badge.btn-outline-communication-light[href]:hover, .btn-badge.btn-outline-communication-light[href]:focus, .btn-group-toggle .btn-badge.btn-outline-communication-light.active[href]:hover, .btn-group-toggle .btn-badge.btn-outline-communication-light.active[href]:focus {
      text-decoration: none;
      border-color: #fce8ce;
      color: #fff;
      background-color: #fce8ce; }
  .badge.badge-outline-sales-light, .badge.btn-outline-sales-light, .btn-group-toggle .badge.btn-outline-sales-light.active, .btn-badge.badge-outline-sales-light, .btn-badge.btn-outline-sales-light, .btn-group-toggle .btn-badge.btn-outline-sales-light.active {
    border-color: #f9cabf;
    color: #f9cabf; }
    .badge.badge-outline-sales-light[href]:hover, .badge.badge-outline-sales-light[href]:focus, .badge.btn-outline-sales-light[href]:hover, .badge.btn-outline-sales-light[href]:focus, .btn-group-toggle .badge.btn-outline-sales-light.active[href]:hover, .btn-group-toggle .badge.btn-outline-sales-light.active[href]:focus, .btn-badge.badge-outline-sales-light[href]:hover, .btn-badge.badge-outline-sales-light[href]:focus, .btn-badge.btn-outline-sales-light[href]:hover, .btn-badge.btn-outline-sales-light[href]:focus, .btn-group-toggle .btn-badge.btn-outline-sales-light.active[href]:hover, .btn-group-toggle .btn-badge.btn-outline-sales-light.active[href]:focus {
      text-decoration: none;
      border-color: #f9cabf;
      color: #fff;
      background-color: #f9cabf; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-list {
  display: flex;
  justify-content: center;
  align-content: center; }
  .badge-list .badge__remove {
    width: 1rem;
    height: 1rem;
    font-size: 1rem;
    line-height: 1;
    height: .8rem;
    margin-top: -.2rem;
    margin-left: .5rem; }
    .badge-list .badge__remove, .badge-list .badge__remove:hover, .badge-list .badge__remove:focus, .badge-list .badge__remove:active {
      color: #212121; }
  @media (max-width: 767.98px) {
    .badge-list {
      padding: 1rem 0;
      flex-direction: column;
      height: auto; }
      .badge-list .badge, .badge-list .btn-badge {
        width: 100%;
        /*text-align: left;*/ }
        .badge-list .badge span, .badge-list .btn-badge span {
          flex-grow: 1;
          text-align: left; }
      .badge-list .badge + .badge, .badge-list .btn-badge + .btn-badge {
        margin-top: .5rem; } }
  @media (min-width: 768px) {
    .badge-list {
      flex-wrap: wrap; }
      .badge-list .badge, .badge-list .btn-badge {
        margin: 4px; } }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4.9rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #853a00;
  background-color: #ffe2cc;
  border-color: #ffd7b8; }
  .alert-primary hr {
    border-top-color: #ffc99f; }
  .alert-primary .alert-link {
    color: #522400; }

.alert-secondary {
  color: #4d535d;
  background-color: #eaecf0;
  border-color: #e1e4e9; }
  .alert-secondary hr {
    border-top-color: #d2d7de; }
  .alert-secondary .alert-link {
    color: #363a41; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

.alert-marketing {
  color: #456664;
  background-color: #e7f3f3;
  border-color: #ddeeee; }
  .alert-marketing hr {
    border-top-color: #cce6e6; }
  .alert-marketing .alert-link {
    color: #304846; }

.alert-communication {
  color: #7b5a25;
  background-color: #fbefda;
  border-color: #fae8cb; }
  .alert-communication hr {
    border-top-color: #f8deb4; }
  .alert-communication .alert-link {
    color: #543d19; }

.alert-sales {
  color: #7b3625;
  background-color: #fbe1da;
  border-color: #fad5cb; }
  .alert-sales hr {
    border-top-color: #f8c2b4; }
  .alert-sales .alert-link {
    color: #542519; }

.alert-marketing-light {
  color: #717b7b;
  background-color: #f8fbfb;
  border-color: #f5fafa; }
  .alert-marketing-light hr {
    border-top-color: #e4f2f2; }
  .alert-marketing-light .alert-link {
    color: #596060; }

.alert-communication-light {
  color: #83796b;
  background-color: #fefaf5;
  border-color: #fef9f1; }
  .alert-communication-light hr {
    border-top-color: #fcefd9; }
  .alert-communication-light .alert-link {
    color: #675f54; }

.alert-sales-light {
  color: #816963;
  background-color: #fef4f2;
  border-color: #fdf0ed; }
  .alert-sales-light hr {
    border-top-color: #faddd6; }
  .alert-sales-light .alert-link {
    color: #64524d; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 1.2rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  background-color: #ff6f00;
  transition: width 0.6s ease; }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212121;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item:hover, .list-group-item:focus {
    z-index: 1;
    text-decoration: none; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #ff6f00;
    border-color: #ff6f00; }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0; }

.list-group-item-primary {
  color: #853a00;
  background-color: #ffd7b8; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #853a00;
    background-color: #ffc99f; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #853a00;
    border-color: #853a00; }

.list-group-item-secondary {
  color: #4d535d;
  background-color: #e1e4e9; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #4d535d;
    background-color: #d2d7de; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #4d535d;
    border-color: #4d535d; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.list-group-item-marketing {
  color: #456664;
  background-color: #ddeeee; }
  .list-group-item-marketing.list-group-item-action:hover, .list-group-item-marketing.list-group-item-action:focus {
    color: #456664;
    background-color: #cce6e6; }
  .list-group-item-marketing.list-group-item-action.active {
    color: #fff;
    background-color: #456664;
    border-color: #456664; }

.list-group-item-communication {
  color: #7b5a25;
  background-color: #fae8cb; }
  .list-group-item-communication.list-group-item-action:hover, .list-group-item-communication.list-group-item-action:focus {
    color: #7b5a25;
    background-color: #f8deb4; }
  .list-group-item-communication.list-group-item-action.active {
    color: #fff;
    background-color: #7b5a25;
    border-color: #7b5a25; }

.list-group-item-sales {
  color: #7b3625;
  background-color: #fad5cb; }
  .list-group-item-sales.list-group-item-action:hover, .list-group-item-sales.list-group-item-action:focus {
    color: #7b3625;
    background-color: #f8c2b4; }
  .list-group-item-sales.list-group-item-action.active {
    color: #fff;
    background-color: #7b3625;
    border-color: #7b3625; }

.list-group-item-marketing-light {
  color: #717b7b;
  background-color: #f5fafa; }
  .list-group-item-marketing-light.list-group-item-action:hover, .list-group-item-marketing-light.list-group-item-action:focus {
    color: #717b7b;
    background-color: #e4f2f2; }
  .list-group-item-marketing-light.list-group-item-action.active {
    color: #fff;
    background-color: #717b7b;
    border-color: #717b7b; }

.list-group-item-communication-light {
  color: #83796b;
  background-color: #fef9f1; }
  .list-group-item-communication-light.list-group-item-action:hover, .list-group-item-communication-light.list-group-item-action:focus {
    color: #83796b;
    background-color: #fcefd9; }
  .list-group-item-communication-light.list-group-item-action.active {
    color: #fff;
    background-color: #83796b;
    border-color: #83796b; }

.list-group-item-sales-light {
  color: #816963;
  background-color: #fdf0ed; }
  .list-group-item-sales-light.list-group-item-action:hover, .list-group-item-sales-light.list-group-item-action:focus {
    color: #816963;
    background-color: #faddd6; }
  .list-group-item-sales-light.list-group-item-action.active {
    color: #fff;
    background-color: #816963;
    border-color: #816963; }

.close {
  float: right;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover, .close:focus {
    color: #000;
    text-decoration: none;
    opacity: .75; }
  .close:not(:disabled):not(.disabled) {
    cursor: pointer; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none; }

.modal-open {
  overflow: hidden; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -25%); }
  .modal.show .modal-dialog {
    transform: translate(0, 0); }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2)); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2)); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Fort", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 1.4rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 1; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #9299a7; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #9299a7; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #9299a7; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #9299a7; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #9299a7;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Fort", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 1.4rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
  .bs-popover-top .arrow::after, .bs-popover-auto[x-placement^="top"] .arrow::after {
    border-width: 0.5rem 0.5rem 0; }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
    bottom: 0;
    border-top-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-top .arrow::after, .bs-popover-auto[x-placement^="top"] .arrow::after {
    bottom: 1px;
    border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
  .bs-popover-right .arrow::after, .bs-popover-auto[x-placement^="right"] .arrow::after {
    border-width: 0.5rem 0.5rem 0.5rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
    left: 0;
    border-right-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-right .arrow::after, .bs-popover-auto[x-placement^="right"] .arrow::after {
    left: 1px;
    border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
    top: calc((0.5rem + 1px) * -1); }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
  .bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-width: 0 0.5rem 0.5rem 0.5rem; }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
    top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    top: 1px;
    border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
  .bs-popover-left .arrow::after, .bs-popover-auto[x-placement^="left"] .arrow::after {
    border-width: 0.5rem 0 0.5rem 0.5rem; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
    right: 0;
    border-left-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-left .arrow::after, .bs-popover-auto[x-placement^="left"] .arrow::after {
    right: 1px;
    border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1.6rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212121; }

.carousel {
  position: relative; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  transition: transform 0.6s ease;
  backface-visibility: hidden;
  perspective: 1000px; }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0; }

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
      transform: translate3d(0, 0, 0); } }

.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next,
    .active.carousel-item-right {
      transform: translate3d(100%, 0, 0); } }

.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-prev,
    .active.carousel-item-left {
      transform: translate3d(-100%, 0, 0); } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5; }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    position: relative;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    background-color: rgba(255, 255, 255, 0.5); }
    .carousel-indicators li::before {
      position: absolute;
      top: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
    .carousel-indicators li::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
  .carousel-indicators .active {
    background-color: #fff; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #ff6f00 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #cc5900 !important; }

.bg-secondary {
  background-color: #94a0b2 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #76869d !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-marketing {
  background-color: #85c4c1 !important; }

a.bg-marketing:hover, a.bg-marketing:focus,
button.bg-marketing:hover,
button.bg-marketing:focus {
  background-color: #63b3b0 !important; }

.bg-communication {
  background-color: #edae47 !important; }

a.bg-communication:hover, a.bg-communication:focus,
button.bg-communication:hover,
button.bg-communication:focus {
  background-color: #e89a19 !important; }

.bg-sales {
  background-color: #ed6847 !important; }

a.bg-sales:hover, a.bg-sales:focus,
button.bg-sales:hover,
button.bg-sales:focus {
  background-color: #e84219 !important; }

.bg-marketing-light {
  background-color: #daedec !important; }

a.bg-marketing-light:hover, a.bg-marketing-light:focus,
button.bg-marketing-light:hover,
button.bg-marketing-light:focus {
  background-color: #b8dcda !important; }

.bg-communication-light {
  background-color: #fce8ce !important; }

a.bg-communication-light:hover, a.bg-communication-light:focus,
button.bg-communication-light:hover,
button.bg-communication-light:focus {
  background-color: #f9d19e !important; }

.bg-sales-light {
  background-color: #f9cabf !important; }

a.bg-sales-light:hover, a.bg-sales-light:focus,
button.bg-sales-light:hover,
button.bg-sales-light:focus {
  background-color: #f5a390 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #ff6f00 !important; }

.border-secondary {
  border-color: #94a0b2 !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-marketing {
  border-color: #85c4c1 !important; }

.border-communication {
  border-color: #edae47 !important; }

.border-sales {
  border-color: #ed6847 !important; }

.border-marketing-light {
  border-color: #daedec !important; }

.border-communication-light {
  border-color: #fce8ce !important; }

.border-sales-light {
  border-color: #f9cabf !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-10 {
  width: 10% !important; }

.w-15 {
  width: 15% !important; }

.w-20 {
  width: 20% !important; }

.w-30 {
  width: 30% !important; }

.w-35 {
  width: 35% !important; }

.w-40 {
  width: 40% !important; }

.w-45 {
  width: 45% !important; }

.w-55 {
  width: 55% !important; }

.w-60 {
  width: 60% !important; }

.w-65 {
  width: 65% !important; }

.w-70 {
  width: 70% !important; }

.w-80 {
  width: 80% !important; }

.w-85 {
  width: 85% !important; }

.w-90 {
  width: 90% !important; }

.w-95 {
  width: 95% !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-10 {
  height: 10% !important; }

.h-15 {
  height: 15% !important; }

.h-20 {
  height: 20% !important; }

.h-30 {
  height: 30% !important; }

.h-35 {
  height: 35% !important; }

.h-40 {
  height: 40% !important; }

.h-45 {
  height: 45% !important; }

.h-55 {
  height: 55% !important; }

.h-60 {
  height: 60% !important; }

.h-65 {
  height: 65% !important; }

.h-70 {
  height: 70% !important; }

.h-80 {
  height: 80% !important; }

.h-85 {
  height: 85% !important; }

.h-90 {
  height: 90% !important; }

.h-95 {
  height: 95% !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.5rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.5rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.5rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.5rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.5rem !important; }

.m-2 {
  margin: 1rem !important; }

.mt-2,
.my-2 {
  margin-top: 1rem !important; }

.mr-2,
.mx-2 {
  margin-right: 1rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 1rem !important; }

.ml-2,
.mx-2 {
  margin-left: 1rem !important; }

.m-3 {
  margin: 2rem !important; }

.mt-3,
.my-3 {
  margin-top: 2rem !important; }

.mr-3,
.mx-3 {
  margin-right: 2rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 2rem !important; }

.ml-3,
.mx-3 {
  margin-left: 2rem !important; }

.m-4 {
  margin: 3rem !important; }

.mt-4,
.my-4 {
  margin-top: 3rem !important; }

.mr-4,
.mx-4 {
  margin-right: 3rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 3rem !important; }

.ml-4,
.mx-4 {
  margin-left: 3rem !important; }

.m-5 {
  margin: 6rem !important; }

.mt-5,
.my-5 {
  margin-top: 6rem !important; }

.mr-5,
.mx-5 {
  margin-right: 6rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 6rem !important; }

.ml-5,
.mx-5 {
  margin-left: 6rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.5rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.5rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.5rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.5rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.5rem !important; }

.p-2 {
  padding: 1rem !important; }

.pt-2,
.py-2 {
  padding-top: 1rem !important; }

.pr-2,
.px-2 {
  padding-right: 1rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 1rem !important; }

.pl-2,
.px-2 {
  padding-left: 1rem !important; }

.p-3 {
  padding: 2rem !important; }

.pt-3,
.py-3 {
  padding-top: 2rem !important; }

.pr-3,
.px-3 {
  padding-right: 2rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 2rem !important; }

.pl-3,
.px-3 {
  padding-left: 2rem !important; }

.p-4 {
  padding: 3rem !important; }

.pt-4,
.py-4 {
  padding-top: 3rem !important; }

.pr-4,
.px-4 {
  padding-right: 3rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 3rem !important; }

.pl-4,
.px-4 {
  padding-left: 3rem !important; }

.p-5 {
  padding: 6rem !important; }

.pt-5,
.py-5 {
  padding-top: 6rem !important; }

.pr-5,
.px-5 {
  padding-right: 6rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 6rem !important; }

.pl-5,
.px-5 {
  padding-left: 6rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.5rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.5rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.5rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.5rem !important; }
  .m-sm-2 {
    margin: 1rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 1rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 1rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 1rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 1rem !important; }
  .m-sm-3 {
    margin: 2rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 2rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 2rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 2rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 2rem !important; }
  .m-sm-4 {
    margin: 3rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 3rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 3rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 3rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 3rem !important; }
  .m-sm-5 {
    margin: 6rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 6rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 6rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 6rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 6rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.5rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.5rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.5rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.5rem !important; }
  .p-sm-2 {
    padding: 1rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 1rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 1rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 1rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 1rem !important; }
  .p-sm-3 {
    padding: 2rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 2rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 2rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 2rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 2rem !important; }
  .p-sm-4 {
    padding: 3rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 3rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 3rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 3rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 3rem !important; }
  .p-sm-5 {
    padding: 6rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 6rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 6rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 6rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 6rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.5rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.5rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.5rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.5rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.5rem !important; }
  .m-md-2 {
    margin: 1rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 1rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 1rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 1rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 1rem !important; }
  .m-md-3 {
    margin: 2rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 2rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 2rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 2rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 2rem !important; }
  .m-md-4 {
    margin: 3rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 3rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 3rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 3rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 3rem !important; }
  .m-md-5 {
    margin: 6rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 6rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 6rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 6rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 6rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.5rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.5rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.5rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.5rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.5rem !important; }
  .p-md-2 {
    padding: 1rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 1rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 1rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 1rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 1rem !important; }
  .p-md-3 {
    padding: 2rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 2rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 2rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 2rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 2rem !important; }
  .p-md-4 {
    padding: 3rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 3rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 3rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 3rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 3rem !important; }
  .p-md-5 {
    padding: 6rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 6rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 6rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 6rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 6rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.5rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.5rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.5rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.5rem !important; }
  .m-lg-2 {
    margin: 1rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 1rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 1rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 1rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 1rem !important; }
  .m-lg-3 {
    margin: 2rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 2rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 2rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 2rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 2rem !important; }
  .m-lg-4 {
    margin: 3rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 3rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 3rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 3rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 3rem !important; }
  .m-lg-5 {
    margin: 6rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 6rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 6rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 6rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 6rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.5rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.5rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.5rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.5rem !important; }
  .p-lg-2 {
    padding: 1rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 1rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 1rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 1rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 1rem !important; }
  .p-lg-3 {
    padding: 2rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 2rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 2rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 2rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 2rem !important; }
  .p-lg-4 {
    padding: 3rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 3rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 3rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 3rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 3rem !important; }
  .p-lg-5 {
    padding: 6rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 6rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 6rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 6rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 6rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.5rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.5rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.5rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.5rem !important; }
  .m-xl-2 {
    margin: 1rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 1rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 1rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 1rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 1rem !important; }
  .m-xl-3 {
    margin: 2rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 2rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 2rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 2rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 2rem !important; }
  .m-xl-4 {
    margin: 3rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 3rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 3rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 3rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 3rem !important; }
  .m-xl-5 {
    margin: 6rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 6rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 6rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 6rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 6rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.5rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.5rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.5rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.5rem !important; }
  .p-xl-2 {
    padding: 1rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 1rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 1rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 1rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 1rem !important; }
  .p-xl-3 {
    padding: 2rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 2rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 2rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 2rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 2rem !important; }
  .p-xl-4 {
    padding: 3rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 3rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 3rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 3rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 3rem !important; }
  .p-xl-5 {
    padding: 6rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 6rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 6rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 6rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 6rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #ff6f00 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #cc5900 !important; }

.text-secondary {
  color: #94a0b2 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #76869d !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #1e7e34 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #117a8b !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #d39e00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #bd2130 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #dae0e5 !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important; }

.text-marketing {
  color: #85c4c1 !important; }

a.text-marketing:hover, a.text-marketing:focus {
  color: #63b3b0 !important; }

.text-communication {
  color: #edae47 !important; }

a.text-communication:hover, a.text-communication:focus {
  color: #e89a19 !important; }

.text-sales {
  color: #ed6847 !important; }

a.text-sales:hover, a.text-sales:focus {
  color: #e84219 !important; }

.text-marketing-light {
  color: #daedec !important; }

a.text-marketing-light:hover, a.text-marketing-light:focus {
  color: #b8dcda !important; }

.text-communication-light {
  color: #fce8ce !important; }

a.text-communication-light:hover, a.text-communication-light:focus {
  color: #f9d19e !important; }

.text-sales-light {
  color: #f9cabf !important; }

a.text-sales-light:hover, a.text-sales-light:focus {
  color: #f5a390 !important; }

.text-muted {
  color: #6c757d !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important; } }

html {
  font-size: 10px; }

body {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column; }

a, a:hover, a:focus, a:active {
  text-decoration: none; }

h1, h2, h3, h4, h5, ul, ol, p {
  margin: 0; }

.site-content {
  flex-grow: 1; }

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh; }

.site-header {
  position: static; }
  .site-header, .site-header .header {
    z-index: 1020;
    height: 75px; }

.site-content {
  flex-grow: 1; }

.grid {
  display: flex;
  flex-wrap: wrap;
  margin: 15px -15px; }
  .grid__item {
    padding: 15px;
    width: 100%;
    display: flex; }
    .grid__item > * {
      width: 100%; }
    @media (min-width: 768px) {
      .grid__item {
        width: 50%; } }
    @media (min-width: 992px) {
      .grid__item {
        width: 33.3333%; } }
    @media (min-width: 1200px) {
      .grid__item {
        width: 25%; } }
    @media (min-width: 576px) {
      .grid--5cols .grid__item {
        width: 50%; } }
    @media (min-width: 768px) {
      .grid--5cols .grid__item {
        width: 33.3333%; } }
    @media (min-width: 992px) {
      .grid--5cols .grid__item {
        width: 25%; } }
    @media (min-width: 1200px) {
      .grid--5cols .grid__item {
        width: 20%; } }

span.twitter-typeahead {
  /*
    .input-group & {
        display: flex !important;
        align-items: center;
        position: relative;
        flex: 1 1 auto;
        width: 1%;

        .tt-menu, .tt-hint, .tt-input {
            width: 100%;
        }
    }*/ }
  span.twitter-typeahead .tt-menu {
    cursor: pointer; }

.site-footer {
  padding-top: 25px;
  padding-bottom: 20px;
  color: #fff;
  background: #000;
  text-align: center; }
  .site-footer__brand img {
    width: 138px; }
  .site-footer__copy {
    margin-top: 40px;
    opacity: .5;
    font-size: 1.2rem; }
  @media (min-width: 992px) {
    .site-footer {
      padding-bottom: 80px; }
      .site-footer .container {
        display: flex;
        justify-content: space-between; }
      .site-footer__copy {
        margin-top: 0;
        text-align: right; } }

.site-header {
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 1030;
  height: 75px;
  color: #bfc5d2;
  background: #000;
  text-align: center; }
  .site-header__menu-toggler, .site-header__search-toggler {
    width: 75px;
    height: 75px;
    position: relative;
    display: block; }
    .site-header__menu-toggler, .site-header__menu-toggler:hover, .site-header__menu-toggler:focus, .site-header__menu-toggler:active, .site-header__search-toggler, .site-header__search-toggler:hover, .site-header__search-toggler:focus, .site-header__search-toggler:active {
      color: #fff; }
    .site-header__menu-toggler:after, .site-header__search-toggler:after {
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      content: "ꨀ";
      display: inline-block;
      font-family: 'icons';
      font-style: normal;
      font-variant: normal;
      font-weight: normal;
      line-height: 1;
      speak: none;
      text-transform: none;
      position: relative;
      top: 2px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%); }
  .site-header__menu-toggler:after {
    content: "ꨠ"; }
  .site-header__search-toggler:after {
    content: "ꨕ"; }
  body.show-menu .site-header__menu-toggler:after, body.show-search .site-header__search-toggler:after {
    content: "ꨡ"; }
  .site-header__brand {
    font-size: 1.4rem;
    font-weight: bold;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 75px; }
    .site-header__brand, .site-header__brand:hover, .site-header__brand:focus, .site-header__brand:active {
      color: #bfc5d2; }
  .site-header__new-campaign, .site-header__data-visualization {
    width: 6rem;
    height: 6rem;
    background: #ff6f00;
    border-radius: 50%;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.18), 0 0 8px 0 rgba(0, 0, 0, 0.12); }
    .site-header__new-campaign, .site-header__new-campaign:hover, .site-header__new-campaign:focus, .site-header__new-campaign:active, .site-header__data-visualization, .site-header__data-visualization:hover, .site-header__data-visualization:focus, .site-header__data-visualization:active {
      color: #fff; }
    .site-header__new-campaign:after, .site-header__data-visualization:after {
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      content: "ꨀ";
      display: inline-block;
      font-family: 'icons';
      font-style: normal;
      font-variant: normal;
      font-weight: normal;
      line-height: 1;
      speak: none;
      text-transform: none;
      position: relative;
      top: 2px;
      width: 1.6rem;
      height: 1.6rem;
      font-size: 1.6rem;
      line-height: 1;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%); }
  .site-header__new-campaign:after {
    content: "ꨓ"; }
  .site-header__data-visualization:after {
    content: "쀄";
    width: 2.4rem;
    height: 2.4rem;
    font-size: 2.4rem;
    line-height: 1; }
  @media (max-width: 991.98px) {
    .site-header {
      display: flex;
      width: 100%; }
      .site-header .container {
        position: absolute; }
      .site-header__search-desktop, .site-header__user {
        display: none; }
      .site-header__new-campaign, .site-header__data-visualization {
        position: fixed;
        bottom: 10px;
        right: 20px; }
      .site-header__search-portable {
        top: 75px;
        right: 0;
        bottom: 0;
        left: 0;
        position: fixed;
        background: #f5f8fa;
        z-index: 1031;
        padding-top: 15px;
        display: none; }
        .site-header__search-portable .btn-group {
          width: 100%; }
          .site-header__search-portable .btn-group .btn {
            width: 50%; }
        body.show-search .site-header__search-portable {
          display: block; } }
  @media (min-width: 992px) {
    .site-header {
      height: 124px; }
      .site-header .container {
        display: flex;
        position: relative;
        height: 105px;
        align-items: center;
        justify-content: center; }
      .site-header__search-toggler {
        display: none; }
      .site-header__new-campaign, .site-header__data-visualization {
        position: absolute;
        top: 124px;
        right: 20px;
        width: 56px;
        height: 56px;
        transform: translateY(-50%); }
      .site-header__brand, .site-header__menu-toggler {
        position: absolute;
        top: 10px;
        z-index: 1; }
      .site-header__menu-toggler {
        left: 0; }
      .site-header__brand {
        left: 75px; }
      .site-header__search-portable {
        display: none; }
      .site-header__search-desktop {
        width: 505px;
        margin-left: -30px; } }
    @media (min-width: 992px) and (min-width: 1200px) {
      .site-header__search-desktop {
        width: 605px; } }
  @media (min-width: 992px) {
      .site-header__user {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0; } }

.user-menu .dropdown-toggle {
  display: flex;
  align-items: center;
  padding: 10px 0; }
  .user-menu .dropdown-toggle .dropdown-menu, .user-menu .dropdown-toggle span.twitter-typeahead .tt-menu, span.twitter-typeahead .user-menu .dropdown-toggle .tt-menu {
    z-index: 10000; }
  .user-menu .dropdown-toggle:after {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    content: "ꨣ";
    display: inline-block;
    font-family: 'icons';
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    speak: none;
    text-transform: none;
    position: relative;
    top: 2px;
    border: 0;
    width: 12px;
    height: 12px;
    width: 12px;
    height: 12px;
    font-size: 12px;
    line-height: 1;
    margin-left: 8px;
    color: #fff; }

.user-menu__name {
  text-align: right;
  color: #a9a9a9; }
  .user-menu__name b, .user-menu__name span {
    display: block; }
  .user-menu__name b {
    font-size: 1.4rem; }
  .user-menu__name span {
    font-size: 1.2rem; }

.user-menu__avatar {
  width: 40px;
  height: 40px;
  position: relative;
  margin-left: 8px;
  background: #eee;
  border-radius: 50%;
  color: #212121; }
  .user-menu__avatar .icon {
    width: 20px;
    height: 20px;
    font-size: 20px;
    line-height: 1; }
    .user-menu__avatar .icon, .user-menu__avatar .icon:before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%); }
  .user-menu__avatar:after {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    content: "쀃";
    display: inline-block;
    font-family: 'icons';
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    speak: none;
    text-transform: none;
    position: relative;
    top: 2px;
    width: 20px;
    height: 20px;
    font-size: 20px;
    line-height: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%); }

.site-login-header {
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 1030;
  padding-top: 22px;
  padding-bottom: 22px;
  color: #fff;
  background: #000;
  text-align: center; }
  .site-login-header__brand img {
    width: 138px; }

.site-menu {
  overflow: auto;
  top: 75px;
  right: 0;
  bottom: 0;
  left: 0;
  position: fixed;
  background: #f5f8fa;
  z-index: 1030; }
  .site-menu ul {
    padding-left: 0;
    padding-right: 0;
    list-style: none;
    margin: 0; }
  .site-menu a {
    position: relative;
    display: flex;
    padding: 0 38px;
    height: 66px;
    align-items: center;
    margin: 0 20px; }
    .site-menu a, .site-menu a:hover, .site-menu a:focus, .site-menu a:active {
      color: #212121; }
  .site-menu li + li a {
    border-top: solid 1px #9299a7; }
  .site-menu li:last-child a {
    border-bottom: solid 1px #9299a7; }
  .site-menu li.active a:before {
    content: '';
    display: block;
    content: '';
    border-left: 4px solid #ff6f00;
    position: absolute;
    left: -20px;
    top: 11px;
    bottom: 11px; }
  .site-menu__has-children > a, .site-menu__back-link > a {
    position: relative; }
    .site-menu__has-children > a:after, .site-menu__back-link > a:after {
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      content: "ꨀ";
      display: inline-block;
      font-family: 'icons';
      font-style: normal;
      font-variant: normal;
      font-weight: normal;
      line-height: 1;
      speak: none;
      text-transform: none;
      position: relative;
      top: 2px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 18px;
      height: 18px;
      font-size: 18px;
      line-height: 1; }
  .site-menu__has-children > a {
    position: relative; }
    .site-menu__has-children > a:after {
      content: "ꨥ";
      color: #9299a7;
      right: 0; }
  .site-menu__back-link {
    background: #d4dae3; }
    .site-menu__back-link > a {
      border: 0; }
      .site-menu__back-link > a:after {
        content: "ꨤ";
        color: #fff;
        left: 0; }
  @media (max-width: 991.98px) {
    .site-menu {
      display: none; }
      body.show-menu .site-menu {
        display: block; }
      .site-menu__submenu {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        position: absolute;
        background: #f5f8fa;
        height: auto !important;
        z-index: 1031; }
        .site-menu__submenu.collapsing {
          transition: none;
          max-height: none; }
        .site-menu__submenu.show {
          transition: none;
          display: block; }
        .site-menu__submenu.collapsing.show {
          display: block !important; }
      .site-menu__back-link {
        background: #d4dae3; }
        .site-menu__back-link > a {
          border: 0 !important; }
          .site-menu__back-link > a:after {
            color: #fff;
            left: 0; }
        .site-menu__back-link + li a {
          border-top: 0 !important; } }
  @media (min-width: 992px) {
    .site-menu {
      top: 124px;
      right: auto;
      width: 0;
      background: #fff;
      box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.18), 0 0 8px 0 rgba(0, 0, 0, 0.12);
      transition: left 0.2s, width 0.2s;
      transition-timing-function: ease-in-out; }
      .site-menu > ul {
        width: 308px;
        float: right; }
      .site-menu__back-link {
        display: none; }
      .site-menu__has-children > a {
        position: relative; }
        .site-menu__has-children > a:after {
          content: "ꨢ"; }
        .site-menu__has-children > a.collapsed:after {
          content: "ꨣ"; }
      body.show-menu .site-menu {
        left: 0;
        width: 308px; }
      .site-menu__submenu {
        padding-left: 20px;
        padding-right: 20px; }
        .site-menu__submenu ul {
          border-top: solid 1px #9299a7; }
        .site-menu__submenu li > a {
          height: 56px;
          border: 0 !important; } }

.site-login {
  /*min-height: 100vh;*/ }
  .site-login__welcome {
    padding: 20px 0; }
    .site-login__welcome-media {
      margin-bottom: 20px; }
    .site-login__welcome-title {
      font-size: 3.6rem;
      font-weight: bold;
      line-height: 1.78;
      color: #212121; }
    .site-login__welcome-body {
      font-family: font-shift;
      font-size: 2rem;
      line-height: 1.6;
      color: #9299a7; }
  .site-login__form {
    background: rgba(40, 40, 40, 0.8);
    padding: 20px 0; }
  @media (min-width: 992px) {
    .site-login {
      background-color: #f5f8fa; }
      .site-login__bg {
        top: 0;
        right: 0;
        bottom: 0;
        left: 50%;
        position: fixed;
        background: url("../../theme/img/login-bg.jpg") no-repeat left #ddd;
        background-size: cover;
        margin-left: 15px; }
      .site-login__container {
        overflow: hidden;
        border-radius: 1rem;
        background-color: #ffffff;
        box-shadow: 0 5px 10px 0 rgba(12, 0, 51, 0.1);
        background-color: #fff;
        margin: 70px 0 0; }
      .site-login__welcome {
        padding: 70px 70px 138px; }
        .site-login__welcome-media {
          margin-bottom: 80px; }
        .site-login__welcome-title {
          font-size: 3.6rem;
          font-weight: bold;
          line-height: 1.78;
          color: #212121; }
        .site-login__welcome-body {
          font-family: font-shift;
          font-size: 2rem;
          line-height: 1.6;
          color: #9299a7; }
      .site-login__form {
        padding: 0;
        display: flex;
        flex-direction: column;
        width: 100%; }
        .site-login__form form {
          padding: 20px 75px 20px 100px;
          flex-grow: 1;
          display: flex;
          justify-content: center;
          flex-direction: column; }
          .site-login__form form .btn {
            width: 140px; }
        .site-login__form .form-group {
          margin-bottom: 30px; }
        .site-login__form .form-control {
          padding: 0;
          border: 0;
          border-bottom: 1px solid #fff;
          background: none;
          color: #fff; }
          .site-login__form .form-control::placeholder {
            color: #fff; }
          .site-login__form .form-control:focus {
            box-shadow: none;
            border-color: #ff6f00; }
        .site-login__form-footer {
          display: flex;
          justify-content: center;
          background: #fff; }
          .site-login__form-footer a {
            padding: 1.8rem;
            font-size: 1.4rem; } }
  .site-login + .site-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0; }

.form-control-typeahead {
  position: relative;
  padding: 0;
  border: 0; }
  .form-control-typeahead .form-control {
    background: none;
    position: relative; }
  .form-control-typeahead .twitter-typeahead, .form-control-typeahead .twitter-typeahead .tt-menu {
    width: 100%; }

.form-control-choices .badge:not(.active), .form-control-choices .btn-badge:not(.active) {
  background-color: #d8dfe8;
  color: #212121;
  border-color: #d8dfe8; }
  .form-control-choices .badge:not(.active)[href]:hover, .form-control-choices .badge:not(.active)[href]:focus, .form-control-choices .btn-badge:not(.active)[href]:hover, .form-control-choices .btn-badge:not(.active)[href]:focus {
    text-decoration: none;
    color: #212121;
    background-color: #b8c5d5;
    border-color: #b8c5d5; }

@media (max-width: 991.98px) {
  .form-control-choices .badge, .form-control-choices .btn {
    width: 100%; }
  .form-control-choices .badge + .badge, .form-control-choices .btn + .btn {
    margin-top: 1rem; } }

@media (min-width: 992px) {
  .form-control-choices {
    display: flex;
    align-items: center;
    height: 4.3rem;
    border: 1px solid transparent; }
    .form-control-choices .badge + .badge, .form-control-choices .btn + .btn {
      margin-top: 0;
      margin-left: 1rem; } }

.form-control-datepicker {
  position: relative;
  padding: 0;
  border: 0;
  /*    padding: 0;
    border: 0;

    @include append-icon {

    }

    > .form-control {
        background: none;
        padding-right: 50px;
    }*/ }
  .form-control-datepicker:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    content: "ꨳ";
    display: inline-block;
    font-family: 'icons';
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    speak: none;
    text-transform: none;
    position: relative;
    top: 2px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 1.5rem;
    height: 1.5rem;
    font-size: 1.5rem;
    line-height: 1;
    right: 2rem; }
  .form-control-datepicker .form-control {
    background: none;
    position: relative;
    padding-right: 4.5rem; }

.daterangepicker {
  position: absolute;
  color: #212121;
  background-color: #fff;
  border-radius: 4px;
  width: 278px;
  padding: 4px;
  margin-top: 1px;
  top: 100px;
  left: 20px;
  /* Calendars */ }
  .daterangepicker:before, .daterangepicker:after {
    position: absolute;
    display: inline-block;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    content: ''; }
  .daterangepicker:before {
    top: -7px;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    border-bottom: 7px solid #ccc; }
  .daterangepicker:after {
    top: -6px;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    border-left: 6px solid transparent; }
  .daterangepicker.opensleft:before {
    right: 9px; }
  .daterangepicker.opensleft:after {
    right: 10px; }
  .daterangepicker.openscenter:before {
    left: 0;
    right: 0;
    width: 0;
    margin-left: auto;
    margin-right: auto; }
  .daterangepicker.openscenter:after {
    left: 0;
    right: 0;
    width: 0;
    margin-left: auto;
    margin-right: auto; }
  .daterangepicker.opensright:before {
    left: 9px; }
  .daterangepicker.opensright:after {
    left: 10px; }
  .daterangepicker.dropup {
    margin-top: -5px; }
    .daterangepicker.dropup:before {
      top: initial;
      bottom: -7px;
      border-bottom: initial;
      border-top: 7px solid #ccc; }
    .daterangepicker.dropup:after {
      top: initial;
      bottom: -6px;
      border-bottom: initial;
      border-top: 6px solid #fff; }
  .daterangepicker.dropdown-menu, span.twitter-typeahead .daterangepicker.tt-menu {
    max-width: none;
    z-index: 3001; }
  .daterangepicker.single .ranges, .daterangepicker.single .calendar {
    float: none; }
  .daterangepicker.show-calendar .calendar {
    display: block; }
  .daterangepicker .calendar {
    display: none;
    max-width: 270px;
    margin: 4px; }
    .daterangepicker .calendar.single .calendar-table {
      border: none; }
    .daterangepicker .calendar th, .daterangepicker .calendar td {
      white-space: nowrap;
      text-align: center;
      min-width: 32px; }
  .daterangepicker .calendar-table {
    border: 1px solid #fff;
    padding: 4px;
    border-radius: 4px;
    background-color: #fff; }
  .daterangepicker table {
    width: 100%;
    margin: 0; }
  .daterangepicker td, .daterangepicker th {
    text-align: center;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: 1px solid transparent;
    white-space: nowrap;
    cursor: pointer; }
    .daterangepicker td.available:hover, .daterangepicker th.available:hover {
      background-color: #eee;
      border-color: transparent;
      color: #212121; }
    .daterangepicker td.week, .daterangepicker th.week {
      font-size: 80%;
      color: #ccc; }
  .daterangepicker td.off, .daterangepicker td.off.in-range, .daterangepicker td.off.start-date, .daterangepicker td.off.end-date {
    background-color: #fff;
    border-color: transparent;
    color: #999; }
  .daterangepicker td.in-range {
    background-color: #ffe2cc;
    border-color: transparent;
    color: #000;
    border-radius: 0; }
  .daterangepicker td.start-date {
    border-radius: 4px 0 0 4px; }
  .daterangepicker td.end-date {
    border-radius: 0 4px 4px 0; }
  .daterangepicker td.start-date.end-date {
    border-radius: 4px; }
  .daterangepicker td.active, .daterangepicker td.active:hover {
    background-color: #ff6f00;
    border-color: transparent;
    color: #fff; }
  .daterangepicker th.month {
    width: auto; }
  .daterangepicker td.disabled, .daterangepicker option.disabled {
    color: #999;
    cursor: not-allowed;
    text-decoration: line-through; }
  .daterangepicker select.monthselect, .daterangepicker select.yearselect {
    font-size: 12px;
    padding: 1px;
    height: auto;
    margin: 0;
    cursor: default; }
  .daterangepicker select.monthselect {
    margin-right: 2%;
    width: 56%; }
  .daterangepicker select.yearselect {
    width: 40%; }
  .daterangepicker select.hourselect, .daterangepicker select.minuteselect, .daterangepicker select.secondselect, .daterangepicker select.ampmselect {
    width: 50px;
    margin-bottom: 0; }
  .daterangepicker .input-mini {
    border: 1px solid #ccc;
    border-radius: 4px;
    color: #555;
    height: 35px;
    line-height: 30px;
    display: block;
    vertical-align: middle;
    margin: 0 0 5px 0;
    padding: 0 6px 0 28px;
    width: 100%; }
    .daterangepicker .input-mini.active {
      /*border: $daterangepicker-control-active-border-size solid $daterangepicker-control-active-border-color;*/
      /*border-radius: $daterangepicker-control-active-border-radius;*/ }
  .daterangepicker .daterangepicker_input {
    position: relative; }
    .daterangepicker .daterangepicker_input i {
      position: absolute;
      left: 8px;
      top: 8px; }
  .daterangepicker.rtl .input-mini {
    padding-right: 28px;
    padding-left: 6px; }
  .daterangepicker.rtl .daterangepicker_input i {
    left: auto;
    right: 8px; }
  .daterangepicker .calendar-time {
    text-align: center;
    margin: 5px auto;
    line-height: 30px;
    position: relative;
    padding-left: 28px; }
    .daterangepicker .calendar-time select.disabled {
      color: #ccc;
      cursor: not-allowed; }
  .daterangepicker .btn-success {
    color: #fff;
    background-color: #ff6f00;
    border-color: #ff6f00; }
    .daterangepicker .btn-success:hover {
      color: #fff;
      background-color: #000;
      border-color: #000; }
    .daterangepicker .btn-success:focus, .daterangepicker .btn-success.focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 111, 0, 0.5); }
    .daterangepicker .btn-success.disabled, .daterangepicker .btn-success:disabled {
      color: #fff;
      background-color: #ff6f00;
      border-color: #ff6f00; }
    .daterangepicker .btn-success:not(:disabled):not(.disabled):active, .daterangepicker .btn-success:not(:disabled):not(.disabled).active,
    .show > .daterangepicker .btn-success.dropdown-toggle {
      color: #fff;
      background-color: #cc5900;
      border-color: #cc5900; }
      .daterangepicker .btn-success:not(:disabled):not(.disabled):active:focus, .daterangepicker .btn-success:not(:disabled):not(.disabled).active:focus,
      .show > .daterangepicker .btn-success.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(255, 111, 0, 0.5); }
  .daterangepicker .btn-default {
    color: #fff;
    background-color: #94a0b2;
    border-color: #94a0b2; }
    .daterangepicker .btn-default:hover {
      color: #fff;
      background-color: #000;
      border-color: #000; }
    .daterangepicker .btn-default:focus, .daterangepicker .btn-default.focus {
      box-shadow: 0 0 0 0.2rem rgba(148, 160, 178, 0.5); }
    .daterangepicker .btn-default.disabled, .daterangepicker .btn-default:disabled {
      color: #fff;
      background-color: #94a0b2;
      border-color: #94a0b2; }
    .daterangepicker .btn-default:not(:disabled):not(.disabled):active, .daterangepicker .btn-default:not(:disabled):not(.disabled).active,
    .show > .daterangepicker .btn-default.dropdown-toggle {
      color: #fff;
      background-color: #76869d;
      border-color: #76869d; }
      .daterangepicker .btn-default:not(:disabled):not(.disabled):active:focus, .daterangepicker .btn-default:not(:disabled):not(.disabled).active:focus,
      .show > .daterangepicker .btn-default.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(148, 160, 178, 0.5); }

.ranges {
  font-size: 11px;
  float: none;
  margin: 4px;
  text-align: left;
  clear: both; }
  .ranges ul {
    list-style: none;
    margin: 0 auto;
    padding: 0;
    width: 100%; }
  .ranges li {
    font-size: 13px;
    background-color: #f5f5f5;
    border: 1px solid #f5f5f5;
    border-radius: 4px;
    color: #08c;
    padding: 3px 12px;
    margin-bottom: 8px;
    cursor: pointer; }
    .ranges li:hover {
      background-color: #08c;
      border: 1px solid #08c;
      color: #fff; }
    .ranges li.active {
      background-color: #08c;
      border: 1px solid #08c;
      color: #fff; }

/*  Larger Screen Styling */
@media (min-width: 564px) {
  .daterangepicker {
    width: auto;
    max-width: 498px; }
    .daterangepicker .ranges ul {
      width: 160px; }
    .daterangepicker.single .ranges ul {
      width: 100%; }
    .daterangepicker.single .calendar.left {
      clear: none; }
    .daterangepicker.single.ltr .ranges, .daterangepicker.single.ltr .calendar {
      float: left; }
    .daterangepicker.single.rtl .ranges, .daterangepicker.single.rtl .calendar {
      float: right; }
    .daterangepicker.ltr {
      direction: ltr;
      text-align: left; }
      .daterangepicker.ltr .calendar.left {
        clear: left;
        margin-right: 0; }
        .daterangepicker.ltr .calendar.left .calendar-table {
          border-right: none;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
      .daterangepicker.ltr .calendar.right {
        margin-left: 0; }
        .daterangepicker.ltr .calendar.right .calendar-table {
          border-left: none;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
      .daterangepicker.ltr .left .daterangepicker_input {
        padding-right: 12px; }
      .daterangepicker.ltr .calendar.left .calendar-table {
        padding-right: 12px; }
      .daterangepicker.ltr .ranges, .daterangepicker.ltr .calendar {
        float: left; }
    .daterangepicker.rtl {
      direction: rtl;
      text-align: right; }
      .daterangepicker.rtl .calendar.left {
        clear: right;
        margin-left: 0; }
        .daterangepicker.rtl .calendar.left .calendar-table {
          border-left: none;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
      .daterangepicker.rtl .calendar.right {
        margin-right: 0; }
        .daterangepicker.rtl .calendar.right .calendar-table {
          border-right: none;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
      .daterangepicker.rtl .left .daterangepicker_input {
        padding-left: 12px; }
      .daterangepicker.rtl .calendar.left .calendar-table {
        padding-left: 12px; }
      .daterangepicker.rtl .ranges, .daterangepicker.rtl .calendar {
        text-align: right;
        float: right; } }

@media (min-width: 730px) {
  .daterangepicker .ranges {
    width: auto; }
  .daterangepicker.ltr .ranges {
    float: left; }
  .daterangepicker.rtl .ranges {
    float: right; }
  .daterangepicker .calendar.left {
    clear: none !important; } }

.form-control-search {
  position: relative;
  padding: 0;
  border: 0; }
  .form-control-search:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    content: "ꨕ";
    display: inline-block;
    font-family: 'icons';
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    speak: none;
    text-transform: none;
    position: relative;
    top: 2px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 1.5rem;
    height: 1.5rem;
    font-size: 1.5rem;
    line-height: 1;
    right: 2rem; }
  .form-control-search .form-control {
    background: none;
    position: relative;
    padding-right: 4.5rem; }
  .form-control-search:before {
    color: #ff6f00; }

.form-control-concept-search .dropdown-menu, .form-control-concept-search span.twitter-typeahead .tt-menu, span.twitter-typeahead .form-control-concept-search .tt-menu {
  border-radius: 0;
  min-width: 100%;
  padding: 10px 0; }
  .form-control-concept-search .dropdown-menu label, .form-control-concept-search span.twitter-typeahead .tt-menu label, span.twitter-typeahead .form-control-concept-search .tt-menu label {
    margin: 0;
    cursor: pointer; }

.form-control-concept-search .dropdown-item:active, .form-control-concept-search span.twitter-typeahead .tt-suggestion:active, span.twitter-typeahead .form-control-concept-search .tt-suggestion:active {
  background: none;
  color: #212121; }

.form-control-concept-search .input-group-prepend {
  position: relative; }
  .form-control-concept-search .input-group-prepend .btn {
    border-radius: 4px 0 0 4px;
    min-width: 130px; }
    .form-control-concept-search .input-group-prepend .btn, .form-control-concept-search .input-group-prepend .btn:hover, .form-control-concept-search .input-group-prepend .btn:focus, .form-control-concept-search .input-group-prepend .btn:active {
      background: #94a0b2;
      color: #212121; }
    .form-control-concept-search .input-group-prepend .btn:after {
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      content: "ꨣ";
      display: inline-block;
      font-family: 'icons';
      font-style: normal;
      font-variant: normal;
      font-weight: normal;
      line-height: 1;
      speak: none;
      text-transform: none;
      position: relative;
      top: 2px;
      width: 12px;
      height: 12px;
      font-size: 12px;
      line-height: 1;
      color: #fff;
      border: 0;
      margin-left: 6px; }
  .form-control-concept-search .input-group-prepend .dropdown-item.active, .form-control-concept-search .input-group-prepend span.twitter-typeahead .active.tt-suggestion, span.twitter-typeahead .form-control-concept-search .input-group-prepend .active.tt-suggestion, .form-control-concept-search .input-group-prepend span.twitter-typeahead .tt-suggestion.tt-cursor, span.twitter-typeahead .form-control-concept-search .input-group-prepend .tt-suggestion.tt-cursor {
    display: none !important; }
  .form-control-concept-search .input-group-prepend .dropdown-item input, .form-control-concept-search .input-group-prepend span.twitter-typeahead .tt-suggestion input, span.twitter-typeahead .form-control-concept-search .input-group-prepend .tt-suggestion input {
    display: none; }

.form-control-concept-search .form-control-typeahead .dropdown-menu, .form-control-concept-search .form-control-typeahead span.twitter-typeahead .tt-menu, span.twitter-typeahead .form-control-concept-search .form-control-typeahead .tt-menu {
  padding: 10px 0;
  overflow: auto;
  overflow-x: hidden;
  max-height: 366px; }
  .form-control-concept-search .form-control-typeahead .dropdown-menu .dropdown-item, .form-control-concept-search .form-control-typeahead span.twitter-typeahead .tt-menu .dropdown-item, span.twitter-typeahead .form-control-concept-search .form-control-typeahead .tt-menu .dropdown-item, .form-control-concept-search .form-control-typeahead .dropdown-menu span.twitter-typeahead .tt-suggestion, span.twitter-typeahead .form-control-concept-search .form-control-typeahead .dropdown-menu .tt-suggestion, .form-control-concept-search .form-control-typeahead span.twitter-typeahead .tt-menu .tt-suggestion, span.twitter-typeahead .form-control-concept-search .form-control-typeahead .tt-menu .tt-suggestion {
    padding: 0; }
  .form-control-concept-search .form-control-typeahead .dropdown-menu a.dropdown-item, .form-control-concept-search .form-control-typeahead span.twitter-typeahead .tt-menu a.dropdown-item, span.twitter-typeahead .form-control-concept-search .form-control-typeahead .tt-menu a.dropdown-item, .form-control-concept-search .form-control-typeahead .dropdown-menu span.twitter-typeahead a.tt-suggestion, span.twitter-typeahead .form-control-concept-search .form-control-typeahead .dropdown-menu a.tt-suggestion, .form-control-concept-search .form-control-typeahead span.twitter-typeahead .tt-menu a.tt-suggestion, span.twitter-typeahead .form-control-concept-search .form-control-typeahead .tt-menu a.tt-suggestion, .form-control-concept-search .form-control-typeahead .dropdown-menu label, .form-control-concept-search .form-control-typeahead span.twitter-typeahead .tt-menu label, span.twitter-typeahead .form-control-concept-search .form-control-typeahead .tt-menu label, .form-control-concept-search .form-control-typeahead .dropdown-menu .tt-suggestion, .form-control-concept-search .form-control-typeahead span.twitter-typeahead .tt-menu .tt-suggestion, span.twitter-typeahead .form-control-concept-search .form-control-typeahead .tt-menu .tt-suggestion {
    padding: .5rem 1.5rem; }
  .form-control-concept-search .form-control-typeahead .dropdown-menu label, .form-control-concept-search .form-control-typeahead span.twitter-typeahead .tt-menu label, span.twitter-typeahead .form-control-concept-search .form-control-typeahead .tt-menu label {
    padding-left: 4rem; }
  .form-control-concept-search .form-control-typeahead .dropdown-menu .custom-control-label:before, .form-control-concept-search .form-control-typeahead span.twitter-typeahead .tt-menu .custom-control-label:before, span.twitter-typeahead .form-control-concept-search .form-control-typeahead .tt-menu .custom-control-label:before, .form-control-concept-search .form-control-typeahead .dropdown-menu .custom-control-label:after, .form-control-concept-search .form-control-typeahead span.twitter-typeahead .tt-menu .custom-control-label:after, span.twitter-typeahead .form-control-concept-search .form-control-typeahead .tt-menu .custom-control-label:after {
    top: .55rem;
    left: 1.5rem; }

.form-control-concept-search .form-control-typeahead .form-control {
  border-radius: 0; }

.form-control-concept-search .input-group-append, .form-control-concept-search-xs .input-group-append {
  position: relative; }
  .form-control-concept-search .input-group-append .btn, .form-control-concept-search-xs .input-group-append .btn {
    border-radius: 0 4px 4px 0;
    border: 1px solid #ced4da;
    background-color: #fff;
    border-left: 0;
    padding-left: 1rem;
    padding-right: 1rem;
    color: #ff6f00; }

.form-control-tagsinput {
  /*padding: 15px;*/ }

.bootstrap-tagsinput input {
  border: none;
  box-shadow: none;
  outline: none;
  background-color: transparent;
  padding: 0 6px;
  margin: 0;
  width: auto;
  max-width: inherit; }
  .bootstrap-tagsinput input:focus {
    border: none;
    box-shadow: none; }

.bootstrap-tagsinput.form-control input::-moz-placeholder {
  color: #777;
  opacity: 1; }

.bootstrap-tagsinput.form-control input:-ms-input-placeholder {
  color: #777; }

.bootstrap-tagsinput.form-control input::-webkit-input-placeholder {
  color: #777; }

.bootstrap-tagsinput .tag {
  display: inline-block;
  padding: .6rem 1.5rem .4rem;
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  text-transform: uppercase;
  color: #fff;
  border-radius: 15px;
  transition: background 0.1s, border-color 0.1s, color 0.1s;
  border-width: 1px;
  border-style: solid;
  background-color: #d8dfe8;
  color: #212121;
  border-color: #d8dfe8;
  margin-right: 2px; }
  .bootstrap-tagsinput .tag:empty {
    display: none; }
  .bootstrap-tagsinput .tag[href]:hover, .bootstrap-tagsinput .tag[href]:focus {
    text-decoration: none;
    color: #212121;
    background-color: #b8c5d5;
    border-color: #b8c5d5; }
  .bootstrap-tagsinput .tag [data-role="remove"] {
    margin-left: 8px;
    cursor: pointer; }
    .bootstrap-tagsinput .tag [data-role="remove"]:after {
      content: "x";
      padding: 0px 2px; }
    .bootstrap-tagsinput .tag [data-role="remove"]:hover {
      box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05); }
    .bootstrap-tagsinput .tag [data-role="remove"]:hover:active {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }

.form-control-icon-choice {
  display: flex;
  align-items: center;
  height: 4.3rem;
  border: 1px solid transparent; }
  .form-control-icon-choice input {
    display: none; }
  .form-control-icon-choice .icon {
    width: 18px;
    height: 18px;
    font-size: 18px;
    line-height: 1;
    width: 30px;
    height: 30px;
    position: relative;
    opacity: 0.37;
    background: #9299a7;
    color: #fff;
    transition: opacity .1s;
    cursor: pointer;
    border-radius: 50%; }
    .form-control-icon-choice .icon:before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%); }
  .form-control-icon-choice input:checked + .icon {
    opacity: 1; }
  .form-control-icon-choice label {
    width: 30px;
    height: 30px;
    margin: 0; }
  .form-control-icon-choice label + label {
    margin-left: 1rem; }

.books {
  max-width: 166px;
  height: 139px;
  position: relative;
  /*background: #fdd;*/ }
  .books img {
    width: 90px;
    height: 139px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 2; }
    .books img:first-child, .books img:last-child {
      width: 72px;
      height: 111px;
      z-index: 1; }
    .books img:first-child {
      left: 36px; }
    .books img:last-child {
      left: auto;
      right: -36px; }
    .books img:first-child:last-child {
      width: 90px;
      height: 139px;
      left: 50%;
      right: auto; }

.add-widget {
  overflow: hidden;
  border-radius: 1rem;
  background-color: #ffffff;
  box-shadow: 0 5px 10px 0 rgba(12, 0, 51, 0.1);
  display: block;
  position: relative;
  height: 200px;
  border: 2px dashed #cbd0d8; }
  .add-widget .icon {
    width: 29px;
    height: 29px;
    font-size: 29px;
    line-height: 1;
    width: 89px;
    height: 89px;
    background: #d4dae3;
    border-radius: 50%;
    color: #fff; }
    .add-widget .icon, .add-widget .icon:before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%); }
  @media (min-width: 992px) {
    .add-widget {
      height: 256px; } }

.book-data {
  width: 100%;
  padding: 35px 15px;
  text-align: center; }
  .book-data__info {
    margin-top: 20px;
    font-family: "Shift", sans-serif;
    font-size: 1.4rem;
    color: #ffc000; }

.chart-book {
  display: flex;
  width: 280px;
  border-left: 3px solid #d8dfe8;
  max-width: 80vw; }
  .chart-book__media {
    /*width: 97px;*/
    padding-left: 11px; }
    .chart-book__media img {
      width: 66px; }
  .chart-book__title {
    line-height: 1.25; }
  .chart-book__body {
    margin-top: 10px;
    margin-bottom: 20px;
    font-family: font-shift;
    font-size: 1.4rem;
    line-height: 1.14;
    color: #ffc000; }
  .chart-book__content {
    padding: 0 20px; }
    .chart-book__content > a {
      font-size: 1.4rem; }
      .chart-book__content > a, .chart-book__content > a:hover, .chart-book__content > a:focus, .chart-book__content > a:active {
        color: #212121; }
  .chart-book.chart-book--accent1 {
    border-color: #5b7bdf; }
  .chart-book.chart-book--accent2 {
    border-color: #e9775f; }
  .chart-book.chart-book--accent3 {
    border-color: #438da0; }
  .chart-book.chart-book--accent4 {
    border-color: #de5562; }
  .chart-book.chart-book--accent5 {
    border-color: #fab06e; }
  .chart-book.chart-book--accent6 {
    border-color: #66d09f; }
  .chart-book.chart-book--accent7 {
    border-color: #a14848; }
  .chart-book.chart-book--accent8 {
    border-color: #ecd260; }
  .chart-book.chart-book--accent9 {
    border-color: #b4e1aa; }
  .chart-book.chart-book--accent10 {
    border-color: #5c86bd; }
  .chart-book.chart-book--accent11 {
    border-color: #e18d90; }
  .chart-book.chart-book--accent12 {
    border-color: #939cf7; }
  .chart-book.chart-book--accent13 {
    border-color: #a2c0f5; }
  .chart-book.chart-book--accent14 {
    border-color: #9299a7; }

.chart-books {
  display: flex;
  overflow: auto; }

.resource-link {
  display: flex; }
  .resource-link .media-body {
    display: flex;
    align-items: center;
    height: 100%; }
  .resource-link a {
    font-size: 1.4rem; }
    .resource-link a, .resource-link a:hover, .resource-link a:focus, .resource-link a:active {
      color: #212121; }
  .resource-link__name a, .resource-link__name a:hover, .resource-link__name a:focus, .resource-link__name a:active {
    text-decoration: underline; }
  .resource-link__download {
    margin-left: 1rem; }
    .resource-link__download .icon {
      width: 2rem;
      height: 2rem;
      font-size: 2rem;
      line-height: 1; }

.calendar {
  overflow: hidden;
  border-radius: 1rem;
  background-color: #ffffff;
  box-shadow: 0 5px 10px 0 rgba(12, 0, 51, 0.1); }
  .calendar__head {
    background: #9299a7;
    padding: 10px 15px;
    color: #fff; }
    .calendar__head a, .calendar__head a:hover, .calendar__head a:focus, .calendar__head a:active {
      color: #fff; }
    .calendar__head .dropdown-menu a, .calendar__head span.twitter-typeahead .tt-menu a, span.twitter-typeahead .calendar__head .tt-menu a, .calendar__head .dropdown-menu a:hover, .calendar__head span.twitter-typeahead .tt-menu a:hover, span.twitter-typeahead .calendar__head .tt-menu a:hover, .calendar__head .dropdown-menu a:focus, .calendar__head span.twitter-typeahead .tt-menu a:focus, span.twitter-typeahead .calendar__head .tt-menu a:focus, .calendar__head .dropdown-menu a:active, .calendar__head span.twitter-typeahead .tt-menu a:active, span.twitter-typeahead .calendar__head .tt-menu a:active {
      color: #212121; }
    .calendar__head-navigation {
      display: flex;
      height: 50px;
      align-items: center; }
      .calendar__head-navigation > span {
        flex-grow: 1;
        text-align: center;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        font-size: 2rem;
        font-weight: bold; }
    @media (max-width: 991.98px) {
      .calendar__head .btn-link {
        display: block; }
      .calendar__head .dropdown-menu, .calendar__head span.twitter-typeahead .tt-menu, span.twitter-typeahead .calendar__head .tt-menu {
        width: 100%; }
      .calendar__head .dropdown-item, .calendar__head span.twitter-typeahead .tt-suggestion, span.twitter-typeahead .calendar__head .tt-suggestion {
        text-align: center; } }
    @media (min-width: 992px) {
      .calendar__head {
        display: flex;
        width: 100%;
        padding: 30px 40px 10px;
        align-items: center; }
        .calendar__head > div {
          flex-grow: 2;
          width: 40%; }
        .calendar__head-month {
          width: 20%;
          flex-grow: 1;
          display: flex;
          justify-content: center; } }
  .calendar__intro {
    background: #d4dae3;
    color: #9299a7; }
    .calendar__intro-day {
      padding: 10px 15px; }
  .calendar-number {
    font-size: 3.6rem; }
  .calendar-event {
    height: 40px;
    font-size: 1.2rem;
    background: #fdd;
    display: flex;
    align-items: flex-start;
    padding: 2px 10px 0;
    color: #212121;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    cursor: pointer;
    transition: background .1s, color .1s; }
    .calendar-event.calendar-event--marketing {
      background: #daedec; }
    .calendar-event.calendar-event--communication {
      background: #fce8ce; }
    .calendar-event.calendar-event--sales {
      background: #f9cabf; }
    .calendar-event.calendar-event--marketing {
      border-left: 4px solid #85c4c1;
      /*&:hover, &.hover {*/ }
      .calendar-event.calendar-event--marketing.hover {
        background: #b9dddb; }
    .calendar-event.calendar-event--communication {
      border-left: 4px solid #edae47;
      /*&:hover, &.hover {*/ }
      .calendar-event.calendar-event--communication.hover {
        background: #f4cd8d; }
    .calendar-event.calendar-event--sales {
      border-left: 4px solid #ed6847;
      /*&:hover, &.hover {*/ }
      .calendar-event.calendar-event--sales.hover {
        background: #f4a18d; }
    .calendar-event.calendar-event--half {
      border-color: transparent; }
  .calendar__cell {
    padding: 10px 0; }
    .calendar__body .calendar__cell {
      padding: 0; }
  .calendar--month table th {
    width: 14.28571%;
    text-align: right; }
    .calendar--month table th.calendar__cell {
      padding: 35px 0 15px;
      text-align: center; }
  @media (min-width: 992px) {
    .calendar {
      /*        &-event {
                    max-width: 166px;
                }*/ }
      .calendar__intro-day {
        padding: 20px 40px 5px; }
      .calendar__body {
        display: flex;
        flex-wrap: wrap; }
      .calendar__cell {
        padding: 10px; }
        .calendar__cell .calendar-event {
          max-width: none;
          width: 100%; } }

.calendar-table {
  width: 100%; }
  .calendar-table__head table, .calendar-table__body table {
    width: 100%; }
  .calendar-table__head td, .calendar-table__body td {
    width: 14.28571%; }
  .calendar-table__head {
    background: #d4dae3;
    color: #9299a7; }
    .calendar-table__head td {
      width: 14.28571%;
      text-align: right;
      padding: 10px;
      text-align: center;
      padding-top: 32px; }
  .calendar-table__row {
    position: relative; }
  .calendar-table__days {
    position: absolute;
    top: 0;
    height: 100%; }
    .calendar-table__days td {
      vertical-align: top;
      text-align: right;
      padding: 0;
      padding-top: 3px;
      padding-right: 14px;
      color: #8a888a;
      font-size: 1.4rem; }
      .calendar-table__days td.disabled {
        color: #fff;
        background: #d9d9d9; }
  .calendar-table__events {
    position: relative;
    z-index: 1; }
  .calendar-table__event {
    height: 48px;
    padding: 4px 0;
    padding-right: 1px;
    vertical-align: top; }
    .calendar-table__event .calendar-event {
      max-width: none;
      width: 100%; }
  .calendar-table__actions {
    text-align: right;
    padding: 4px 8px; }
    .calendar-table__actions button {
      font-size: 3rem;
      color: #cbcbcb;
      line-height: .5;
      padding: 0 5px;
      background: none;
      border: 0;
      cursor: pointer; }
    .calendar-table__actions span {
      display: inline-block;
      transform: translateY(-8px); }
  .calendar-table__actions-tooltip {
    padding: 20px;
    font-size: 1.4rem; }
    .calendar-table__actions-tooltip__info {
      text-align: left; }
    .calendar-table__actions-tooltip__detail {
      margin-top: 30px;
      text-align: right; }
      .calendar-table__actions-tooltip__detail a {
        text-decoration: underline; }
  .calendar--month .calendar-table__head td + td, .calendar--week .calendar-table__head td + td {
    border-left: 1px solid #c8c8c8; }
  .calendar--month .calendar-table__row + .calendar--month .calendar-table__row, .calendar--week .calendar-table__row + .calendar--month .calendar-table__row, .calendar--month .calendar-table__row + .calendar--week .calendar-table__row, .calendar--week .calendar-table__row + .calendar--week .calendar-table__row {
    border-top: 1px solid #efefef; }
  .calendar--month .calendar-table__days td + td, .calendar--week .calendar-table__days td + td {
    border-left: 1px solid #efefef; }
  .calendar--month .calendar-table__row {
    padding-top: 36px; }

.ct {
  width: 100%;
  height: 100%;
  /*    &-content {
            @include absolute();
        }*/
  /*    &-bg {
            height: 100%;
        }*/
  /*    &-day {
            height: 36px;
            vertical-align: middle;
            text-align: right;
            padding: 0;
            padding-right: 14px;
            color: $table-day;
            font-size: 1.4rem;

            &--disabled {
                background: $table-day-disabled;
                color: #fff;
                font-weight: 400;
                padding-top: 40px;
            }
        }*/ }
  .ct .ct, .ct .ct td {
    border: 0; }
  .ct-head {
    background: #d4dae3;
    color: #9299a7; }
    .ct-head th {
      width: 14.28571%;
      text-align: right; }
      .ct-head th span {
        font-weight: 400; }
      .ct-head th.calendar__cell {
        padding: 10px; }
      .ct-head th + th {
        border-left: 1px solid #9299a7; }
  .ct-body {
    /*        > tr + tr {
                    border-top: $table-border;
                }
                > tr > td {
                    padding: 0;
                    border-top: 0;
                }

                .ct {
                    position: relative;

                    td {
                        width: $table-day-width;
                    }

                    .ct-day {
                        & + .ct-day:before {
                            content: '';
                            display: block;
                            border-left: $table-border;
                            position: absolute;
                            top: 0;
                            bottom: 0;
                        }
                    }
                }*/ }
    .ct-body .ct {
      width: 100%; }
      .ct-body .ct td {
        width: 14.28571%; }
    .ct-body .ct-container {
      position: relative; }
    .ct-body > tr + tr {
      border-top: 1px solid #efefef; }
    .ct-body .ct-days {
      position: absolute;
      /*display: none;*/ }
      .ct-body .ct-days td + td {
        border-left: 1px solid #efefef; }
      .ct-body .ct-days td {
        height: 36px;
        vertical-align: top;
        text-align: right;
        padding: 0;
        padding-top: 3px;
        padding-right: 14px;
        color: #8a888a;
        font-size: 1.4rem; }
    .ct-body .ct-events {
      position: relative;
      z-index: 1;
      /*position: absolute;*/
      /*display: none;*/ }
  .ct-row {
    position: relative;
    /*width: 100%;*/
    /*height: 200px;*/
    /*        &--week {
                    height: 682px;

                    thead {
                        height: 10px;
                    }

                    .ct-day-top {
                        height: 0;
                    }
                }*/ }
  .ct-event {
    padding: 4px 0;
    vertical-align: top; }
    .ct-event .calendar-event {
      max-width: none;
      width: 100%; }
  .ct .ct-actions {
    height: 20px;
    text-align: right;
    vertical-align: middle; }

#modal--add-widget .media-body p {
  font-family: font-shift;
  font-size: 1.4rem;
  line-height: 1.14;
  color: #ffc000; }

#modal--add-widget .media-body > a {
  font-size: 1.4rem; }
  #modal--add-widget .media-body > a, #modal--add-widget .media-body > a:hover, #modal--add-widget .media-body > a:focus, #modal--add-widget .media-body > a:active {
    color: #212121; }

.modal--visualization {
  /*    &.open {
            .modal-dialog {
                max-width: 946px;
            }

            .modal__items-selected {
                display: block;
            }
        }*/ }
  .modal--visualization .close {
    position: absolute;
    right: 15px;
    top: 15px; }
  .modal--visualization .modal__selected,
  .modal--visualization .modal__items--book-search,
  .modal--visualization .modal__items--book-format {
    display: none; }
  .modal--visualization--book-search .modal__items--book-search,
  .modal--visualization--book-format .modal__items--book-format {
    display: block; }
  .modal--visualization .modal-body {
    display: flex;
    padding: 40px 0 0; }
  .modal--visualization .modal__items {
    padding: 20px; }
  .modal--visualization .book-search__item img {
    width: 60px; }
  .modal--visualization .book-search__item p {
    color: #ffc000;
    font-family: "Shift", sans-serif;
    font-size: 1.4rem; }
  .modal--visualization .book-search__item a {
    padding-left: 0;
    font-size: 1.4rem; }
  .modal--visualization .book-search__item .media-body {
    position: relative; }
  .modal--visualization .book-format__media {
    display: none; }
  @media (min-width: 992px) {
    .modal--visualization .modal-dialog {
      width: 780px;
      max-width: none; }
    .modal--visualization .modal-body {
      padding: 10px 0 0; }
    .modal--visualization .modal__items {
      padding: 40px; }
    .modal--visualization .book-format {
      display: flex; }
      .modal--visualization .book-format__media {
        display: block;
        padding-right: 40px; }
        .modal--visualization .book-format__media img {
          width: 94px; }
    .modal--visualization.open .modal-dialog {
      width: 946px; }
    .modal--visualization.open .modal__selected {
      display: block; }
    .modal--visualization .modal__items {
      width: 780px; }
    .modal--visualization .modal__selected {
      display: none;
      width: 280px;
      padding-top: 60px;
      padding-bottom: 30px;
      padding-left: 38px;
      padding-right: 38px;
      border-left: solid 1px #979797; }
      .modal--visualization .modal__selected .modal-scroll {
        max-height: 424px; }
      .modal--visualization .modal__selected .media img {
        width: 60px; }
      .modal--visualization .modal__selected .media p {
        color: #ffc000;
        font-family: "Shift", sans-serif;
        font-size: 1.4rem; }
      .modal--visualization .modal__selected .media a {
        padding-left: 0;
        font-size: 1.4rem; }
      .modal--visualization .modal__selected .media .media-body {
        position: relative; }
        .modal--visualization .modal__selected .media .media-body .close {
          position: absolute;
          right: -15px;
          top: 0; } }

.campaign-summary + .campaign-summary {
  margin-top: 10px; }

.campaign-summary .collapsible__body {
  padding-left: 0;
  padding-right: 0; }

.campaign-summary__info {
  display: flex;
  justify-content: right;
  font-size: 1.4rem; }
  .campaign-summary__info dl, .campaign-summary__info dt, .campaign-summary__info dd {
    display: inline; }
  .campaign-summary__info dl + dl {
    margin-left: 1.5rem; }

.campaign-info__head {
  font-size: 1.4rem; }
  .campaign-info__head dt {
    font-weight: 400; }
  .campaign-info__head dd {
    font-weight: 500; }

@media (min-width: 768px) {
  .campaign-info__head {
    display: flex;
    justify-content: right; }
    .campaign-info__head dl, .campaign-info__head dt, .campaign-info__head dd {
      display: inline; }
    .campaign-info__head dl + dl {
      margin-left: 1.5rem; }
  .campaign-info__actions {
    margin-left: 1.5rem; } }

.books-list__item {
  display: flex;
  align-items: center;
  height: 55px;
  border-bottom: 1px solid #dbdbdb; }

.books-list__title {
  flex-grow: 1; }

.books-list__info {
  font-size: 1.4rem;
  color: #a29eb1; }

.books-list__actions {
  margin-top: -1px;
  margin-left: 1rem; }
  .books-list__actions a {
    width: 12px;
    height: 12px;
    font-size: 12px;
    line-height: 1; }
    .books-list__actions a, .books-list__actions a:hover, .books-list__actions a:focus, .books-list__actions a:active {
      color: #000; }

@media (max-width: 991.98px) {
  .books-list__info {
    display: none; } }

.tagsinput-box {
  padding: 5px;
  position: relative; }
  .tagsinput-box .form-control {
    border: 0; }

body.show-menu, body.show-search {
  overflow: hidden; }

h1 {
  font-family: "Fort", sans-serif;
  font-weight: 700;
  font-size: 4.8rem;
  line-height: 1.2; }

h2 {
  font-family: "Fort", sans-serif;
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 1.2; }

h3 {
  font-family: "Shift", sans-serif;
  font-weight: 300;
  font-size: 2.4rem;
  line-height: 1.2; }

h4 {
  font-family: "Shift", sans-serif;
  font-weight: 300;
  font-size: 2.0rem;
  line-height: 1.2; }

h5 {
  font-family: "Fort", sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.2; }

h6 {
  font-family: "Shift", sans-serif;
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 1.2; }

label {
  min-height: 24px; }
  label.label-hidden:after {
    content: ' '; }

.site-content {
  padding-top: 105px;
  padding-bottom: 80px; }
  @media (min-width: 992px) {
    .site-content {
      padding-top: 204px;
      padding-bottom: 170px; } }

.box {
  overflow: hidden;
  border-radius: 1rem;
  background-color: #ffffff;
  box-shadow: 0 5px 10px 0 rgba(12, 0, 51, 0.1); }

.box-grid > div > .box {
  width: 100%;
  height: 100%; }

.card-grid > div > .card {
  width: 100%;
  height: 100%; }

.grid, .grid > div, .box-grid, .box-grid > div, .card-grid, .card-grid > div {
  display: flex; }

@media (max-width: 991.98px) {
  .grid > div, .box-grid > div, .card-grid > div {
    margin-top: 30px; } }

.modal-header, .modal-footer {
  border: 0; }

.modal-footer {
  display: flex;
  justify-content: center; }

.glyphicon {
  display: inline-block;
  line-height: 1; }
  .glyphicon:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    content: "ꨀ";
    display: inline-block;
    font-family: 'icons';
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    speak: none;
    text-transform: none;
    position: relative;
    top: 2px; }

.glyphicon-calendar:before {
  content: "ꨳ"; }

.glyphicon-chevron-left:before {
  content: "ꨤ"; }

.glyphicon-chevron-right:before {
  content: "ꨥ"; }

.tooltip-inner a, .tooltip-inner a:hover, .tooltip-inner a:focus, .tooltip-inner a:active {
  color: #fff; }

@font-face {
  font-family: 'Fort';
  src: url("../fonts/Fort/Fort-Bold/Fort-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Fort/Fort-Bold/Fort-Bold.otf") format("opentype"), url("../fonts/Fort/Fort-Bold/Fort-Bold.woff") format("woff"), url("../fonts/Fort/Fort-Bold/Fort-Bold.ttf") format("truetype"), url("../fonts/Fort/Fort-Bold/Fort-Bold.svg#Fort-Bold") format("svg");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Fort';
  src: url("../fonts/Fort/Fort-BoldItalic/Fort-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Fort/Fort-BoldItalic/Fort-BoldItalic.otf") format("opentype"), url("../fonts/Fort/Fort-BoldItalic/Fort-BoldItalic.woff") format("woff"), url("../fonts/Fort/Fort-BoldItalic/Fort-BoldItalic.ttf") format("truetype"), url("../fonts/Fort/Fort-BoldItalic/Fort-BoldItalic.svg#Fort-BoldItalic") format("svg");
  font-weight: 700;
  font-style: italic; }

@font-face {
  font-family: 'Fort';
  src: url("../fonts/Fort/Fort-Light/Fort-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Fort/Fort-Light/Fort-Light.otf") format("opentype"), url("../fonts/Fort/Fort-Light/Fort-Light.woff") format("woff"), url("../fonts/Fort/Fort-Light/Fort-Light.ttf") format("truetype"), url("../fonts/Fort/Fort-Light/Fort-Light.svg#Fort-Light") format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Fort';
  src: url("../fonts/Fort/Fort-LightItalic/Fort-LightItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Fort/Fort-LightItalic/Fort-LightItalic.otf") format("opentype"), url("../fonts/Fort/Fort-LightItalic/Fort-LightItalic.woff") format("woff"), url("../fonts/Fort/Fort-LightItalic/Fort-LightItalic.ttf") format("truetype"), url("../fonts/Fort/Fort-LightItalic/Fort-LightItalic.svg#Fort-LightItalic") format("svg");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: 'Fort';
  src: url("../fonts/Fort/Fort-Medium/Fort-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Fort/Fort-Medium/Fort-Medium.otf") format("opentype"), url("../fonts/Fort/Fort-Medium/Fort-Medium.woff") format("woff"), url("../fonts/Fort/Fort-Medium/Fort-Medium.ttf") format("truetype"), url("../fonts/Fort/Fort-Medium/Fort-Medium.svg#Fort-Medium") format("svg");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Fort';
  src: url("../fonts/Fort/Fort-MediumItalic/Fort-MediumItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Fort/Fort-MediumItalic/Fort-MediumItalic.otf") format("opentype"), url("../fonts/Fort/Fort-MediumItalic/Fort-MediumItalic.woff") format("woff"), url("../fonts/Fort/Fort-MediumItalic/Fort-MediumItalic.ttf") format("truetype"), url("../fonts/Fort/Fort-MediumItalic/Fort-MediumItalic.svg#Fort-MediumItalic") format("svg");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: 'Shift';
  src: url("../fonts/Shift/Shift-Light/Shift-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Shift/Shift-Light/Shift-Light.otf") format("opentype"), url("../fonts/Shift/Shift-Light/Shift-Light.woff") format("woff"), url("../fonts/Shift/Shift-Light/Shift-Light.ttf") format("truetype"), url("../fonts/Shift/Shift-Light/Shift-Light.svg#Shift-Light") format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Shift';
  src: url("../fonts/Shift/Shift-LightItalic/Shift-LightItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Shift/Shift-LightItalic/Shift-LightItalic.otf") format("opentype"), url("../fonts/Shift/Shift-LightItalic/Shift-LightItalic.woff") format("woff"), url("../fonts/Shift/Shift-LightItalic/Shift-LightItalic.ttf") format("truetype"), url("../fonts/Shift/Shift-LightItalic/Shift-LightItalic.svg#Shift-LightItalic") format("svg");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: 'Shift';
  src: url("../fonts/Shift/Shift-Medium/Shift-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Shift/Shift-Medium/Shift-Medium.otf") format("opentype"), url("../fonts/Shift/Shift-Medium/Shift-Medium.woff") format("woff"), url("../fonts/Shift/Shift-Medium/Shift-Medium.ttf") format("truetype"), url("../fonts/Shift/Shift-Medium/Shift-Medium.svg#Shift-Medium") format("svg");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Shift';
  src: url("../fonts/Shift/Shift-MediumItalic/Shift-MediumItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Shift/Shift-MediumItalic/Shift-MediumItalic.otf") format("opentype"), url("../fonts/Shift/Shift-MediumItalic/Shift-MediumItalic.woff") format("woff"), url("../fonts/Shift/Shift-MediumItalic/Shift-MediumItalic.ttf") format("truetype"), url("../fonts/Shift/Shift-MediumItalic/Shift-MediumItalic.svg#Shift-MediumItalic") format("svg");
  font-weight: 400;
  font-style: italic; }

.collapsible__head {
  display: flex;
  position: relative;
  align-items: center;
  /*justify-content: space-between;*/
  padding: 10px 30px;
  /*padding-right: 15px + 19px + 30px;*/
  background: #d8dfe8; }
  .collapsible__head, .collapsible__head:hover, .collapsible__head:focus, .collapsible__head:active {
    color: #212121; }
  .collapsible__head:after {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    content: "ꨢ";
    display: inline-block;
    font-family: 'icons';
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    speak: none;
    text-transform: none;
    position: relative;
    top: 2px;
    width: 19px;
    height: 19px;
    font-size: 19px;
    line-height: 1;
    margin-left: 16px;
    /*@include center-y;*/
    /*right: 25px;*/ }
  .collapsible__head.collapsed:after {
    content: "ꨣ"; }

.collapsible__title {
  font-size: 2rem;
  flex-grow: 1;
  padding-top: 4px; }

.collapsible__info, .collapsible__actions {
  margin-left: 16px; }

.collapsible__info {
  font-size: 1.2rem;
  font-weight: 400; }

.collapsible__actions, .collapsible__actions:hover, .collapsible__actions:focus, .collapsible__actions:active {
  color: #ff6f00; }

.collapsible__actions .icon {
  width: 16px;
  height: 16px;
  font-size: 16px;
  line-height: 1; }

.collapsible__body {
  padding: 20px 0; }

.collapsible.collapsible--marketing .collapsible__head {
  background: #daedec; }

.collapsible.collapsible--communication .collapsible__head {
  background: #fce8ce; }

.collapsible.collapsible--sales .collapsible__head {
  background: #f9cabf; }

@media (max-width: 991.98px) {
  .collapsible__head {
    padding: 10px; }
  .collapsible__info {
    display: none; } }

@media (min-width: 992px) {
  .collapsible__body {
    /*padding: 30px;*/
    padding: 30px 0; } }

.filter label {
  display: block; }

.filter .filter-sort {
  margin-left: 2rem; }
  .filter .filter-sort .dropdown-toggle {
    width: 50px; }
  .filter .filter-sort.show .dropdown-toggle .icon:before {
    content: "ꨡ"; }
  .filter .filter-sort .custom-control-label {
    white-space: nowrap; }

.list__head {
  margin-bottom: 40px; }

.list__footer {
  margin-top: 70px; }

.card {
  overflow: hidden;
  border-radius: 1rem;
  background-color: #ffffff;
  box-shadow: 0 5px 10px 0 rgba(12, 0, 51, 0.1);
  position: relative;
  max-width: 262px;
  margin: 0 auto;
  text-align: center; }
  .card__actions {
    padding-left: 0;
    padding-right: 0;
    list-style: none;
    margin: 0;
    position: absolute;
    top: 5px;
    right: 8px;
    z-index: 5; }
  .card__action--show .icon:before {
    content: "ꨄ"; }
  .card__action--show:hover .icon:before {
    content: "꨷"; }
  .card__action--edit .icon:before {
    content: "ꨙ"; }
  .card__action--edit:hover .icon:before {
    content: "꨸"; }
  .card__content {
    padding: 18px; }
  .card__head {
    text-align: left; }
  .card .books {
    margin: 25px auto; }
  .card__title {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.25;
    margin-bottom: 10px; }
  .card__body {
    font-size: 1.4rem;
    font-weight: normal;
    line-height: 1.43;
    margin-bottom: 20px; }
  .card__author {
    font-family: "Shift", sans-serif;
    font-size: 1.4rem;
    line-height: 1.14;
    color: #ffc000; }
  .card__cta {
    margin-top: 25px; }
  .card__link, .card__link:hover, .card__link:focus, .card__link:active {
    color: #212121; }
  .card__footer {
    font-family: "Shift", sans-serif;
    font-size: 2rem;
    padding: 10px; }
  .card__front {
    /*background: #dfd;*/ }
  .card__rear {
    background: #d4dae3;
    padding: 30px; }
    .card__rear .card__title {
      padding-bottom: 10px;
      border-bottom: solid 1px #fff;
      margin-bottom: 20px; }
  .card__flip {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    width: 200%; }
  .card__front, .card__rear {
    width: 50%; }
  .card:hover .card__flip {
    transform: translateX(-50%); }
  .card--site .card__actions a {
    width: 24px;
    height: 24px;
    position: relative;
    display: block;
    border-radius: 50%;
    background: #fff; }
    .card--site .card__actions a, .card--site .card__actions a:hover, .card--site .card__actions a:focus, .card--site .card__actions a:active {
      color: #9299a7; }
    .card--site .card__actions a .icon {
      width: 12px;
      height: 12px;
      font-size: 12px;
      line-height: 1; }
      .card--site .card__actions a .icon, .card--site .card__actions a .icon:before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%); }
  .card--site .card__link {
    /*@include absolute;*/
    /*display: flex;*/
    height: 0;
    padding-bottom: 100%; }
  .card--site .card__media {
    height: 0;
    padding-bottom: 100%; }
  .card--site .card__footer {
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    background: #fff; }
  .card--number {
    background: #d4dae3;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    padding: 15px; }
    @media (min-width: 992px) {
      .card--number {
        padding: 28px 10px; } }
  .card--number .card__title {
    font-family: Shift;
    font-size: 24px;
    line-height: 1.5;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center; }
  .card--number .card__number {
    font-size: 4.8rem;
    font-weight: bold;
    line-height: 1.33;
    color: #fff; }

.panel {
  overflow: hidden;
  border-radius: 1rem;
  background-color: #ffffff;
  box-shadow: 0 5px 10px 0 rgba(12, 0, 51, 0.1); }
  .panel__head {
    display: flex;
    align-items: center;
    /*justify-content: space-between;*/
    padding: 20px 30px;
    background: #d8dfe8; }
    .panel__head--lg {
      padding-top: 30px;
      padding-bottom: 30px; }
  .panel__title, .panel__editable-title {
    font-size: 2.4rem;
    flex-grow: 1;
    font-family: "Shift", sans-serif; }
  .panel__editable-title {
    border: 0;
    background: none;
    padding: 5px 0 0; }
  .panel__collapser {
    margin-left: 42px; }
    .panel__collapser, .panel__collapser:hover, .panel__collapser:focus, .panel__collapser:active {
      color: #7d8189; }
    .panel__collapser:after {
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      content: "ꨢ";
      display: inline-block;
      font-family: 'icons';
      font-style: normal;
      font-variant: normal;
      font-weight: normal;
      line-height: 1;
      speak: none;
      text-transform: none;
      position: relative;
      top: 2px;
      width: 19px;
      height: 19px;
      font-size: 19px;
      line-height: 1; }
    .panel__collapser.collapsed:after {
      content: "ꨣ"; }
  .panel__body {
    padding: 20px; }
  .panel.panel--marketing .panel__head {
    background: #daedec; }
  .panel.panel--communication .panel__head {
    background: #fce8ce; }
  .panel.panel--sales .panel__head {
    background: #f9cabf; }
  @media (max-width: 991.98px) {
    .panel__head {
      /*position: relative;*/
      /*margin-bottom: 35px;*/
      padding: 15px 20px;
      flex-direction: column; } }
  .panel__title {
    font-size: 1.6rem; }
  .panel__actions {
    margin-top: 10px;
    /*        &__actions {
            position: absolute;
            bottom: -30px;
            right: 10px;
        }*/ }
    .panel__actions__body {
      padding: 15px; }
  @media (min-width: 992px) {
    .panel__body {
      padding: 30px; } }

.section__title {
  font-family: "Shift", sans-serif;
  font-size: 2.4rem;
  line-height: 1.5;
  text-align: center;
  padding-bottom: 10px;
  border-bottom: solid 1px #dbdbdb;
  margin-bottom: 30px; }

.section + .section {
  margin-top: 60px; }

.chart {
  display: flex;
  justify-content: center; }
  .chart > div {
    width: 100%; }

.amChartsDataSetSelector {
  display: none; }

.amcharts-left-div {
  display: none; }

.amcharts-center-div {
  padding-left: 0 !important; }

@media (max-width: 991.98px) {
  .actions .btn {
    width: 100%; }
    .actions .btn + .btn {
      margin-top: 1.5rem; } }

@media (min-width: 992px) {
  .actions {
    display: flex;
    width: 100%;
    justify-content: flex-end; }
    .actions .btn + .btn {
      margin-left: 1.5rem; } }

.brand-section__title {
  font-size: 2rem;
  font-weight: 500;
  padding-bottom: 10px;
  border-bottom: solid 1px #979797;
  margin-bottom: 10px; }

.brand-section.brand-section--marketing .brand-section__title {
  color: #85c4c1; }

.brand-section.brand-section--communication .brand-section__title {
  color: #edae47; }

.brand-section.brand-section--sales .brand-section__title {
  color: #ed6847; }

.data-table {
  font-size: 1.4rem; }
  .data-table__head .data-table__cell {
    font-weight: 700; }
  .data-table__row {
    padding: 10px;
    border-bottom: solid 1px #dbdbdb; }
  .data-table__action--remove {
    color: #212121; }
  @media (max-width: 991.98px) {
    .data-table__head {
      display: none; }
    .data-table__row {
      position: relative; }
    .data-table__cell {
      display: none; }
    .data-table__cell-xs {
      display: block; }
    .data-table__cell--actions {
      white-space: nowrap;
      display: block;
      position: absolute;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      width: 10px; }
    .data-table__action {
      display: block; }
      .data-table__action + .data-table__action {
        margin-top: .5rem; } }
  @media (min-width: 992px) {
    .data-table {
      display: table; }
      .data-table__head, .data-table__row {
        display: table;
        table-layout: fixed;
        width: 100%;
        padding: 0; }
      .data-table__head {
        background: #f5f5f5; }
      .data-table__cell {
        display: table-cell;
        padding: 25px 15px; }
      .data-table__head .data-table__cell {
        padding-top: 5px;
        padding-bottom: 5px; }
      .data-table__cell--actions {
        vertical-align: middle;
        white-space: nowrap;
        padding-left: 5px;
        padding-right: 5px;
        text-align: right; }
      .data-table__action + .data-table__action {
        margin-left: 1.5rem; } }

.dropdown-modal .dropdown-toggle {
  width: 30px;
  height: 30px;
  width: 30px;
  height: 30px;
  position: relative;
  color: #212121;
  position: relative; }
  .dropdown-modal .dropdown-toggle .icon {
    width: 20px;
    height: 20px;
    font-size: 20px;
    line-height: 1; }
    .dropdown-modal .dropdown-toggle .icon, .dropdown-modal .dropdown-toggle .icon:before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%); }
  .dropdown-modal .dropdown-toggle:after {
    display: none; }

.dropdown-modal .dropdown-menu, .dropdown-modal span.twitter-typeahead .tt-menu, span.twitter-typeahead .dropdown-modal .tt-menu {
  overflow: hidden;
  border-radius: 1rem;
  background-color: #ffffff;
  box-shadow: 0 5px 10px 0 rgba(12, 0, 51, 0.1);
  border-radius: 10px;
  padding: 0;
  margin-top: -39px;
  /*margin-left: 17px;*/
  min-width: 265px; }

.dropdown-modal__head {
  background-color: #d8dfe8;
  padding: 25px 30px;
  font-weight: 500; }

.dropdown-modal__body {
  padding: 20px 30px; }
  .dropdown-modal__body .custom-control + .custom-control {
    margin-top: 15px; }

.dropdown-modal.show .dropdown-toggle {
  z-index: 1001; }
