
@import "generated/icons-vars";

// Project variables
$debug: false;

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;


$blue:    #007bff !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;

$primary:       #ff6f00 !default;
$secondary:     #94a0b2 !default;
$ternary:     #ffc000 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;





$accent-default: #d8dfe8;

$accent-marketing: #85c4c1 !default;
$accent-communication: #edae47 !default;
$accent-sales: #ed6847 !default;

$accent-marketing-light: #daedec !default;
$accent-communication-light: #fce8ce !default;
$accent-sales-light: #f9cabf !default;







$theme-colors: (
  "marketing":     $accent-marketing,
  "communication": $accent-communication,
  "sales":         $accent-sales,
  "marketing-light":     $accent-marketing-light,
  "communication-light": $accent-communication-light,
  "sales-light":         $accent-sales-light
);

$theme-accents: (
  "marketing":     $accent-marketing,
  "communication": $accent-communication,
  "sales":         $accent-sales,
);
$theme-accents-light: (
  "marketing-light":     $accent-marketing-light,
  "communication-light": $accent-communication-light,
  "sales-light":         $accent-sales-light
);

$theme-accents--light: (
  "marketing":     $accent-marketing-light,
  "communication": $accent-communication-light,
  "sales":         $accent-sales-light
);


$theme-accents--light: (
  "marketing":     $accent-marketing-light,
  "communication": $accent-communication-light,
  "sales":         $accent-sales-light
);


$theme-chart-accents: (
    "accent1": #5b7bdf,
    "accent2": #e9775f,
    "accent3": #438da0,
    "accent4": #de5562,
    "accent5": #fab06e,
    "accent6": #66d09f,
    "accent7": #a14848,
    "accent8": #ecd260,
    "accent9": #b4e1aa,
    "accent10": #5c86bd,
    "accent11": #e18d90,
    "accent12": #939cf7,
    "accent13": #a2c0f5,
    "accent14": #9299a7
);




$spacer: 2rem !default;


$body-bg:    #f5f8fa !default;
$body-color: #212121 !default;


$font-fort: 'Fort', sans-serif;
$font-shift: 'Shift', sans-serif;

$font-family-base: $font-fort;
$headings-font-family: $font-shift;
$headings-font-weight: 300;







/// Bootstrap
$badge-font-size:     1.2rem !default;
$badge-font-weight:   300 !default;
$badge-padding-y:     6px !default;
$badge-padding-x:     15px !default;
$badge-border-radius: 15px !default;

$input-btn-padding-y:         .95rem !default;
$input-btn-padding-x:         2rem !default;

$input-btn-padding-y-sm:      .85rem !default;
$input-btn-padding-x-sm:      2rem !default;

$input-btn-padding-y-lg:      1.05rem !default;
$input-btn-padding-x-lg:      2rem !default;

$btn-border-radius:           .5rem !default;
$btn-border-radius-lg:        $btn-border-radius !default;
$btn-border-radius-sm:        $btn-border-radius !default;

$form-check-input-gutter:               2rem !default;
$form-check-input-margin-y:             .6rem !default;
$form-check-input-margin-x:             .4rem !default;

$form-check-inline-margin-x:            1.2rem !default;
$form-check-inline-input-margin-y:      .25rem !default;
$form-check-inline-input-margin-x:      .5rem !default;


$custom-control-gutter:                 2.5rem !default;
$custom-control-spacer-x:               1rem !default;

$custom-control-indicator-size:         2rem !default;
$custom-control-indicator-bg:           $secondary !default;
$custom-control-indicator-bg-size:      50% 50% !default;
$custom-control-indicator-box-shadow:   none !default;

$custom-control-indicator-disabled-bg:          lighten($secondary, 35%) !default;
$custom-control-label-disabled-color:     lighten($secondary, 35%) !default;

$custom-control-indicator-checked-color:        $secondary !default;
$custom-control-indicator-checked-bg:           none !default;
$custom-control-indicator-checked-disabled-bg:  rgba($primary, .5) !default;
$custom-control-indicator-checked-box-shadow:   none !default;

/*$custom-control-indicator-focus-box-shadow:     0 0 0 1px $body-bg, $input-btn-focus-box-shadow !default;*/

$custom-control-indicator-active-color:         $secondary !default;
$custom-control-indicator-active-bg:            lighten($secondary, 35%) !default;
$custom-control-indicator-active-box-shadow:    none !default;

$custom-checkbox-indicator-border-radius:       3px !default;
$custom-checkbox-indicator-icon-checked:        str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$custom-control-indicator-checked-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"), "#", "%23") !default;

$custom-checkbox-indicator-indeterminate-bg:    $secondary !default;
$custom-checkbox-indicator-indeterminate-color: $custom-control-indicator-checked-color !default;
$custom-checkbox-indicator-icon-indeterminate:  str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='#{$custom-checkbox-indicator-indeterminate-color}' d='M0 2h4'/%3E%3C/svg%3E"), "#", "%23") !default;
$custom-checkbox-indicator-indeterminate-box-shadow: none !default;

$custom-radio-indicator-border-radius:          50% !default;
$custom-radio-indicator-icon-checked:           str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='#{$custom-control-indicator-checked-color}'/%3E%3C/svg%3E"), "#", "%23") !default;


$sizes: (
  10: 10%,
  15: 15%,
  20: 20%,
  25: 25%,
  30: 30%,
  35: 35%,
  40: 40%,
  45: 45%,
  50: 50%,
  55: 55%,
  60: 60%,
  65: 65%,
  70: 70%,
  75: 75%,
  80: 80%,
  85: 85%,
  90: 90%,
  95: 95%,
  100: 100%
);


$tooltip-bg: #9299a7;
$tooltip-opacity: 1;
$tooltip-color: #fff;




/////

$box--border-radius: 1rem;
$box--background-color: #ffffff;
$box--box-shadow: 0 5px 10px 0 rgba(12, 0, 51, 0.1);

/////

$layout-color: #fff;
$layout-bg: #000;


$site-header--height--portable: 75px;
$site-header--height--desktop: 124px;

@import "kec/variables";