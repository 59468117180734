.site-footer {

    padding-top: 25px;
    padding-bottom: 20px;

    color: $layout-color;
    background: $layout-bg;

    text-align: center;

    &__brand {
        img {
            width: 138px;
        }
    }

    &__copy {
        margin-top: 40px;
        opacity: .5;
        font-size: 1.2rem;
    }

    @include desktop {
        padding-bottom: 80px;

        .container {
            display: flex;
            justify-content: space-between;
        }

        &__copy {
            margin-top: 0;
            text-align: right;
        }
    }

}