.brand-section {

    &__title {
	font-size: 2rem;
        font-weight: 500;

        padding-bottom: 10px;

	border-bottom: solid 1px #979797;
        margin-bottom: 10px;
    }

     @each $color, $value in $theme-accents {
        &.brand-section--#{$color} &__title {
            color: $value;
        }
    }
}