.modal--visualization {


    /*    &.open {
            .modal-dialog {
                max-width: 946px;
            }

            .modal__items-selected {
                display: block;
            }
        }*/

    .close {
        position: absolute;
        right: 15px;
        top: 15px;
    }

    .modal__selected,
    .modal__items--book-search,
    .modal__items--book-format {
        display: none;
    }

    &--book-search .modal__items--book-search,
    &--book-format .modal__items--book-format {

        display: block;
    }




    .modal-body {
        display: flex;
        padding: 40px 0 0;
    }

    .modal__items {
        padding: 20px;
    }

    .book-search__item {
        img { width: 60px; }

        p {
            color: $ternary;
            font-family: $font-shift;
            font-size: 1.4rem;
        }

        a {
            padding-left: 0;
            font-size: 1.4rem;
        }

        .media-body {
            position: relative;
        }
    }


    .book-format {
        &__media {
            display: none;
        }
    }



    @include desktop {
        .modal-dialog {
            width: 780px;
            max-width: none;
        }
        .modal-body {
            padding: 10px 0 0;
        }


        .modal__items {
            padding: 40px;
        }



        .book-format {
            display: flex;
            &__media {
                display: block;
                padding-right: 40px;
                img {
                    width: 94px;
                }
            }
        }


        &.open {
            .modal-dialog {
                width: 946px;
            }

            .modal__selected {
                display: block;
            }
        }

         .modal__items {
            width: 780px;
         }
         .modal__selected {
            display: none;
            width: 280px;
            padding-top: 60px;
            padding-bottom: 30px;
            padding-left: 38px;
            padding-right: 38px;

            border-left: solid 1px #979797;

            .modal-scroll {
                max-height: 424px;
            }


            .media {

                img {
                    width: 60px;
                }


                p {
                    color: $ternary;
                    font-family: $font-shift;
                    font-size: 1.4rem;
                }

                a {
                    padding-left: 0;
                    font-size: 1.4rem;
                }

                .media-body {
                    position: relative;

                    .close {
                        position: absolute;
                        right: -15px;
                        top: 0;
                    }
                }

            }
        }
    }
}