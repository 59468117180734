// Anchors

@mixin anchor($color: false) {
    &, &:hover, &:focus, &:active {
        @if $color {
            color: $color;
        }
        @content;
    }
}

@mixin anchor-hover($color: false) {
    &:hover, &:focus, &:active {
        @if $color {
            color: $color;
        }
        @content;
    }
}

@mixin button() {
    &, &:hover, &:focus {
        @content;
    }
}