// List

@mixin list-unstyled {
    padding-left: 0;
    padding-right: 0;
    list-style: none;
    margin: 0;
}

@mixin list-inline {
    @include list-unstyled();

    > li {
        display: inline-block;
    }
}

@mixin list-table {
    @include list-unstyled();

    display: table;
    width: 100%;

    > li {
        display: table-cell;
        /*float:left;*/
        vertical-align: top;

        > a {
            display: block;
        }
    }
}