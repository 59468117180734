.site-header {
    @include fixed-top;
    z-index: $zindex-fixed;

    height: $site-header--height--portable;

    color: #bfc5d2;
    background: $layout-bg;
    text-align: center;


    &__menu-toggler, &__search-toggler {
        @include size($site-header--height--portable);
        @include anchor(#fff);
        position: relative;
        display: block;

        @include append-icon {
            @include center;
        }
    }

    &__menu-toggler { @include appended-icon($icon-burger); }
    &__search-toggler { @include appended-icon($icon-search); }

    body.show-menu &__menu-toggler, body.show-search &__search-toggler {
        @include appended-icon($icon-times);
    }


    &__brand {
        font-size: 1.4rem;
        font-weight: bold;

        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        height: $site-header--height--portable;

        @include anchor(#bfc5d2);
    }

    &__new-campaign, &__data-visualization {
        @include size(6rem);
        @include anchor(#fff);

        background: $primary;
        border-radius: 50%;
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.18), 0 0 8px 0 rgba(0, 0, 0, 0.12);

        @include append-icon() {
            @include icon-size(1.6rem);
            @include center;

        }
    }
    &__new-campaign {
        @include appended-icon($icon-plus);
    }

    &__data-visualization {
        @include appended-icon($icon-chart) {
            @include icon-size(2.4rem);
        }
    }

    @include portable {
        display: flex;
        width: 100%;

        .container{
            position: absolute;
        }

        &__search-desktop, &__user {
            display: none;
        }


        &__new-campaign, &__data-visualization {
            position: fixed;
            bottom: 10px;
            right: 20px;
        }

        &__search-portable {
            @include fixed($site-header--height--portable, 0, 0);
            background: $body-bg;
            z-index: $zindex-fixed + 1;

            padding-top: 15px;

            .btn-group {
                width: 100%;
                .btn { width: 50%; }
            }

            display: none;

            body.show-search & { display: block; }
        }
    }

    @include desktop {
        height: $site-header--height--desktop;

        .container {
            display: flex;
            position: relative;

            height: 30px + 45px + 30px;

            align-items: center;
            justify-content: center;
        }

        &__search-toggler { display: none; }


        &__new-campaign, &__data-visualization {
            $size: 56px;
            position: absolute;
            top: $site-header--height--desktop;
            right: 20px;
            @include size($size);
            transform: translateY(-50%);
        }


        &__brand, &__menu-toggler {
            position: absolute;
            top: 10px;
            z-index: 1;
        }

        &__menu-toggler {
            left: 0;
        }

        &__brand {
            left: $site-header--height--portable;
        }

        &__search-portable {
            display: none;
        }

        &__search-desktop {
            width: 505px;
            margin-left: -30px;

            @include large-desktop {
                width: 605px;
            }
        }

        &__user {
            @include center-y;
            right: 0;
        }

    }
}

.user-menu {

    .dropdown-toggle {
        display: flex;
        align-items: center;
        padding: 10px 0;

        .dropdown-menu {
            z-index: 10000;
        }

        @include append-icon($icon-chevron-down) {
            border: 0;
            @include size(12px);
            @include icon-size(12px);
            margin-left: 8px;
            color: #fff;
        }
    }

    &__name {
        text-align: right;
        color: #a9a9a9;

        b, span {
            display: block;
        }

        b { font-size: 1.4rem; }
        span { font-size: 1.2rem; }
    }

    &__avatar {
        @include icon-badged-size(40px, 20px);
        margin-left: 8px;

        background: #eee;
        border-radius: 50%;
        color: $body-color;

        @include append-icon($icon-profile) {
            @include icon-size(20px);
            @include center;
        }
    }



}