// Size

@mixin size($width, $height: $width) {
    width: $width;
    height: $height;
}


@mixin relative-height($height) {
    padding-top: $height;
    height: 0;
}



@mixin cover() {
    @include center();
    min-width: 101%;
    min-height: 101%;
    width: auto !important; // Override inline width
    height: auto !important; // Override inline height
}

@mixin contains() {
    @include center();
    max-width: 101%;
    max-height: 101%;
    width: auto !important; // Override inline width
    height: auto !important; // Override inline height
}


@mixin cover-override() {
    min-width: 101%;
    min-height: 101%;
    max-width: none;
    max-height: none;
}

@mixin contains-override() {
    min-width: 0;
    min-height: 0;
    max-width: 101%;
    max-height: 101%;
}