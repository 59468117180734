.section {

    &__title {
	font-family: $font-shift;
	font-size: 2.4rem;
	line-height: 1.5;
	text-align: center;

        padding-bottom: 10px;

	border-bottom: solid 1px #dbdbdb;
        margin-bottom: 30px;
    }


    & + & {
        margin-top: 60px;
    }
}