.actions {
    @include portable {
        .btn {
            width: 100%;

            & + .btn {
                margin-top: 1.5rem;
            }
        }
    }

    @include desktop {

        display: flex;
        width: 100%;
        justify-content: flex-end;

        .btn + .btn {
            margin-left: 1.5rem;
        }
    }
}