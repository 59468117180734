.data-table {

    font-size: 1.4rem;

    &__cell {
    }
    &__head &__cell {
        font-weight: 700;
    }


    &__row {
        padding: 10px;
        border-bottom: solid 1px #dbdbdb;
    }



    &__action--remove {
        color: $body-color;
    }

    @include portable {
        &__head {
            display: none;
        }


        &__row {
            position: relative;

        }


        &__cell { display: none; }

        &__cell-xs {
            display: block;
        }

        &__cell--actions {
            white-space: nowrap;
            display: block;
            position: absolute;
            @include center-y;
            right: 0;
            width: 10px;
        }

        &__action {
            display: block;
            & + & {
                margin-top: .5rem;
            }
        }

    }

    @include desktop {
        display: table;

        &__head, &__row {
            display: table;
            table-layout: fixed;
            width: 100%;
            padding: 0;
        }

        &__head {
            background: #f5f5f5;
        }

        &__cell {
            display: table-cell;
            padding: 25px 15px;
        }

        &__head &__cell {

            padding-top: 5px;
            padding-bottom: 5px;
        }


        &__cell--actions {
            vertical-align: middle;
            white-space: nowrap;
            padding-left: 5px;
            padding-right: 5px;
            text-align: right;
        }

        &__action {
            & + & {
                margin-left: 1.5rem;
            }
        }

        &__collapse {
        }
    }

}