
@font-face {
  font-family: 'Fort';
  src: url('../fonts/Fort/Fort-Bold/Fort-Bold.eot?#iefix') format('embedded-opentype'),  url('../fonts/Fort/Fort-Bold/Fort-Bold.otf')  format('opentype'),
	     url('../fonts/Fort/Fort-Bold/Fort-Bold.woff') format('woff'), url('../fonts/Fort/Fort-Bold/Fort-Bold.ttf')  format('truetype'), url('../fonts/Fort/Fort-Bold/Fort-Bold.svg#Fort-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Fort';
  src: url('../fonts/Fort/Fort-BoldItalic/Fort-BoldItalic.eot?#iefix') format('embedded-opentype'),  url('../fonts/Fort/Fort-BoldItalic/Fort-BoldItalic.otf')  format('opentype'),
	     url('../fonts/Fort/Fort-BoldItalic/Fort-BoldItalic.woff') format('woff'), url('../fonts/Fort/Fort-BoldItalic/Fort-BoldItalic.ttf')  format('truetype'), url('../fonts/Fort/Fort-BoldItalic/Fort-BoldItalic.svg#Fort-BoldItalic') format('svg');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Fort';
  src: url('../fonts/Fort/Fort-Light/Fort-Light.eot?#iefix') format('embedded-opentype'),  url('../fonts/Fort/Fort-Light/Fort-Light.otf')  format('opentype'),
	     url('../fonts/Fort/Fort-Light/Fort-Light.woff') format('woff'), url('../fonts/Fort/Fort-Light/Fort-Light.ttf')  format('truetype'), url('../fonts/Fort/Fort-Light/Fort-Light.svg#Fort-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Fort';
  src: url('../fonts/Fort/Fort-LightItalic/Fort-LightItalic.eot?#iefix') format('embedded-opentype'),  url('../fonts/Fort/Fort-LightItalic/Fort-LightItalic.otf')  format('opentype'),
	     url('../fonts/Fort/Fort-LightItalic/Fort-LightItalic.woff') format('woff'), url('../fonts/Fort/Fort-LightItalic/Fort-LightItalic.ttf')  format('truetype'), url('../fonts/Fort/Fort-LightItalic/Fort-LightItalic.svg#Fort-LightItalic') format('svg');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Fort';
  src: url('../fonts/Fort/Fort-Medium/Fort-Medium.eot?#iefix') format('embedded-opentype'),  url('../fonts/Fort/Fort-Medium/Fort-Medium.otf')  format('opentype'),
	     url('../fonts/Fort/Fort-Medium/Fort-Medium.woff') format('woff'), url('../fonts/Fort/Fort-Medium/Fort-Medium.ttf')  format('truetype'), url('../fonts/Fort/Fort-Medium/Fort-Medium.svg#Fort-Medium') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Fort';
  src: url('../fonts/Fort/Fort-MediumItalic/Fort-MediumItalic.eot?#iefix') format('embedded-opentype'),  url('../fonts/Fort/Fort-MediumItalic/Fort-MediumItalic.otf')  format('opentype'),
	     url('../fonts/Fort/Fort-MediumItalic/Fort-MediumItalic.woff') format('woff'), url('../fonts/Fort/Fort-MediumItalic/Fort-MediumItalic.ttf')  format('truetype'), url('../fonts/Fort/Fort-MediumItalic/Fort-MediumItalic.svg#Fort-MediumItalic') format('svg');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Shift';
  src: url('../fonts/Shift/Shift-Light/Shift-Light.eot?#iefix') format('embedded-opentype'),  url('../fonts/Shift/Shift-Light/Shift-Light.otf')  format('opentype'),
	     url('../fonts/Shift/Shift-Light/Shift-Light.woff') format('woff'), url('../fonts/Shift/Shift-Light/Shift-Light.ttf')  format('truetype'), url('../fonts/Shift/Shift-Light/Shift-Light.svg#Shift-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Shift';
  src: url('../fonts/Shift/Shift-LightItalic/Shift-LightItalic.eot?#iefix') format('embedded-opentype'),  url('../fonts/Shift/Shift-LightItalic/Shift-LightItalic.otf')  format('opentype'),
	     url('../fonts/Shift/Shift-LightItalic/Shift-LightItalic.woff') format('woff'), url('../fonts/Shift/Shift-LightItalic/Shift-LightItalic.ttf')  format('truetype'), url('../fonts/Shift/Shift-LightItalic/Shift-LightItalic.svg#Shift-LightItalic') format('svg');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Shift';
  src: url('../fonts/Shift/Shift-Medium/Shift-Medium.eot?#iefix') format('embedded-opentype'),  url('../fonts/Shift/Shift-Medium/Shift-Medium.otf')  format('opentype'),
	     url('../fonts/Shift/Shift-Medium/Shift-Medium.woff') format('woff'), url('../fonts/Shift/Shift-Medium/Shift-Medium.ttf')  format('truetype'), url('../fonts/Shift/Shift-Medium/Shift-Medium.svg#Shift-Medium') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Shift';
  src: url('../fonts/Shift/Shift-MediumItalic/Shift-MediumItalic.eot?#iefix') format('embedded-opentype'),  url('../fonts/Shift/Shift-MediumItalic/Shift-MediumItalic.otf')  format('opentype'),
	     url('../fonts/Shift/Shift-MediumItalic/Shift-MediumItalic.woff') format('woff'), url('../fonts/Shift/Shift-MediumItalic/Shift-MediumItalic.ttf')  format('truetype'), url('../fonts/Shift/Shift-MediumItalic/Shift-MediumItalic.svg#Shift-MediumItalic') format('svg');
  font-weight: 400;
  font-style: italic;
}
//url\('([^-]*)-([^.]*)\.([^']*)'\)
//url('../fonts/$1/$1-$2/$1-$2.$3')