.form-control-tagsinput {
    /*padding: 15px;*/
}

.bootstrap-tagsinput {

    input {
        border: none;
        box-shadow: none;
        outline: none;
        background-color: transparent;
        padding: 0 6px;
        margin: 0;
        width: auto;
        max-width: inherit;

        &:focus {
            border: none;
            box-shadow: none;
        }
    }

    &.form-control {
        input::-moz-placeholder {
            color: #777;
            opacity: 1;
        }
        input:-ms-input-placeholder {
            color: #777;
        }
        input::-webkit-input-placeholder {
            color: #777;
        }
    }


    .tag {
        @include badge();
        @include badge-variant($accent-default, $body-color);
        margin-right: 2px;

        [data-role="remove"] {
            margin-left: 8px;
            cursor: pointer;

            &:after {
                content: "x";
                padding: 0px 2px;
            }
            &:hover {
                box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
            }
            &:hover:active {
                box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
            }
        }
    }
}