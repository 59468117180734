// Position

@mixin position($top: 0, $right: $top, $bottom: $top, $left: $right) {
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
}

@mixin absolute($top: 0, $right: $top, $bottom: $top, $left: $right) {
    @include position($top, $right, $bottom, $left);
    position: absolute;
}

@mixin fixed($top: 0, $right: $top, $bottom: $top, $left: $right) {
    @include position($top, $right, $bottom, $left);
    position: fixed;
}

@mixin top($top: 0, $horizontal: 0) {
    top: $top;
    left: $horizontal;
    right: $horizontal;
}

@mixin bottom($bottom: 0, $horizontal: 0) {
    bottom: $bottom;
    left: $horizontal;
    right: $horizontal;
}

@mixin left($left: 0, $vertical: 0) {
    left: $left;
    top: $vertical;
    bottom: $vertical;
}

@mixin right($right: 0, $vertical: 0) {
    right: $right;
    top: $vertical;
    bottom: $vertical;
}

@mixin absolute-top($bottom: 0, $horizontal: 0) {
    @include top($bottom, $horizontal);
    position: absolute;
}
@mixin absolute-bottom($bottom: 0, $horizontal: 0) {
    @include bottom($bottom, $horizontal);
    position: absolute;
}

@mixin absolute-left($bottom: 0, $vertical: 0) {
    @include left($bottom, $vertical);
    position: absolute;
}
@mixin absolute-right($bottom: 0, $vertical: 0) {
    @include right($bottom, $vertical);
    position: absolute;
}

@mixin fixed-top($top: 0, $horizontal: 0) {
    @include top($top, $horizontal);
    position: fixed;
}

@mixin fixed-bottom($top: 0, $horizontal: 0) {
    @include bottom($top, $horizontal);
    position: fixed;
}

@mixin fixed-left($top: 0, $vertical: 0) {
    @include left($top, $vertical);
    position: fixed;
}

@mixin fixed-right($top: 0, $vertical: 0) {
    @include right($top, $vertical);
    position: fixed;
}