#modal--add-widget {

    .media-body {
        p {
            font-family: font-shift;
            font-size: 1.4rem;
            line-height: 1.14;
            color: $ternary;
        }

        > a{
            font-size: 1.4rem;
            @include anchor($body-color);
        }
    }

}