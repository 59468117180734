
//.btn-primary, .btn-secondary {
//    @include anchor-hover {
//        background: #000;
//        border-color: #000;
//    }
//}
//
//@each $color, $value in $theme-accents {
//    .btn-#{$color} {
//        @include anchor {
//            color: #fff;
//        }
//    }
//}
//
//@each $color, $value in $theme-accents-light {
//    .btn-#{$color} {
//        @include anchor {
//            color: $body-color;
//        }
//    }
//}


body {
    &.show-menu, &.show-search {
        overflow: hidden;
    }
}


h1 { @include style(h1); }
h2 { @include style(h2); }
h3 { @include style(h3); }
h4 { @include style(h4); }
h5 { @include style(h5); }
h6 { @include style(h6); }


label {
    min-height: 24px;
    &.label-hidden {
        &:after { content: ' '; }
    }
}

.site-content {
    padding-top: $site-header--height--portable + 30px;
    padding-bottom: 80px;

    @include desktop {
        padding-top: $site-header--height--desktop + 80px;
        padding-bottom: 170px;
    }
}

.box {
    @include box;
}


.box-grid {
    > div > .box {
        width: 100%;
        height: 100%;
    }
}

.card-grid {
    > div > .card {
        width: 100%;
        height: 100%;
    }
}


.grid, .box-grid, .card-grid {
    &, > div {
        display: flex;
    }

    @include portable {
        > div {
            margin-top: $grid-gutter-width;
        }
    }
}


.modal-header, .modal-footer {
    border: 0;
}

.modal-footer {
    display: flex;
    justify-content: center;
}


.glyphicon  {
    @include icon();
}

.glyphicon-calendar { @include switch-icon($icon-calendar); }
.glyphicon-chevron-left { @include switch-icon($icon-chevron-left); }
.glyphicon-chevron-right { @include switch-icon($icon-chevron-right); }


.tooltip-inner {

    a {
        @include anchor(#fff);
    }

}