.books {
    max-width: 166px;
    height: 139px;
    position: relative;
    /*background: #fdd;*/

    img {
        @include size(90px, 139px);
        @include center;
        z-index: 2;

        &:first-child, &:last-child {
            @include size(72px, 111px);
            z-index: 1;
        }

        &:first-child {
            left: 36px;
        }

        &:last-child {
            left: auto;
            right: -36px;
        }

         &:first-child:last-child {
            @include size(90px, 139px);
            left: 50%;
            right: auto;
        }
    }
}