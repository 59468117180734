.dropdown-modal {
    .dropdown-toggle {
        @include size(30px);
        @include icon-badged-size(30px, 20px);
        color: $body-color;
        &:after { display: none; }
        position: relative;
    }

    .dropdown-menu {
        @include box;

        border-radius: 10px;
        padding: 0;
        margin-top: -39px;
        /*margin-left: 17px;*/

        min-width: 265px;
    }

    &__head {
        background-color: $accent-default;
        padding: 25px 30px;
        font-weight: 500;
    }

    &__body {
        padding: 20px 30px;

        .custom-control + .custom-control {
            margin-top: 15px;
        }
    }

    &.show {
        .dropdown-toggle {
            z-index: $zindex-dropdown + 1;
        }
    }
}