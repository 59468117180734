.filter {

    label {
        display: block;
    }

    .row {

    }

    .filter-sort {
        margin-left: 2rem;

        .dropdown-toggle {
            width: 50px;
        }

        &.show  .dropdown-toggle {
            .icon { @include switch-icon($icon-times); }
        }

        .custom-control-label {
            white-space: nowrap;
        }
    }
}