.form-control-typeahead {

    @include form-control-container();

    .twitter-typeahead {
        &, .tt-menu {
            width: 100%;
        }
    }

}