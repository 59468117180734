.books-list {
    &__item {
        display: flex;
        align-items: center;
        height: 55px;
        border-bottom: 1px solid #dbdbdb;
    }

    &__title {
        flex-grow: 1;
    }

    &__info {
        font-size: 1.4rem;
        color: #a29eb1;
    }

    &__actions {
        margin-top: -1px;
        margin-left: 1rem;

        a {
            @include icon-size(12px);
            @include anchor(#000);
        }
    }

    @include portable {
        &__info {
            display: none;
        }
    }

    @include desktop {

    }

}