@mixin icon-pseudo($icon: $icon-empty) {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    content: $icon;
    display: inline-block;
    font-family: 'icons';
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    speak: none;
    text-transform: none;

    position: relative;
    top: 2px;

  //display: inline-block;
//  font-family: 'Glyphicons Halflings';
//  font-style: normal;
//  font-weight: normal;
//  line-height: 1;
//  -webkit-font-smoothing: antialiased;
//  -moz-osx-font-smoothing: grayscale;


    @content;
}

@mixin prepend-icon($icon: $icon-empty) {
    &:before {
        @include icon-pseudo($icon) {
            @content;
        }
    }
}

@mixin append-icon($icon: $icon-empty) {
    &:after {
        @include icon-pseudo($icon) {
            @content;
        }
    }
}

@mixin prepended-icon($icon: false) {
    &:before {
        @if ($icon) {
            content: $icon;
        }

        @content;
    }
}

@mixin appended-icon($icon: false) {
    &:after {
        @if ($icon) {
            content: $icon;
        }

        @content;
    }
}

@mixin icon($icon: $icon-empty, $pseudo: before) {
    display: inline-block;
    line-height: 1;

    &:#{$pseudo} {
        @include icon-pseudo($icon) {
            @content;
        }
    }
}

@mixin switch-icon($icon: $icon-empty, $pseudo: before) {
    &:#{$pseudo} {
        @if ($icon) {
            content: $icon;
        }

        @content;
    }
}




// Sizing

@mixin icon-badged-size($badge-size, $icon-size: $badge-size, $position: relative) {
    @include size($badge-size);
    position: $position;

    .icon {
        @include icon-size($icon-size);
        &, &:before {
            @include center();
        }
    }
}

@mixin icon-size($size) {
    @include size($size);
    font-size: $size;
    line-height: 1;
}

