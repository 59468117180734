.form-control-choices {
    .badge, .btn-badge {
        &:not(.active) {
            @include badge-variant($accent-default, $body-color);
        }
    }

    @include portable {
        .badge, .btn {
            width: 100%;
        }

        .badge + .badge, .btn + .btn {
            margin-top: 1rem;
        }
    }

    @include desktop {
        display: flex;
        align-items: center;

        height: $input-padding-y + ($font-size-base * $input-line-height) + $input-padding-y;
        border: $input-border-width solid transparent;


        .badge + .badge, .btn + .btn {
            margin-top: 0;
            margin-left: 1rem;
        }
    }
}