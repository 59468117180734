.collapsible {
    &__head {
        display: flex;
        position: relative;
        align-items: center;
        /*justify-content: space-between;*/

        padding: 10px 30px;
        /*padding-right: 15px + 19px + 30px;*/
        background: $accent-default;

        @include anchor {
            color: $body-color;
        }

        @include append-icon($icon-chevron-up) {
            @include icon-size(19px);
            margin-left: 16px;
            /*@include center-y;*/
            /*right: 25px;*/
        }

        &.collapsed {
            @include appended-icon($icon-chevron-down);
        }

    }

    &__title {
        font-size: 2rem;
        flex-grow: 1;
        padding-top: 4px;
    }

    &__info, &__actions {
        margin-left: 16px;
    }

    &__info {
        font-size: 1.2rem;
        font-weight: 400;
    }

    &__actions {
        @include anchor($primary);
        .icon {
            @include icon-size(16px);
        }
    }

    &__body {
        padding: 20px 0;
    }

    @each $color, $value in $theme-accents--light {
        &.collapsible--#{$color} & {
            &__head {
                background: $value;
            }
        }
    }


    @include portable {
         &__head {
        padding: 10px;
         }

        &__info {
            display: none;
        }

    }


    @include desktop {

        &__body {
            /*padding: 30px;*/
            padding: 30px 0;
        }

    }
}