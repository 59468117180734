// Base class
//
// Requires one of the contextual, color modifier classes for `color` and
// `background-color`.

.badge, .btn-badge {
    @include badge;

    &.badge-default {
        @include badge-variant($accent-default, $body-color);
    }

    @each $color, $value in $theme-colors {
        &.badge-#{$color}, &.btn-#{$color} {
            @include badge-variant($value, #fff);
        }

        /*        .btn-group-toggle- &.btn-#{$color}:not(.active) {
                    @include badge-variant($accent-default, #fff);
                }*/
    }

    @each $color, $value in $theme-colors {
        &.badge-outline-#{$color}, &.btn-outline-#{$color}, .btn-group-toggle &.btn-outline-#{$color}.active {
            @include badge-outline-variant($value, #fff);
        }
    }


}

// Quick fix for badges in buttons
.btn .badge {
    position: relative;
    top: -1px;
}

.badge-list {

    display: flex;
    justify-content: center;
    align-content: center;

    .badge__remove {
        @include anchor($body-color);
        @include icon-size(1rem);
        height: .8rem;
        margin-top: -.2rem;
        margin-left: .5rem;

    }

    @include mobile {
        padding: 1rem 0;
        flex-direction: column;
        height: auto;

        .badge, .btn-badge {
            width: 100%;
            /*text-align: left;*/

            span {
                flex-grow: 1;
                text-align: left
            }
            .badge__remove {
            }
        }

        .badge + .badge, .btn-badge + .btn-badge {
            margin-top: .5rem;
        }
    }

    @include tablet-up {
        flex-wrap: wrap;

        .badge, .btn-badge {
            margin: 4px;
        }
    }
}