.grid {
    display: flex;
    flex-wrap: wrap;
    margin: ($grid-gutter-width / 2) (-($grid-gutter-width / 2));

    &__item {
        padding: $grid-gutter-width / 2;
        width: 100%;
        display: flex;

        //background: #dfd;

        > * {
            //background: #f00;
            width: 100%;
        }

        @include media-breakpoint-up(md) {
            width: 50%;
        }

        @include media-breakpoint-up(lg) {
            width: 33.3333%;
        }

         @include media-breakpoint-up(xl) {
                width: 25%;
            }

        .grid--5cols & {
            @include media-breakpoint-up(sm) {
                width: 50%;
            }

            @include media-breakpoint-up(md) {
                width: 33.3333%;
            }

            @include media-breakpoint-up(lg) {
                width: 25%;
            }
            @include media-breakpoint-up(xl) {
                width: 20%;
            }
        }
    }
}

