.panel {
    @include box;

    &__head {
        display: flex;
        align-items: center;
        /*justify-content: space-between;*/

        padding: 20px 30px;
        background: $accent-default;

        &--lg {
            padding-top: 30px;
            padding-bottom: 30px;
        }
    }

    &__title, &__editable-title {
        font-size: 2.4rem;
        flex-grow: 1;
        font-family: $font-shift;
    }

    &__editable-title {
        border: 0;
        background: none;
        padding: 5px 0 0;
    }

    &__collapser {
        margin-left: 42px;

        @include anchor {
            color: #7d8189;
        }

        @include append-icon($icon-chevron-up) {
            @include icon-size(19px);
        }

        &.collapsed {
            @include appended-icon($icon-chevron-down);
        }
    }

    &__body {
        padding: 20px;
    }


    @each $color, $value in $theme-accents--light {
        &.panel--#{$color} & {
            &__head {
                background: $value;
            }
        }
    }

    @include portable {

        &__head {
            /*position: relative;*/
            /*margin-bottom: 35px;*/
            padding: 15px 20px;

            flex-direction: column;
        }
        }

        &__title {
            font-size: 1.6rem;
        }

        &__actions {
            margin-top: 10px;

/*        &__actions {
            position: absolute;
            bottom: -30px;
            right: 10px;
        }*/

        &__body {
            padding: 15px;
        }

    }

    @include desktop {

        &__body {
            padding: 30px;
        }

    }

}