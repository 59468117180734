.site-menu {
	overflow: auto;
    $border: solid 1px #9299a7;

    @include fixed($site-header--height--portable, 0, 0);
    background: $body-bg;
    z-index: $zindex-fixed;

    ul {
        @include list-unstyled;
    }

    li {
    }

    a {
        position: relative;
        display: flex;
        padding: 0 38px;
        height: 66px;
        align-items: center;
        margin: 0 20px;

        @include anchor($body-color);
    }


    li + li a { border-top: $border;    }

    li:last-child a {
        border-bottom: $border;
    }

    li.active a {

        @include before {
            content: '';
            border-left: 4px solid $primary;
            position: absolute;
            left: -20px;
            top: 11px;
            bottom: 11px;
        }

    }

    &__has-children  > a, &__back-link > a  {
        position: relative;

        @include append-icon() {
            @include center-y;
            @include icon-size(18px);
        }
    }

    &__has-children {
        > a {
            position: relative;

            @include appended-icon {
				content: $icon-chevron-right;
                color: #9299a7;
                right: 0;
            }
        }
    }

    &__back-link {
        background: #d4dae3;

        > a {
            border: 0;

            @include appended-icon {
				content: $icon-chevron-left;
                color: #fff;
                left: 0;
            }
        }
    }


    @include portable {
        display: none;

        body.show-menu & {
            display: block;
        }

        &__submenu {
            @include absolute;
            background: $body-bg;
            height: auto !important;
            z-index: $zindex-fixed + 1;

            &.collapsing {
                transition: none;
                max-height: none;
            }
            &.show {
                transition: none;
                display: block;
            }

            &.collapsing.show {
                display: block !important;
            }
        }

        &__back-link {
            background: #d4dae3;

            > a {
                border: 0 !important;

                @include appended-icon {
                    color: #fff;
                    left: 0;
                }
            }
            & + li a {
                border-top: 0 !important;
            }
        }

    }

    @include desktop {
        $menu-width: 308px;

        top: $site-header--height--desktop;
        right: auto;
        width: 0;
        background: #fff;
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.18), 0 0 8px 0 rgba(0, 0, 0, 0.12);

        > ul {
            width: $menu-width;
            float: right;
        }

        &__back-link {
            display: none;
		}
		
		&__has-children {
			> a {
				position: relative;
				& {
					@include appended-icon {
						content: $icon-chevron-up;
					}
				}

				&.collapsed {
					@include appended-icon {
						content: $icon-chevron-down;
					}
				}
			}
		}

        $transition: .2s;

        transition: left $transition, width $transition;
        transition-timing-function: ease-in-out;

        body.show-menu & {
            left: 0;
            width: $menu-width;
        }

        &__submenu {
            padding-left: 20px;
            padding-right: 20px;

            ul {
                border-top: $border;
            }

            li > a {
                height: 56px;
                border: 0 !important;
            }
        }

    }

}