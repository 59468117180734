.site-login-header {
    @include fixed-top;
    z-index: $zindex-fixed;

    padding-top: 22px;
    padding-bottom: 22px;

    color: $layout-color;
    background: $layout-bg;

    text-align: center;

    &__brand {
        img {
            width: 138px;
        }
    }

}