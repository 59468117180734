.book-data {
    width: 100%;
    padding: 35px 15px;
    text-align: center;

    &__info {
        margin-top: 20px;

        font-family: $font-shift;
        font-size: 1.4rem;
        color: $ternary;
    }
}