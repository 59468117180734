@mixin badge {
    display: inline-block;
    padding: .6rem 1.5rem .4rem;
    font-size: $badge-font-size;
    font-weight: $badge-font-weight;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    text-transform: uppercase;
    color: #fff;
    @include border-radius($badge-border-radius);
    $transition: .1s;
    transition: background $transition, border-color $transition, color $transition;
    border-width: 1px;
    border-style: solid;

    // Empty badges collapse automatically
    &:empty {
        display: none;
    }
}



@mixin badge-variant($main, $alt) {
    background-color: $main;
    color: $alt;
    border-color: $main;

    &[href] {
        @include hover-focus {
            text-decoration: none;
            color: $alt;
            background-color: darken($main, 10%);
            border-color: darken($main, 10%);
        }
    }
}

@mixin badge-outline-variant($main, $alt) {
    border-color: $main;
    color: $main;

    &[href] {
        @include hover-focus {
            text-decoration: none;
            border-color: $main;
            color: $alt;
            background-color: $main;
        }
    }
}
